import { Card, CardActionArea, CardContent, Grid, Paper, } from '@mui/material'
import React, { } from 'react'
import { FormDescriptionTypographyPDF, FormTitleTypographyPDF } from '../../../../components/CustomStyles/StyledComponents';
import CircularPieChart from '../../../../components/Charts/CircularPieChart';
import ColoredPieChart from '../../../../components/Charts/ColoredPieChart';
import { addCommas } from '../../../../helpers/stringFormatHelper';
import Vertical2XBarChart from '../../../../components/Charts/Vertical2XBarChart';

const Charts = ({ results = {}, configs = {}, firstChild = false}) => {
    return (
        <>
            <Paper sx={{
                maxWidth: '860px',
                //minHeight: '500px',
                pageBreakAfter: 'auto',
                marginTop: firstChild ? '160px' : '80px',
            }}
                p={2}
            >
                <Grid item xs={12} md={12} lg={12} mb={1}>
                    <Paper
                        sx={{
                            p: 0,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <FormTitleTypographyPDF component="div" mb={1}>
                            {configs.enterpriseTitle || '-Enterprise Name-'}
                        </FormTitleTypographyPDF>
                        <Grid container direction="row" spacing={0}>
                            <Grid item xs={6}>
                                <FormDescriptionTypographyPDF paragraph fontWeight="bold" mb={0} pr={1}>
                                    {'Sector: '}
                                    <FormDescriptionTypographyPDF paragraph display="inline" mb={0}>
                                        {configs.sector}
                                    </FormDescriptionTypographyPDF>
                                </FormDescriptionTypographyPDF>
                            </Grid>
                            <Grid item xs={6}>
                                <FormDescriptionTypographyPDF paragraph fontWeight="bold" mb={0} pr={1}>
                                    {'Organic: '}
                                    <FormDescriptionTypographyPDF paragraph display="inline" mb={0}>
                                        {configs.isOrganic ? 'Yes' : 'No'}
                                    </FormDescriptionTypographyPDF>
                                </FormDescriptionTypographyPDF>
                            </Grid>
                            <Grid item xs={6}>
                                <FormDescriptionTypographyPDF paragraph fontWeight="bold" mb={0} pr={1}>
                                    {'Enterprise Type: '}
                                    <FormDescriptionTypographyPDF paragraph display="inline" mb={0}>
                                        {configs.enterpriseType}
                                    </FormDescriptionTypographyPDF>
                                </FormDescriptionTypographyPDF>
                            </Grid>
                            <Grid item xs={6}>
                                <FormDescriptionTypographyPDF paragraph fontWeight="bold" mb={0} pr={1}>
                                    {'Compare To: '}
                                    <FormDescriptionTypographyPDF paragraph display="inline" mb={0}>
                                        {configs.comparedTo || '-Compare To-'}
                                    </FormDescriptionTypographyPDF>
                                </FormDescriptionTypographyPDF>
                            </Grid>
                            <Grid item xs={6}>
                                <FormDescriptionTypographyPDF paragraph fontWeight="bold" mb={0} pr={1}>
                                    {'System: '}
                                    <FormDescriptionTypographyPDF paragraph display="inline" mb={0}>
                                        {configs.system}
                                    </FormDescriptionTypographyPDF>
                                </FormDescriptionTypographyPDF>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={12} lg={12} mb={1}>
                    <Paper
                        sx={{
                            p: 0,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <FormDescriptionTypographyPDF variant="body2" paragraph mb={0}>
                            {`This enterprise-level report further breaks down emissions from a specific farm enterprise. Again, carbon
                             dioxide equivalent emissions are reported by source and by gas. Product level emissions by source and 
                             are shown against those of similar enterprises.`}
                        </FormDescriptionTypographyPDF>
                    </Paper>
                </Grid>
                <Grid item xs={12} mb={1} px={0}>
                    <Grid container direction='row'>
                        <Grid item xs={3} p={1} >
                            <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                <CardActionArea sx={{ height: '100%' }}>
                                    <CardContent sx={{ padding: '5px' }}>
                                        <FormDescriptionTypographyPDF gutterBottom variant="h6" fontSize={13} mb={0} component="div">
                                            Total Emissions:
                                        </FormDescriptionTypographyPDF>
                                        {results && <FormDescriptionTypographyPDF variant="h6" fontSize={14} fontWeight="bold" component="div" color="#cc5500">
                                            {addCommas(results.totalEmissions)}
                                        </FormDescriptionTypographyPDF>}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={3} p={1}>
                            <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                <CardActionArea sx={{ height: '100%' }}>
                                    <CardContent sx={{ padding: '5px' }}>
                                        <FormDescriptionTypographyPDF gutterBottom variant="h6" fontSize={13} mb={0} component="div">
                                            Product Emissions:
                                        </FormDescriptionTypographyPDF>
                                        {results && <FormDescriptionTypographyPDF variant="h6" fontSize={14} fontWeight="bold" component="div" color="#cc5500">
                                            {addCommas(results.productEmissions)}
                                        </FormDescriptionTypographyPDF>}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={3} p={1} >
                            <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                <CardActionArea sx={{ height: '100%' }}>
                                    <CardContent sx={{ padding: '5px' }}>
                                        <FormDescriptionTypographyPDF gutterBottom variant="h6" fontSize={13} mb={0} component="div">
                                            Largest Source:
                                        </FormDescriptionTypographyPDF>
                                        {results && <FormDescriptionTypographyPDF variant="h6" fontSize={14} fontWeight="bold" component="div" color="#cc5500">
                                            {results.largestSource}
                                        </FormDescriptionTypographyPDF>}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={3} p={1}>
                            <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                <CardActionArea sx={{ height: '100%' }}>
                                    <CardContent sx={{ padding: '5px' }}>
                                        <FormDescriptionTypographyPDF gutterBottom variant="h6" fontSize={13} mb={0} component="div">
                                            Highest Gas Emission:
                                        </FormDescriptionTypographyPDF>
                                        {results && <FormDescriptionTypographyPDF variant="h6" fontSize={14} fontWeight="bold" component="div" color="#cc5500">
                                            {results.highestGasEmission}
                                        </FormDescriptionTypographyPDF>}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} px={0}>
                    <Grid container direction='row' spacing={0}>
                        <Grid item xs={6} pl={0}>
                            <Paper sx={{ pl: 0 }}>
                                <ColoredPieChart
                                    isAnimationActive={false}
                                    key={1}
                                    title='Emissions by Source'
                                    dataset={results.emissionBySource}
                                    height={240}
                                    width={355}
                                    cx={98}
                                    cy={98}
                                    outerRadius={98}
                                    legendWidth='140px'
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={6} pl={2}>
                            <Paper>
                                <CircularPieChart
                                    isAnimationActive={false}
                                    key={2}
                                    title='Emissions by Gas'
                                    dataset={results.emissionByGas}
                                    height={240}
                                    width={355}
                                    cx={98}
                                    cy={98}
                                    outerRadius={98}
                                    labelFontSize='1em'
                                    legendWidth='140px'
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' spacing={0}>
                        <Grid item xs={12} pl={0}>
                            <Paper sx={{ width: '600px', margin: '0 auto', mt: 3 }}>
                                <Vertical2XBarChart
                                    isAnimationActive={false}
                                    key={3}
                                    title='Emissions vs Comparison'
                                    dataset={results.emissionVsComparison}
                                    height={240}
                                    emissionUnit={results?.emissionUnit}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

export default Charts