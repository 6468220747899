import { Backdrop, CircularProgress, Grid, Paper, ThemeProvider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import toastr from "toastr";
import { toastrCustomOptions } from '../../../constants/toastrOptions';
import * as Yup from "yup";
import {
    createOffice, setCurrentAdminOffice, setCurrentAdminOrganisation,
    setLoading, unsetLoading, updateOffice
} from "../../../store/appAction";
import _ from 'lodash';
import { mainTheme } from '../../../theme';
import { FormHeaderPaper, FormTitleTypography } from "../../../components/CustomStyles/StyledComponents";
import { Formik } from "formik";
import OfficeForm from "../../../components/Forms/OfficeForm";
import { EDIT } from "../../../constants/modes";

const Office = () => {
    const dispatch = useDispatch();
    let { mode } = useParams();

    const { loading } = useSelector(state => state.common);
    const currentOffice = useSelector(state => state.adminOffice.selectedOffice);
    const user = useSelector(state => state.auth.user);

    const [intOfficeDetails, setIntOfficeDetails] = useState(undefined);
    const [formData, setFormData] = useState(undefined);

    const validationSchema = Yup.object({
        name: Yup.string("Enter office name").trim().required("Office name is required")
    });

    useEffect(() => {
        if (mode === EDIT && !_.isEmpty(currentOffice)) {
            setIntOfficeDetails(currentOffice);
        }
        else {
            dispatch(setCurrentAdminOffice(null));
            setFormData({
                id: '',
                name: '',
                organisationId: '',
                file: null
            });
        }
        return () => {
            setIntOfficeDetails(undefined);
            dispatch(setCurrentAdminOrganisation({}));

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (intOfficeDetails && !_.isEmpty(intOfficeDetails)) {
            setFormData({
                id: intOfficeDetails?.id,
                name: intOfficeDetails?.officeName || '',
                organisationId: intOfficeDetails?.organizationId || '',
                file: intOfficeDetails?.officeLogoUrl || null 
            })
            dispatch(unsetLoading());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intOfficeDetails]);

    const handleSubmit = data => {
        dispatch(setLoading());
        if (mode === EDIT && !_.isEmpty(currentOffice)) {
            dispatch(updateOffice(data));
        } else {
            dispatch(createOffice(data));
        }
    };

    return (
        <>
            <ThemeProvider theme={mainTheme}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} lg={9}>
                        <FormHeaderPaper
                            sx={{
                                p: 2,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <FormTitleTypography variant="h5" component="div">
                                {(mode === EDIT && currentOffice) ? 'Edit' : 'Create'} Office
                            </FormTitleTypography>
                            <Typography variant="body2" paragraph>
                                {' Create or modify your office details.'}
                            </Typography>
                        </FormHeaderPaper>
                    </Grid>
                    <Grid item md={12} >
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {formData &&
                                <Formik
                                    enableReinitialize
                                    render={props => <OfficeForm {...props} mode={mode} role={user?.role}/>}
                                    initialValues={formData}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                    mode= {mode}
                                />}
                        </Paper>
                    </Grid>
                </Grid>

            </ThemeProvider>

        </>
    );
}

export default Office