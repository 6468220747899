import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { styled, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Header from '../TopNavigation';
import SideBar from '../LeftNavigation';
import Footer from '../BottomNavigation';
import { mainTheme } from '../../theme';
import { ADMIN, CONSULTANT, FARMER, SUPPER_ADMIN } from '../../constants/userRoles';
import { Backdrop, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const MainContainer = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    backgroundColor: theme.palette.primary.contrastText,
    height: '100vh',
    overflow: 'auto',
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const MainLayout = (props) => {
  const location = useLocation();

  const [drawerOpen, setDrawerOpen] = useState(true);
  const [selectedNavItem, setSelectedNavItem] = useState('');
  const { loading } = useSelector(state => state.common);

  const dataEntryMainMenus = [{ title: 'Report' }, { title: 'Land & Crops', landAndCropsOnly: true },
  { title: 'Livestock', livestockOnly: true }, { title: 'Soil Carbon' , soilCarbonOnly: true}, { title: 'Energy & Waste' }/*, 'Notes'*/];

  const dataEntrySubMenus = [
    [
      {
        title: '',
        path: `/data-entry/report/edit`,
      }
    ],
    [
      {
        title: 'Land Area & Crops',
        path: '/data-entry/land-crops/land-area-crops'
      },
      {
        title: 'Fertiliser & Lime',
        path: '/data-entry/land-crops/fertiliser-lime'
      },
      {
        title: 'Imported Organic Materials & Manures',
        path: '/data-entry/land-crops/organic-material-manures'
      },
      {
        title: 'Transferred Manure',
        path: '/data-entry/land-crops/transferred-manure',
        livestockOnly: true
      },
      {
        title: 'Pesticides',
        path: '/data-entry/land-crops/pesticides'
      },
      {
        title: 'Crop production & use',
        path: '/data-entry/land-crops/crop-production-use'
      },
      {
        title: 'Crop use allocated to livestock',
        path: '/data-entry/land-crops/crop-use-livestock',
        livestockOnly: true
      },
      {
        title: 'Biochar',
        path: '/data-entry/land-crops/biochar',
        bioCharOnly: true
      },
    ],
    [
      {
        title: 'Livestock Numbers & Weights',
        path: '/data-entry/livestock/numbers-weights'
      },
      {
        title: 'Purchases, Sales & Deaths',
        path: '/data-entry/livestock/purchases-sales-deaths'
      },
      {
        title: 'Performance',
        path: '/data-entry/livestock/performance'
      },
      {
        title: 'Manure Management',
        path: '/data-entry/livestock/manure-management'
      },
      {
        title: 'Purchased Feeds',
        path: '/data-entry/livestock/feed'
      },
      {
        title: 'Bedding',
        path: '/data-entry/livestock/bedding'
      },
      {
        title: 'Feed Ration',
        path: '/data-entry/livestock/feed-ration'
      },
    ],
    [
      {
        title: 'Arable Rotation',
        path: '/data-entry/soil-carbon/arable-rotation',
        soilCarbonOnly: true
      },
      {
        title: 'Grassland',
        path: '/data-entry/soil-carbon/grassland',
        soilCarbonOnly: true
      },
      {
        title: 'Soil Properties',
        path: '/data-entry/soil-carbon/soil-properties',
        soilCarbonOnly: true
      }
    ],
    [
      {
        title: 'Electricity & Fuel',
        path: '/data-entry/energy-waste/electricity-fuel'
      },
      {
        title: 'Electricity & Fuel (cont)',
        path: '/data-entry/energy-waste/electricity-fuel-cont'
      },
      {
        title: 'Renewable Electricity',
        path: '/data-entry/energy-waste/renewable-electricity'
      },
      {
        title: 'Renewable Heat',
        path: '/data-entry/energy-waste/renewable-heat'
      },
      {
        title: 'Waste, Transport & Water',
        path: '/data-entry/energy-waste/waste-transport-water'
      }
    ],
    [
      ///TODO: Notes submenu
    ]
  ];

  const resultsMainMenus = [{ title: 'Whole Farm' }, { title: 'Enterprise' }/*, 'Agrecalc', 'Comparison', 'Year on Year'*/];

  const resultsSubMenus = [
    [
      { title: 'Charts', path: '/results/whole-farm/charts' },
      { title: 'Tables', path: '/results/whole-farm/tables' },
    ],
    [
      { title: 'Charts', path: '/results/enterprise/charts' },
      { title: 'Tables', path: '/results/enterprise/tables' },
    ],
  ];

  const adminMainMenus = [
    { title: 'Farms', roles: [ADMIN, SUPPER_ADMIN, CONSULTANT]},
    { title: 'Reports', roles: [ADMIN, SUPPER_ADMIN, CONSULTANT]},
    { title: 'Users', roles: [ADMIN, SUPPER_ADMIN, CONSULTANT]},
    { title: 'Organisations', roles: [ADMIN, SUPPER_ADMIN] },
    { title: 'Offices', roles: [ADMIN, SUPPER_ADMIN] },
    { title: 'Projects', roles: [ADMIN, SUPPER_ADMIN, CONSULTANT]},
    // The following code line has been commented out due to the requirement of AGC-1898
    // { title: 'Benchmarking', roles: [ADMIN, SUPPER_ADMIN] }
  ];

  const adminSubMenus = [
    [{ title: '', path: `/admin/farm/list`, }],
    [{ title: '', path: `/admin/reports/list`, }],
    [{ title: '', path: `/admin/account/list`, }],
    [{ title: '', path: `/admin/organisations/list`, }],
    [{ title: '', path: `/admin/offices/list`, }],
    [{ title: '', path: `/admin/project/list`, }],
  ]

  const leftNavigationMenus = {
    admin: {
      title: 'Admin',
      path: '/admin/farm/list',
      menus: adminMainMenus,
      subMenus: adminSubMenus,
      roles: [ADMIN, SUPPER_ADMIN, CONSULTANT]
    },
    farmReports: {
      title: 'Farm Reports',
      path: '/farm-reports/list',
      menus: [],
      subMenus: [],
      roles: []
    },
    dataEntry: {
      title: 'Report Data Entry',
      path: '/data-entry/report/edit',
      menus: dataEntryMainMenus,
      subMenus: dataEntrySubMenus,
      roles: []
    },
    results: {
      title: 'Report Results',
      path: '/results/whole-farm/charts',
      menus: resultsMainMenus,
      subMenus: resultsSubMenus,
      roles: [],
    },
    yearOnYear: {
      title: 'Year on Year',
      path: '/year-on-year',
      menus: [],
      subMenus: [],
      roles: [],
    },
  };

  useEffect(() => {
    window.pageYOffset = 0;

    const currentPath = location.pathname?.split('/')[1];
    switch (currentPath) {
      case 'dashboard':
      default:
        setSelectedNavItem('dashboard');
        break;
      case 'farm-reports':
        setSelectedNavItem('farmReports');
        break;
      case 'data-entry':
        setSelectedNavItem('dataEntry');
        break;
      case 'results':
        setSelectedNavItem('results');
        break;
      case 'admin':
        setSelectedNavItem('admin');
        break;
      case 'other-features':
        setSelectedNavItem('otherFeatures');
        break;
      case 'year-on-year':
        setSelectedNavItem('yearOnYear');
        break;  
      case '':
    }
  }, [location]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <ThemeProvider theme={mainTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Header
          drawerOpen={drawerOpen}
          topMenuItems={leftNavigationMenus[selectedNavItem]?.menus || []}
          subMenuItems={leftNavigationMenus[selectedNavItem]?.subMenus || []}
          toggleDrawer={toggleDrawer}
        />
        <SideBar
          selectedNavItem={selectedNavItem}
          drawerOpen={drawerOpen}
          leftNavMenuList={leftNavigationMenus}
          setSelectedNavItem={setSelectedNavItem}
          toggleDrawer={toggleDrawer}
        />
        <MainContainer open={drawerOpen}>
          <DrawerHeader />
          <Container maxWidth={false} sx={{ mt: 4, mb: 4, }} style={{ marginTop:'0px'}}>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={loading}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            {props.children}
            <Footer sx={{ pt: 4 }} />
          </Container>
        </MainContainer>
      </Box>
    </ThemeProvider >
  );
}

export default MainLayout;