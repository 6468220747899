
export const GET_SOIL_PROPERTIES_METADATA = 'GET_SOIL_PROPERTIES_METADATA'
export const GET_SOIL_PROPERTIES_METADATA_SUCCESS = 'GET_SOIL_PROPERTIES_METADATA_SUCCESS'
export const GET_SOIL_PROPERTIES_METADATA_FAIL = 'GET_SOIL_PROPERTIES_METADATA_FAIL'
export const RESET_SOIL_PROPERTIES_METADATA = 'RESET_SOIL_PROPERTIES_METADATA'

export const GET_SOIL_PROPERTIES_FORM_DATA_SUCCESS = 'GET_SOIL_PROPERTIES_FORM_DATA_SUCCESS'
export const GET_SOIL_PROPERTIES_FORM_DATA_FAIL = 'GET_SOIL_PROPERTIES_FORM_DATA_FAIL'
export const SET_SOIL_PROPERTIES_FORM_DATA = 'SET_SOIL_PROPERTIES_FORM_DATA'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'