import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import produce from "immer";
import {
  Grid, Paper, List
} from "@mui/material";
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import {
  FormTitleTypography, FormRowGroupTableRow, FormTableRow,
  FormTableCell, SectorTitleTypography, FormHeaderPaper,
  DataEntryTextField, FormDescriptionTypography, CustomizedDivider
} from "../../../components/CustomStyles/StyledComponents";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import ListSubheader from "@mui/material/ListSubheader";
import _ from "lodash";
import { isEqual } from "lodash";
import toastr from "toastr";
import { toastrCustomOptions, toastrCustomOptionsLonger } from "../../../constants/toastrOptions";
import { addCommas, removeCommas } from "../../../helpers/stringFormatHelper";
import { getFirstVisibleCellDetailsInDataEntry } from '../../../helpers/dataEntryHelper';
import {
  getPurchasesSalesDeathsFormData, getPurchasesSalesDeathsMetadata, resetPurchasesSalesDeathsMetadata,
  savePurchasesSalesDeathsData, setPurchasesSalesDeathsFormData,
  setPurchasesSalesDeathsOtherSalesData, resetResponseState, unsetLoading, setLoading
} from "../../../store/appAction";
import { MAPPING_PROPS_NUMERIC_4X } from "../../../constants/arrays";
import { numericRegex2X, numericRegex4X } from "../../../constants/regex";
import DataEntryButtonStack from "../../../components/Buttons/DataEntryButtonStack";
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import SelectedReport from '../../../components/SelectedReport/SelectedReport';
import store from '../../../store';

const CELL_WIDTH = {
  RowTitle: "16%",
  NumberPurchases: "15%",
  AverageLiveWeight: "15%",
  NumberSales: "15%",
  AvgNumLivestock: "15%",
  KO: "15%",
  NumberDeaths: "15%",
};

const DataEntryTable = ({ sector, initialFormData, initialOtherSalesData, firstVisibleCellDetails, locked }) => {
  toastr.options = toastrCustomOptions;
  const dispatch = useDispatch();
  const [sectorDataInt, setSectorDataInt] = useState({});
  const [sectorDataExt, setSectorDataExt] = useState({});
  const [otherSalesDataInt, setOtherSalesDataInt] = useState({});
  const [otherSalesDataExt, setOtherSalesDataExt] = useState({});
  const [colGroup, setColGroup] = useState([]);
  const TOTAL_COLUMN_COUNT = sector.columns.length;

  useEffect(() => {
    setSectorDataInt(initialFormData);
    setSectorDataExt(initialFormData);
    setOtherSalesDataInt(initialOtherSalesData);
    setOtherSalesDataExt(initialOtherSalesData);
  }, [initialFormData, initialOtherSalesData]);

  useEffect(() => {
    dispatch(
      setPurchasesSalesDeathsFormData({
        sectorId: sector.id,
        formData: sectorDataExt,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorDataExt]);

  useEffect(() => {
    dispatch(
      setPurchasesSalesDeathsOtherSalesData({
        sectorId: sector.id,
        otherSalesData: otherSalesDataExt,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherSalesDataExt]);

  useEffect(() => {
    let groupedList = _(sector ? sector.columns : [])
      .groupBy("group")
      .map((items, Group) => ({
        Title: Group,
        Length: items.length,
      }))
      .value();
    setColGroup(groupedList);
  }, [sector]);

  const handleInputChange = useCallback(
    (e, groupId, rowId, mappingProperty, isOtherSales, rule) => {
      let value = removeCommas(e.target.value);

      if (e.target.inputMode === "numeric") {
        let numericRegex = MAPPING_PROPS_NUMERIC_4X.includes(mappingProperty) ?
          numericRegex4X : numericRegex2X;
        if (numericRegex.test(value)) {
          if (rule.max < parseFloat(value)) {
            toastr.warning(
              `This input only accepts values between ${addCommas(
                rule.min
              )} and ${addCommas(rule.max)}.  Please enter the value again.`,
              undefined,
              toastrCustomOptionsLonger
            );
          } else {
            if (isOtherSales) {
              setOtherSalesDataInt(produce((draft) => {
                draft[groupId][rowId][mappingProperty] = addCommas(value);
              }));
              setOtherSalesDataExt(produce((draft) => {
                draft[groupId][rowId][mappingProperty] = parseFloat(value);
              }));
            } else {
              setSectorDataInt(produce((draft) => {
                draft[groupId][rowId][mappingProperty] = addCommas(value);
              }));
              setSectorDataExt(produce((draft) => {
                draft[groupId][rowId][mappingProperty] = parseFloat(value);
              }));
            }
          }
        }
      } else {
        setSectorDataInt(produce((draft) => {
          draft[groupId][rowId][mappingProperty] = addCommas(value);
        }));
        setSectorDataExt(produce((draft) => {
          draft[groupId][rowId][mappingProperty] = value;
        }));
      }
    },
    []
  );

  const getCellContent = ({ groupId, rowId, cell, rowData, isOtherSales, firstVisibleCellDetails, locked }) => {
    switch (cell.dataType) {
      case "TextField":
        return (
          <DataEntryTextField
            size="small"
            variant="outlined"
            inputProps={{ inputMode: "numeric" }}
            style={{ width: 125, textAlign: "center" }}
            value={rowData && addCommas(rowData[cell.mappingProperty] || '')}
            onChange={(e) =>
              handleInputChange(
                e,
                groupId,
                rowId,
                cell.mappingProperty,
                isOtherSales,
                cell.validations
              )
            }
            autoFocus={ locked === false && rowId === firstVisibleCellDetails.rowId && cell.id === firstVisibleCellDetails.cellId }
          />
        );

      default:
        return <FormTableCell sx={{ width: "12%" }}></FormTableCell>;
    }
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mb: 3 }}>
        {(sector.sectorGroups.groups || []).map((group) => (
          <>
            <SectorTitleTypography sx={{ mb: 2 }}>
              {sector.title} - {group.title}
            </SectorTitleTypography>
            <Table
              sx={{ minWidth: 700 }}
              size="small"
              aria-label="customized table"
            >
              <TableHead>
                <FormRowGroupTableRow>
                  <FormTableCell></FormTableCell>
                  {colGroup.map((column) => (
                    <FormTableCell
                      align="center"
                      colSpan={column.Length}
                      style={{
                        fontSize: "1rem",
                      }}
                    >
                      {!column.Title.includes("Empty") && column.Title}
                    </FormTableCell>
                  ))}
                </FormRowGroupTableRow>
                <FormTableRow>
                  <FormTableCell></FormTableCell>
                  {sector.columns.map((column) => (
                    <FormTableCell>
                      {column.title}
                    </FormTableCell>
                  ))}
                </FormTableRow>
              </TableHead>
              <TableBody>
                {group.rows.map((row) => (
                  <FormTableRow>
                    <FormTableCell sx={{ width: '16%' }}>{row.title}</FormTableCell>
                    {row.cells.map((cell) => (
                      <FormTableCell sx={{ width: '14%' }}>
                        {cell.visible &&
                          sectorDataInt &&
                          getCellContent({
                            groupId: group.id,
                            rowId: row.id,
                            cell: cell,
                            rowData: sectorDataInt[group.id] && sectorDataInt[group.id][row.id],
                            isOtherSales: false,
                            firstVisibleCellDetails: firstVisibleCellDetails,
                            locked: locked
                          })}
                      </FormTableCell>
                    ))}
                  </FormTableRow>
                ))}
                {sector?.sectorGroups?.sectorGroupSummary && (
                  <>
                    <FormTableRow>
                      <FormTableCell></FormTableCell>
                      {sector?.sectorGroups?.sectorGroupSummary?.map(
                        (sectorGroup) => (
                          <FormTableCell
                            colSpan={
                              TOTAL_COLUMN_COUNT /
                              sector?.sectorGroups?.sectorGroupSummary?.length
                            }
                            sx={{ width: "12%" }}
                          >
                            {sectorGroup.title}
                          </FormTableCell>
                        )
                      )}
                    </FormTableRow>
                  </>
                )}
                {group?.groupSummary && group?.groupSummary?.map((groupSum) => (
                  <FormTableRow>
                    {groupSum?.rows?.[0].cells.length > 0 && groupSum?.rows.map((row) => (
                      <>
                        <FormTableCell>{row.title}</FormTableCell>
                        {row.cells.map((cell) => (
                          <FormTableCell
                            colSpan={
                              TOTAL_COLUMN_COUNT /
                              sector?.sectorGroups?.sectorGroupSummary?.length
                            }
                            sx={{ width: "12%" }}
                          >
                            {cell.visible &&
                              otherSalesDataInt &&
                              getCellContent({
                                groupId: group.id,
                                rowId: row.id,
                                cell: cell,
                                rowData: otherSalesDataInt[group.id] && otherSalesDataInt[group.id][row.id],
                                isOtherSales: true,
                                firstVisibleCellDetails: firstVisibleCellDetails
                              })}
                          </FormTableCell>
                        ))}
                      </>
                    ))}
                  </FormTableRow>
                ))}
              </TableBody>
            </Table>
          </>
        ))}
      </TableContainer>
    </>
  );
};

const PurchasesSalesDeaths = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const reportId = useSelector(state => state.common.reportId);
  const metaData = useSelector((state) => state.purchasesSales.metaData);
  const initialFormData = useSelector((state) => state.purchasesSales.initialFormData);
  const initialOtherSalesData = useSelector((state) => state.purchasesSales.initialOtherSalesData);
  const { successMsg, errorMsg } = useSelector(state => state.common);
  const farmId = useSelector(state => state.common.farmId);
  const adminFarm = useSelector(state => state.adminFarm);
  const {reportDetails: { reportName }} = useSelector(state => state.farmReport);

  const [formData, setFormData] = useState({});
  const [otherSalesData, setOtherSalesData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [firstVisibleCellDetails, setFirstVisibleCellDetails] = useState(null);

  const sectors = metaData?.form?.sectors || [];

  useEffect(() => {
    window.pageYOffset = 0;
    dispatch(setLoading());
    dispatch(getPurchasesSalesDeathsMetadata({ reportId }));
    dispatch(getPurchasesSalesDeathsFormData({ reportId }));
    dispatch(resetResponseState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(resetPurchasesSalesDeathsMetadata());
    }
  }, []);

  useEffect(() => {
    const tempData = { ...initialFormData };
    setFormData(tempData);
    setTimeout(() => {
      dispatch(unsetLoading());
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormData]);

  useEffect(() => {
    setOtherSalesData({ ...initialOtherSalesData });
  }, [initialOtherSalesData]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(unsetLoading());
      successMsg && toastr.success(successMsg);
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(getPurchasesSalesDeathsFormData({ reportId }));
      dispatch(resetResponseState());
      if (successMsg && nextClicked) {
        navigate('/data-entry/livestock/performance');
      }
      setNextClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  useEffect(() => {
    const firstVisibleCellDetails = getFirstVisibleCellDetailsInDataEntry(sectors);
    setFirstVisibleCellDetails(firstVisibleCellDetails);
  }, [sectors]);

  const isFormUpdated = () => {
    const purchasesSalesData = store.getState().purchasesSales;
    const isUpdatedFormData = !isEqual(purchasesSalesData?.initialFormData, purchasesSalesData?.currentFormData);
    const isUpdateOtherSalesData =  !isEqual(purchasesSalesData?.initialOtherSalesData, purchasesSalesData?.currentOtherSalesData);
    if(isUpdatedFormData || isUpdateOtherSalesData){
      return true;
    }
    return false;
  }

  const handelSaveClick = (e) => {
    if(!isFormUpdated()){
      return;
    }
    dispatch(setLoading());
    dispatch(savePurchasesSalesDeathsData({ reportId }));
  }
  const handleNextClick = (e) => {
    setNextClicked(true);
    if (props.locked || !isFormUpdated())
      navigate('/data-entry/livestock/performance');
    else {
      dispatch(setLoading());
      dispatch(savePurchasesSalesDeathsData({ reportId }));
    };
  }

  const handleModalResponse = (positiveResponse) => {
    setModalOpen(false);
    if (positiveResponse) {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      window.pageYOffset = 0;
      dispatch(getPurchasesSalesDeathsMetadata({ reportId }));
      dispatch(getPurchasesSalesDeathsFormData({ reportId }));
    }
  };

  return (
    <ScrollSync>
    <Grid container spacing={2} className={props.locked && 'deactivated'}>
      <Grid item xs={12} md={12} lg={12}>
        <FormHeaderPaper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
          divider={false}
        >
          <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
          <SelectedReport reportName={reportName} />
          <FormTitleTypography variant="h5" component="div">
            {"Purchases, Sales & Deaths"}
          </FormTitleTypography>
          <FormDescriptionTypography variant="body2" paragraph>
            To calculate product level emissions, enter livestock sales and
            purchases as well as other livestock products sold from the farm.
          </FormDescriptionTypography>
        </FormHeaderPaper>
      </Grid>
      <Grid item xs={12} sx={{ paddingTop:'0 !important' }}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", paddingTop:'0' }}>
          <List sx={{ mb: 2 }}>
            <ListSubheader sx={{ bgcolor: "background.paper" }}>
              <ScrollSync>
                <>
                <DataEntryButtonStack
                  modalOpen={modalOpen}
                  setModalOpen={setModalOpen}
                  handleModalResponse={handleModalResponse}
                  handelSaveClick={handelSaveClick}
                  handleNextClick={handleNextClick}
                />
                <CustomizedDivider/>
                </>
              </ScrollSync>
            </ListSubheader>
            {firstVisibleCellDetails && sectors.map((sector, index) => (
              <>
                <React.Fragment key={index}>
                  <DataEntryTable
                    key={sector.id}
                    sector={sector}
                    initialFormData={formData[sector.id] ? formData[sector.id] : {}}
                    initialOtherSalesData={otherSalesData[sector.id] ? otherSalesData[sector.id] : {}}
                    firstVisibleCellDetails={firstVisibleCellDetails}
                    locked={props.locked}
                  />
                </React.Fragment>
              </>
            ))}
          </List> 
        </Paper>
      </Grid>
    </Grid>
    </ScrollSync>
  );
};

export default PurchasesSalesDeaths;
