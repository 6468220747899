import * as actionTypes from './types';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';
import store from '../..';

export const getGrasslandMetadata = ({ reportId }) => async (dispatch) => {

  const response = await api.get(endpoints.soilCarbon.grassland.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getGrasslandMetadataSuccess(response?.data));
  }
  else
    return dispatch(getGrasslandMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getGrasslandMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_GRASSLAND_METADATA_SUCCESS,
    payload: response
  };
};

const getGrasslandMetadataFail = (response) => {
  return {
    type: actionTypes.GET_GRASSLAND_METADATA_FAIL,
    payload: response
  };
};

export const resetGrasslandMetadata = () => {
  return {
    type: actionTypes.RESET_GRASSLAND_METADATA,
  };
};

export const getGrasslandFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.soilCarbon.grassland.formData.concat('?reportId=', reportId));
  if (response && response.status === 200 && response?.data) {
    return dispatch(getGrasslandFormDataSuccess(response?.data?.data));
  } else
    return dispatch(getGrasslandFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getGrasslandFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_GRASSLAND_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getGrasslandFormDataFail = (response) => {
  return {
    type: actionTypes.GET_GRASSLAND_FORM_DATA_FAIL,
    payload: response
  };
};

export const saveGrasslandData = ({ reportId }) => async (dispatch) => {
  const { currentFormData } = store.getState().grassland;
  const response = await api.post(endpoints.soilCarbon.grassland.saveData,
    {
      reportId: reportId,
      data: currentFormData
    });
  if (response && response.status === 200) {
    return dispatch(saveGrasslandDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(saveGrasslandDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const saveGrasslandDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveGrasslandDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};

export const setGrasslandFormData = (response) => {
  return {
    type: actionTypes.SET_GRASSLAND_FORM_DATA,
    payload: response
  };
};

export const getGrasslandOptions = () => async (dispatch) => {
  try {
    const response = await api.get(endpoints.landCrops.soilCarbon.lookup.grasslandRotationOptions);
    if (response && response.status === 200) {
      let options={}
      for(const e of (response?.data || [])){
        options[e.enumName]=e.grasslandEnums;
      }
      return dispatch(getGrasslandOptionsSuccess(options));
    }
    else
      return dispatch(getGrasslandOptionsOptionsFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (e) {
    return dispatch(getGrasslandOptionsOptionsFail(e));
  }
};

const getGrasslandOptionsSuccess = (response) => {
  return {
    type: actionTypes.GET_GRASSLAND_OPTIONS_SUCCESS,
    payload: response
  };
};

const getGrasslandOptionsOptionsFail = (response) => {
  return {
    type: actionTypes.GET_GRASSLAND_OPTIONS_FAIL,
    payload: response
  };
};






