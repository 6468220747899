import { Link, Paper, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import packageJson from '../../../package.json';

const Footer = ({ fixed }) => {
  const backendVersion  = useSelector(state => state.common.backendVersion);
  return (
    <Paper sx={{ position: fixed ? 'fixed' : 'inherit', bottom: 0, left: 0, right: 0, zIndex: 10, borderRadius: 0 }} elevation={4}>
      <Typography variant="body2" color="text.secondary" align="center" mt={2}>
        Copyright &copy; 2014-
        {new Date().getFullYear()+' '}
        <Link color="inherit" underline="hover" href="https://www.agrecalc.com/">
          Agrecalc 
        </Link> | All rights reserved.
      </Typography>
      <Typography variant="body2" color="text.secondary" align="center" mb={1}>
        <Link color="inherit" underline="hover" target="_blank" href="https://www.agrecalc.com/terms/">
          Terms & Conditions
        </Link>
      </Typography>
      <Typography variant="caption" color="text.secondary" display="block" align="center" mb={1}>
        {`Version: F-${packageJson?.applicationVersions?.versionNumber ||''}${packageJson?.applicationVersions?.commitHash?'+'+packageJson.applicationVersions?.commitHash:''}
        / B-${backendVersion?.backendVersion ||''}${backendVersion?.commitHash?'+'+backendVersion.commitHash:''} / M-${backendVersion?.calculatorVersion ||''}` }</Typography>
    </Paper>
  );
}

export default Footer
