import {
    Card,
    CardActionArea,
    CardContent,
    Grid, Paper, Table, TableBody, TableContainer,
    TableFooter,
    TableHead, Typography
} from '@mui/material'
import React, { } from 'react'
import {
    ChartTitleTypography, FormDescriptionTypographyPDF,
    FormTitleTypographyPDF, ParagraphTypographyPDF, ResultsTableCellPDF, ResultsTableRow
} from '../../../../components/CustomStyles/StyledComponents';
import { addCommas } from '../../../../helpers/stringFormatHelper';
import { FARMER_FRIENDLY_TABLES } from '../../../../constants/shellTables';
import Vertical2XBarChart from '../../../../components/Charts/Vertical2XBarChart';

const EnterpriseTables = ({ results = {}, firstChild = false }) => {

    const DataTable = ({ index, metaTable, tableData }) => {
        return <>
            {metaTable &&
                <TableContainer sx={{
                    marginTop: '70px',
                     // marginBottom: index === 1 ? '70px' : '0px'
                }}>
                    <ChartTitleTypography sx={{ mb: 2 }}>{metaTable.title}</ChartTitleTypography>
                    <Table id={index}>
                        <TableHead>
                            <ResultsTableRow>
                                {metaTable.columns.map((column) => (
                                    <ResultsTableCellPDF>
                                        {column.title}
                                    </ResultsTableCellPDF>
                                ))}
                            </ResultsTableRow>
                        </TableHead>
                        <TableBody>
                            {tableData && tableData
                                .slice(0, tableData.length - (metaTable.noFooter ? 0 : 1))
                                .map((row) => (
                                    <ResultsTableRow>
                                        {metaTable.columns.map((column) => (
                                            <ResultsTableCellPDF>{addCommas(row[column.key]) || '--'}</ResultsTableCellPDF>
                                        ))}
                                    </ResultsTableRow>
                                ))}
                        </TableBody>
                        {!metaTable.noFooter &&
                            <TableFooter sx={{ mb: 1 }}>
                                {tableData && tableData
                                    .slice(tableData.length - 1)
                                    .map((row) => (
                                        <ResultsTableRow>
                                            {metaTable.columns.map((column) => (
                                                <ResultsTableCellPDF>{addCommas(row[column.key]) || '--'}</ResultsTableCellPDF>
                                            ))}
                                        </ResultsTableRow>
                                    ))}
                            </TableFooter>}
                    </Table>
                </TableContainer>
            }
        </>
    }

    return (
        <>
            <Paper sx={{
                maxWidth: '860px',
                minWidth: '675px',
                pageBreakAfter: 'always',
                marginTop: '70px',
                borderRadius: 0,
            }}
                p={2}
            >
                {firstChild && <Typography variant='h5' sx={{ mb: 0 , mt:5}}>Section 2: Enterprise Reports</Typography>}
                <Grid item xs={12}>
                    <Paper
                        sx={{
                            p: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 0,
                            width: '100%',
                            marginTop:'10px'
                        }}
                    >
                        <FormTitleTypographyPDF fontSize={20} variant="h6" component="div" mb={1}>
                            {`Enterprise Emissions: ${results.enterpriseTitle}`}
                        </FormTitleTypographyPDF>
                    </Paper>
                </Grid>
                <Grid item xs={12} mb={1} px={0}>
                    <Grid container direction='row'>
                        <Grid item xs={4} p={1} >
                            <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                <CardActionArea sx={{ height: '100%' }}>
                                    <CardContent sx={{ padding: '5px' }}>
                                        <FormDescriptionTypographyPDF gutterBottom variant="h6" fontSize={13} mb={0} component="div">
                                            Total Emissions:
                                        </FormDescriptionTypographyPDF>
                                        {results && <FormDescriptionTypographyPDF variant="h6" fontSize={14} fontWeight="bold" component="div" color="#cc5500">
                                            {addCommas(results?.totalEmissions)}
                                        </FormDescriptionTypographyPDF>}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={4} p={1}>
                            <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                <CardActionArea sx={{ height: '100%' }}>
                                    <CardContent sx={{ padding: '5px' }}>
                                        <FormDescriptionTypographyPDF gutterBottom variant="h6" fontSize={13} mb={0} component="div">
                                            Product Emissions:
                                        </FormDescriptionTypographyPDF>
                                        {results && <FormDescriptionTypographyPDF variant="h6" fontSize={14} fontWeight="bold" component="div" color="#cc5500">
                                            {addCommas(results?.productEmissions)}
                                        </FormDescriptionTypographyPDF>}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={4} p={1} >
                            <Card sx={{ textAlign: 'center', height: '100%' }} variant='outlined'>
                                <CardActionArea sx={{ height: '100%' }}>
                                    <CardContent sx={{ padding: '5px' }}>
                                        <FormDescriptionTypographyPDF gutterBottom variant="h6" fontSize={13} mb={0} component="div">
                                            Emissions per LU:
                                        </FormDescriptionTypographyPDF>
                                        {results && <FormDescriptionTypographyPDF variant="h6" fontSize={14} fontWeight="bold" component="div" color="#cc5500">
                                            {results?.emissionPerLu ? addCommas(results?.emissionPerLu) : 'N/A'}
                                        </FormDescriptionTypographyPDF>}
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} px={0}>
                    <Grid container direction='column'>
                        {FARMER_FRIENDLY_TABLES.map((metaTable, index) => (
                            // results[table.key] && results[table.key].length > 0 &&
                            <>
                                {metaTable.key === 'quickGlanceEmissions' &&
                                    <Grid item xs={12} sx={{
                                        marginTop: '70px',
                                        pageBreakInside: 'avoid',
                                        pageBreakAfter: 'auto'
                                    }}>
                                        <Paper sx={{ width: '600px', margin: '0 auto', mt: 3 }}>
                                            <Vertical2XBarChart
                                                isAnimationActive
                                                key={3}
                                                title='Emissions vs Comparison'
                                                dataset={results.emissionVsComparison}
                                                height={240}
                                                color={{
                                                    x1: '#253764',
                                                    x2: '#ABABAB'
                                                }}
                                            />
                                        </Paper>
                                    </Grid>}
                                <Grid item xs={12} sx={{
                                    pageBreakInside: 'avoid',
                                    pageBreakAfter: 'always'
                                }}>
                                    <DataTable
                                        key={index}
                                        index={index}
                                        metaTable={metaTable}
                                        tableData={results[metaTable.key]}
                                    />
                                    {metaTable.key === 'quickGlanceEmissions' &&
                                        <>
                                            <ParagraphTypographyPDF variant="body2" paragraph mt={2} mb={0}>
                                                {'** Other includes crop residues, lime, transport and waste.'}
                                            </ParagraphTypographyPDF>
                                            <ParagraphTypographyPDF variant="body2" paragraph mt={1} mb={0}>
                                                {'*** Total emission may differ due to rounding. Emissions may be skewed on a year to year basis due to timing of sales therefore results are best monitored over a three year (minimum) period.'}
                                            </ParagraphTypographyPDF>
                                        </>}
                                </Grid>
                            </>
                        ))}
                    </Grid>
                </Grid>
            </Paper >
        </>
    )
}

export default EnterpriseTables