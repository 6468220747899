
import {
  Backdrop,
  Button,
  CircularProgress, Divider, Grid, Paper, Stack, TextField, ThemeProvider, Typography,
} from '@mui/material'
import React, { useEffect, useState  } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from "yup";
import { Formik } from 'formik';
import { resetResponseState, unsetLoading, setLoading, resetPassword } from '../../store/appAction';
import toastr from "toastr";
import { useNavigate, useLocation, } from 'react-router-dom';
import queryString from 'query-string';
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../constants/toastrOptions';
import { mainTheme } from '../../theme';
import Footer from '../../layout/BottomNavigation';
import agrecalcLogo from '../../assets/images/agrecalc-original_v2.png'
import agrecalcBackground from '../../assets/images/agrecalc-cloud-background_v2.jpg'

const ResetPassword = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  toastr.options = toastrCustomOptions;

  const [isRegister, setIsRegister] = useState(false);

  const validationSchema = Yup.object().shape({
    password: Yup
      .string()
      .min(12, 'Password must be 12 characters long')
      .matches(/[0-9]/, 'Password requires a number')
      .matches(/[a-z]/, 'Password requires a lowercase letter')
      .matches(/[A-Z]/, 'Password requires an uppercase letter')
      .matches(/[^\w]/, 'Password requires a symbol'),
    confirmPassword: Yup
      .string()
      .oneOf([Yup.ref('password'), null], "'ConfirmPassword' and 'Password' do not match."),
  });

  const { successMsg, errorMsg, loading } = useSelector(state => state.common);

  useEffect(() => {
    const { isRegister } = queryString.parse(location.search);
    if(isRegister === 'true'){
      setIsRegister(true);
    }
    return () => {
      dispatch(unsetLoading());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(unsetLoading());
      successMsg && toastr.success(successMsg);
      successMsg && navigate('/auth/login');

      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(resetResponseState());

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  const handleSubmit = (formData) => {
    const { userId, resetToken } = queryString.parse(location.search);
    dispatch(setLoading());
    dispatch(resetPassword({
      resetToken: resetToken,
      userId: userId,
      password: formData.password
    }));
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <Grid
        container
        direction="column"
        item
      // sx={{
      //   backgroundImage: `url(${agrecalcBackground})`,
      //   backgroundSize: 'cover',
      //   backgroundRepeat: 'no-repeat',
      //   backgroundPosition: 'right'
      // }}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            style={{ height: "100vh" }}

          >
            <Grid item xs={12} sm={12} md={5} sx={{ background: '#FFFFFF' }}>
              <Grid container direction='column'>
                <Backdrop
                  sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={loading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <Grid item xs="auto" sx={{ textAlign: 'center' }} pt={2}>
                  <img
                    height={80}
                    src={agrecalcLogo}
                    alt="logo"
                  />
                </Grid>
                <Grid item>
                  <Paper sx={{ padding: 4, marginX: 'auto', width: '70%', borderRadius: 0 }}>
                  {
                    isRegister ? <Typography variant="h4" sx={{ ml: 2, mb: 2 }}>Set up your password</Typography> : <Typography variant="h4" sx={{ ml: 2, mb: 2 }}>Reset Password</Typography>
                  }
                    <Grid sx={{ padding: 2 }}>
                      <Typography variant="body2" sx={{ mb: 2 }}>Please enter your new password.</Typography>
                    </Grid>
                    <Formik
                      enableReinitialize
                      render={
                        ({
                          values: {
                            password,
                            confirmPassword,
                          },
                          errors,
                          touched,
                          handleBlur,
                          handleSubmit,
                          handleChange,
                          handleReset,
                          resetForm,
                          mode,
                        }) =>
                          <>
                            <Grid sx={{ padding: 2 }}>
                              <TextField
                                fullWidth
                                id="password"
                                name="password"
                                autoComplete="password"
                                label="Password"
                                type="password"
                                helperText={touched.password ? errors.password : ""}
                                error={Boolean(touched.password ? errors.password : false)}
                                value={password}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Grid sx={{ padding: 2 }}>
                              <TextField
                                fullWidth
                                id="confirmPassword"
                                name="confirmPassword"
                                autoComplete="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                helperText={touched.confirmPassword ? errors.confirmPassword : ""}
                                error={Boolean(touched.confirmPassword ? errors.confirmPassword : false)}
                                value={confirmPassword}
                                onBlur={handleBlur}
                                onChange={handleChange}
                              />
                            </Grid>
                            <Stack sx={{ padding: 2 }}>
                              <Button
                                fullWidth
                                size="large"
                                variant="contained"
                                sx={{ textTransform: 'none' }}
                                onClick={handleSubmit}>
                                Submit
                              </Button>
                            </Stack>
                          </>}
                      initialValues={{
                        password: '',
                        confirmPassword: ''
                      }}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    />
                    <Divider>Or</Divider>
                    <Stack sx={{ padding: 2 }}>
                      <Button
                        fullWidth
                        size="large"
                        variant="outlined"
                        sx={{ textTransform: 'none' }}
                        onClick={() => navigate('/auth/login')}>
                        Login
                      </Button>
                    </Stack>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <Grid item
              sm={0}
              md={7}
              sx={{
                backgroundImage: `url(${agrecalcBackground})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right'
              }}
            >
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer fixed={true} />
    </ThemeProvider >
  )
}

export default ResetPassword