import {
  Backdrop,
  Button, CircularProgress, FormControl, Grid, List,
  ListSubheader, MenuItem, Paper,
  Skeleton, Stack,
} from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';
import { useNavigate } from 'react-router-dom';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import _ from 'lodash';
import produce from 'immer';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { isEqual } from "lodash";
import {
  FormHeaderPaper, FormTitleTypography,
  FormTableCell, FormTableRow, SectorTitleTypography,
  TableListItem,
  DataEntryTextField,
  DataEntrySelect,
  FormDescriptionTypography,
  CustomizedDivider
} from '../../../components/CustomStyles/StyledComponents';
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../../constants/toastrOptions';
import {
  getLandAreaCropsColumnTotal, getLandAreaCropsFormData,
  getLandAreaCropsMetadata, resetLandAreaCropsMetaData, getLandAreaCropsOptions, resetResponseState,
  saveLandAreaCropsData, setLandAreaCropsColumnTotal, setLandAreaCropsFormData, setLoading, unsetLoading,getFarmReport, setLandAreaCropsMiscData, getGrazingSystemOptions
} from '../../../store/appAction';
import CancelModal from '../../../components/Modal/CancelModal';
import { addCommas, removeCommas } from '../../../helpers/stringFormatHelper';
import { getFirstVisibleCellDetailsInDataEntry } from '../../../helpers/dataEntryHelper';
import { MAPPING_PROPS_SOIL_CARBON } from '../../../constants/arrays';
import BackButton from '../../../components/Buttons/BackButton';
import DataEntryButtonStack from '../../../components/Buttons/DataEntryButtonStack';
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import SelectedReport from '../../../components/SelectedReport/SelectedReport';
import store from '../../../store';

const CELL_WIDTH = {
  RowTitle: "12%",
  OwnedAndTenantedLand: "7%",
  SeasonalLand: "7%",
  AnnualOccupancyOfSeasonalLand: "7%",
  NumberOfYearsCropInTheGround: "7%",
  FractionOfLandConvertedInLast20Years: "7%",
  LandUseBeforeConvertion: "17%",
  CloverInGrassSward: "7%",
  OwnedAndTenantedLandTotal: "7%",
  SeasonalLandTotal: "7%",
  OwnedTenantedLandRYandB: "7%",
  SeasonalLandRYandB: "7%",
  GrazingSystem : "25%"
};

const LAND_AREA_TOTAL_KEY = 'TotalLandArea';
const OWNED_TENANT_LAND_KEY = 'OwnedAndTenantedLand';
const SEASONAL_LAND_KEY = 'SeasonalLand';

const TOTAL_OWNED_TENANT_LAND_KEY = 'OwnedAndTenantedLandTotal';
const TOTAL_SEASONAL_LAND_KEY = 'SeasonalLandTotal';

const DataEntryTable = ({ sector, initialFormData, landUseOptions, grazingSystemOptions, includeSoilCarbon, firstVisibleCellDetails, locked }) => {
  toastr.options = toastrCustomOptions;
  const dispatch = useDispatch();
  const [sectorDataInt, setSectorDataInt] = useState(undefined);
  const [sectorDataExt, setSectorDataExt] = useState(undefined);

  useEffect(() => {
    setSectorDataInt(initialFormData);
    setSectorDataExt(initialFormData);
  }, [initialFormData])

  useEffect(() => {
    if (sectorDataExt && !_.isEmpty(sectorDataExt)) {
      dispatch(setLandAreaCropsFormData({
        sectorId: sector.id,
        formData: sectorDataExt
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorDataExt]);

  const handleOnBlur = (e, rule) => {
    e.preventDefault();
    let component = e.currentTarget;
    let value = e.target.value;
    if (e.target.inputMode === "numeric") {
      if (value && rule.min > parseFloat(value)) {
        toastr.warning(
          `This input only accepts values between ${rule.min} and ${rule.max}.  Please enter the value again.`,
          undefined,
          toastrCustomOptionsLonger
        );
        component.focus();
      }
    }
  };

  const handleInputChange = async (e, groupId, rowId, mappingProperty, rule) => {
    const excludedSectors = ['Hedges', 'Total area (ha)']; //temporarily check by title

    let value = e.target.value;
    const colTotal = await getLandAreaCropsColumnTotal();
    let tempColData = { ...colTotal };

    if (e.target.inputMode === 'numeric') {
      value = removeCommas(value);
      let numericRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
      if (numericRegex.test(value)) {
        let numericValue = parseFloat(value || 0);
        const max = /*rule.maxNoYear ? parseFloat(noYear || 0) :*/ rule?.max || 100; //noYear value may retrieve separately 
        if (max < numericValue) {
          toastr.warning(
            `This input only accepts values between ${rule.min} and ${addCommas(max)}.  Please enter the value again.`,
            undefined,
            toastrCustomOptionsLonger
          );
        }
        else {
          if (!excludedSectors.includes(sector.title) &&
            ([OWNED_TENANT_LAND_KEY, SEASONAL_LAND_KEY].includes(mappingProperty))) {
            let currentColTotal =
              parseFloat(tempColData[mappingProperty.concat('Total')] || 0) - parseFloat(sectorDataInt[groupId][rowId][mappingProperty] || 0);
            let areaTotal = currentColTotal + numericValue;
            tempColData[mappingProperty.concat('Total')] = parseFloat(areaTotal);
            dispatch(setLandAreaCropsColumnTotal(tempColData));
          }
          setSectorDataInt(produce((draft) => {
            draft[groupId][rowId][mappingProperty] = value;
            draft[groupId][rowId][mappingProperty] = value;
            draft[groupId][rowId][LAND_AREA_TOTAL_KEY] =
              parseFloat(draft[groupId][rowId][OWNED_TENANT_LAND_KEY] || 0) +
              parseFloat(draft[groupId][rowId][SEASONAL_LAND_KEY] || 0);
          }));
          setSectorDataExt(produce((draft) => {
            draft[groupId][rowId][mappingProperty] = parseFloat(value);
            draft[groupId][rowId][mappingProperty] = parseFloat(value);
            draft[groupId][rowId][LAND_AREA_TOTAL_KEY] =
              parseFloat(draft[groupId][rowId][OWNED_TENANT_LAND_KEY] || 0) +
              parseFloat(draft[groupId][rowId][SEASONAL_LAND_KEY] || 0);
          }));
        }
      }
    }
    else {
      setSectorDataInt(produce((draft) => {
        draft[groupId][rowId][mappingProperty] = value;
      }));
      setSectorDataExt(produce((draft) => {
        draft[groupId][rowId][mappingProperty] = value || null;
      }));
    }
  };

  const fetchRowTitle = (title, landAreaTotal) => {
    const totalInKm = ['Hedges']; //temporarily check by title
    return title.concat(
      ' (', addCommas((landAreaTotal && landAreaTotal[LAND_AREA_TOTAL_KEY]) || 0),
      ' ', totalInKm.includes(sector.title) ? 'km' : 'ha', ')');
  }

  const getCellContent = ({ groupId, rowId, cell, rowData, firstVisibleCellDetails, locked }) => {
    switch (cell.dataType) {
      case 'TextField':
        return (
          <DataEntryTextField
            key={rowId + cell.mappingProperty}
            size="small"
            variant="outlined"
            inputProps={{ inputMode: 'numeric' }}
            style={{ width: 75 }}
            value={(rowData && addCommas(rowData[cell.mappingProperty])) || ''}
            onChange={(e) => handleInputChange(
              e,
              groupId,
              rowId,
              cell.mappingProperty,
              cell.validations)}
            onBlur={(e) => handleOnBlur(e, cell.validations)}
            autoFocus={ locked === false && rowId === firstVisibleCellDetails.rowId && cell.id === firstVisibleCellDetails.cellId }
          />
        );
      case 'Select':
        return (
          <FormControl
            key={rowId + cell.mappingProperty}
            variant="outlined"
            sx={{ width: '98%', margin: 0 }}
          >
            <DataEntrySelect
              displayEmpty
              value={rowData && rowData[cell.mappingProperty] !== null ?
                rowData[cell.mappingProperty] : ''}
              onChange={(e) => handleInputChange(
                e,
                groupId,
                rowId,
                cell.mappingProperty,
                cell.validations)}
              autoFocus={ locked === false && rowId === firstVisibleCellDetails.rowId && cell.id === firstVisibleCellDetails.cellId }
            >
              <MenuItem value=""><em>Please Select</em></MenuItem>
              {cell.mappingProperty === 'LandUseBeforeConvertion' && landUseOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
              ))}
              {cell.mappingProperty === 'GrazingSystem' && grazingSystemOptions.map((option) => (
                <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
              ))}
            </DataEntrySelect>
          </FormControl>
        );
      default:
        return <></>;
    }
  }

  return (
    <>
      {sector ?
        <ScrollSyncPane>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: '900px' }} size="small" aria-label="customized table">
              <TableBody>
                {(sector.sectorGroups.groups || []).map((group) => (
                  <Fragment>
                    {group.rows.map((row) => (
                      <FormTableRow>
                        <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }} >
                          {sectorDataInt &&
                            fetchRowTitle(row.title, sectorDataInt[group.id] && sectorDataInt[group.id][row.id])}
                        </FormTableCell>
                        {row.cells.map((cell) => (
                          <>
                            {
                              includeSoilCarbon ?
                                <FormTableCell sx={{ width: CELL_WIDTH[cell.mappingProperty] }}>
                                  {cell.visible && sectorDataInt &&
                                    getCellContent({
                                      groupId: group.id,
                                      rowId: row.id,
                                      cell: cell,
                                      rowData: sectorDataInt[group.id] && sectorDataInt[group.id][row.id],
                                      firstVisibleCellDetails: firstVisibleCellDetails,
                                      locked: locked
                                    })}
                                </FormTableCell> :
                                !MAPPING_PROPS_SOIL_CARBON.includes(cell.mappingProperty) &&
                                <FormTableCell sx={{ width: CELL_WIDTH[cell.mappingProperty] }}>
                                  {cell.visible && sectorDataInt &&
                                    getCellContent({
                                      groupId: group.id,
                                      rowId: row.id,
                                      cell: cell,
                                      rowData: sectorDataInt[group.id] && sectorDataInt[group.id][row.id],
                                      firstVisibleCellDetails: firstVisibleCellDetails,
                                      locked: locked
                                    })}
                                </FormTableCell>
                            }
                          </>
                        ))}
                      </FormTableRow>
                    ))}

                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScrollSyncPane>
        : <Skeleton variant="rectangular" width={'100%'} height={150} />
      }
    </>
  );
}

const TotalTable = ({ sector, includeSoilCarbon }) => {
  toastr.options = toastrCustomOptions;
  const columnTotal = useSelector(state => state.landAreaCrops.columnTotal);
  const miscData = useSelector(state => state.landAreaCrops.currentReportData);
  const currentReportData = useSelector(state => state.landAreaCrops.currentReportData);
  const [totalTableData, setTotalTableData] = useState([]);

  useEffect(() => {
    let tempColData = { ...columnTotal };
    const areaTotalOfOwnedTenantedLand = parseFloat(tempColData['OwnedAndTenantedLandTotal'] || 0 )  + parseFloat(miscData['OwnedTenantedLandRYandB'] || 0 );
    tempColData['OwnedAndTenantedLandTotal'] = parseFloat(areaTotalOfOwnedTenantedLand);

    const areaTotalOfSeasonalLand = parseFloat(tempColData['SeasonalLandTotal'] || 0 )  + parseFloat(miscData['SeasonalLandRYandB'] || 0 );
    tempColData['SeasonalLandTotal'] = parseFloat(areaTotalOfSeasonalLand);
    setTotalTableData(tempColData);

  }, [columnTotal,miscData,currentReportData ]);
  

  const fetchRowTitle = (title) => {
    let total = parseFloat(totalTableData[TOTAL_OWNED_TENANT_LAND_KEY] || 0) + parseFloat(totalTableData[TOTAL_SEASONAL_LAND_KEY] || 0)
    return title.concat(' (', total.toFixed(2), ' ha)');
  }

  const getCellContent = (cell) => {
    switch (cell.dataType) {
      case 'TextField':
        return (
          <DataEntryTextField
            key={cell.mappingProperty}
            size="small"
            variant="outlined"
            inputProps={{ inputMode: 'numeric' }}
            style={{ width: 75 }}
            disabled={true}
            value={addCommas((totalTableData[cell.mappingProperty] || 0).toFixed(2))}
          />
        );
      default:
        return <></>;
    }
  }
  return (
    <>
      {totalTableData ?
        <ScrollSyncPane>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: '900px' }} size="small" aria-label="customized table">
              <TableBody>
                {(sector.sectorGroups.groups || []).map((group) => (
                  <Fragment>
                    {group.rows.map((row) => (
                      <FormTableRow>
                        <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }} >
                          {fetchRowTitle(row.title)}
                        </FormTableCell>
                        {row.cells.map((cell) => (
                          <>
                            {
                              includeSoilCarbon ?
                                <FormTableCell sx={{ width: CELL_WIDTH[cell.mappingProperty] }}>
                                  {cell.visible && totalTableData &&
                                    getCellContent(cell)}
                                </FormTableCell> :
                                !MAPPING_PROPS_SOIL_CARBON.includes(cell.mappingProperty) &&
                                <FormTableCell sx={{ width: CELL_WIDTH[cell.mappingProperty] }}>
                                  {cell.visible && totalTableData &&
                                    getCellContent(cell)}
                                </FormTableCell>
                            }
                          </>
                        ))}
                      </FormTableRow>
                    ))}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScrollSyncPane>
        : <Skeleton variant="rectangular" width={'100%'} height={150} />
      }
    </>
  );
};

const MiscTable = ({ initialMiscData, metaData, includeSoilCarbon }) => {
  const dispatch = useDispatch();
  const [miscDataInt, setMiscDataInt] = useState(undefined);
  const [miscDataExt, setMiscDataExt] = useState(undefined);

  useEffect(() => {
    setMiscDataInt(initialMiscData);
    setMiscDataExt(initialMiscData);
  }, [initialMiscData]);

  useEffect(() => {
    if (miscDataExt && !_.isEmpty(miscDataExt)) {
      dispatch(setLandAreaCropsMiscData(miscDataExt));
    }
  }, [miscDataExt]);

  const handleMiscInputChange = async (e, mappingProperty, rule) => {
    let value = e.target.value;
    if (e.target.inputMode === 'numeric') {
      
      let numericRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
      if (numericRegex.test(value)) {
        const colTotal = await getLandAreaCropsColumnTotal();
        let tempColData = { ...colTotal };
        value = removeCommas(value);
        let numericValue = parseFloat(value || 0);

        if(mappingProperty === 'OwnedTenantedLandRYandB'){
          let currentColTotal = parseFloat(tempColData['OwnedAndTenantedLandTotal'] || 0 )  - parseFloat(miscDataInt['OwnedTenantedLandRYandB']|| 0);
          let areaTotal = currentColTotal + numericValue;
          tempColData['OwnedAndTenantedLandTotal'] = parseFloat(areaTotal);
        }
        if(mappingProperty === 'SeasonalLandRYandB'){
          const currentColTotal = parseFloat(tempColData['SeasonalLandTotal'] || 0 )  - parseFloat(miscDataInt['SeasonalLandRYandB']|| 0);
          let areaTotal = currentColTotal + numericValue;
          tempColData['SeasonalLandTotal'] = parseFloat(areaTotal);
        }
        
        setMiscDataInt(produce((draft) => {
          draft[mappingProperty]= value;
        }));
        setMiscDataExt(produce((draft) => {
          draft[mappingProperty] = value;
        }));
      }
    }
  };

  const getCellContent = (cell) => {
    switch (cell.dataType) {
      case 'TextField':
        return (
          <DataEntryTextField
            key={cell.mappingProperty}
            size="small"
            variant="outlined"
            inputProps={{ inputMode: 'numeric' }}
            style={{ width: 75 }}
            disabled={cell?.readOnly}
            value={ miscDataInt[cell.mappingProperty] || ''}
            onChange={(e) => handleMiscInputChange(
              e,
              cell.mappingProperty,
              cell.validations)}
          />
        );
      default:
        return <></>;
    }
  }


  const fetchRowTitle = (title) => {
    let total = parseFloat(miscDataInt['OwnedTenantedLandRYandB'] || 0) + parseFloat(miscDataInt['SeasonalLandRYandB'] || 0)
    return title.concat(' (', total.toFixed(2) || 0, ' ha)');
  }

  return(
    <> 
    <Fragment>
      { miscDataInt && metaData?.sections?.map((section) => {
        return(
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: '900px' }} size="small" aria-label="customized table">
              <TableHead >
                <FormTableRow>
                  <FormTableCell colSpan={9} sx={{ textAlign:'left'}} >
                    {section.title}
                  </FormTableCell>
                </FormTableRow> 
              </TableHead>
              <TableBody>
                {section?.rows?.map((row) => {
                  return(
                    <FormTableRow>
                      <FormTableCell sx={{ textAlign:'left', width: CELL_WIDTH['RowTitle']}} >
                         { fetchRowTitle('Other land - roads, yards, buildings ')}
                      </FormTableCell>
                      { row?.cells?.map((cell) => {
                        return(
                          <>
                          {
                            includeSoilCarbon ? 
                            <FormTableCell sx={{  width: CELL_WIDTH[cell.mappingProperty]}}>
                              {cell.visible && getCellContent(cell)}
                            </FormTableCell>
                            :
                            !MAPPING_PROPS_SOIL_CARBON.includes(cell.mappingProperty) &&
                            <FormTableCell sx={{ width: CELL_WIDTH[cell.mappingProperty] }}>
                                {cell.visible  && getCellContent(cell)}
                            </FormTableCell>
                          }
                          </>
                        )
                      
                      })}
                    </FormTableRow>
                )})}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )})}  
    </Fragment> 
    </>
  );
}

const LandCrops = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const reportId = useSelector(state => state.common.reportId);
  const metaData = useSelector(state => state.landAreaCrops.metaData);
  const landUseOptions = useSelector(state => state.landAreaCrops.landUseOptions);
  const grazingSystemOptions = useSelector(state => state.soilCarbon.grazingSystemOptions);
  const initialFormData = useSelector(state => state.landAreaCrops.initialFormData);
  const initialReportData = useSelector(state => state.landAreaCrops.initialReportData);
  const
    { reportDetails,
      reportConfig: { livestockOnly }
    } = useSelector(state => state.farmReport);
  const { successMsg, errorMsg } = useSelector(state => state.common);
  const farmId = useSelector(state => state.common.farmId);
  const adminFarm = useSelector(state => state.adminFarm);

  const [formData, setFormData] = useState({})
  const [miscData, setMiscData] = useState({})
  const [modalOpen, setModalOpen] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [firstVisibleCellDetails, setFirstVisibleCellDetails] = useState(null);

  const sectors = metaData?.form?.sectors || [];
  const columns = metaData?.form?.columns || [];

 const miscTableMeta = {
  "sections": [
      {
          "id": 1,
          "title": "Roads, yards & buildings",
          "rows": [
              {
                  "id": 1,
                  "title": "Other land - roads, yards, buildings (x ha)",
                  "cells": [
                      {
                          "id": 2,
                          "dataType": "TextField",
                          "visible": true,
                          "readOnly": false,
                          "validations": null,
                          "formulatedValidations": null,
                          "mappingProperty": "OwnedTenantedLandRYandB"
                      },
                      {
                          "id": 3,
                          "dataType": "TextField",
                          "visible": true,
                          "readOnly": false,
                          "validations": null,
                          "formulatedValidations": null,
                          "mappingProperty": "SeasonalLandRYandB"
                      },
                      {
                        "id": 4,
                        "dataType": "TextField",
                        "visible": false,
                        "mappingProperty": "AnnualOccupancyOfSeasonalLand"
                      },
                      {
                        "id": 5,
                        "dataType": "TextField",
                        "visible": false,
                        "mappingProperty": "NumberOfYearsCropInTheGround"
                      },
                      {
                        "id": 6,
                        "dataType": "TextField",
                        "visible": false,
                        "mappingProperty": "FractionOfLandConvertedInLast20Years"
                      },
                      {
                        "id": 7,
                        "dataType": "TextField",
                        "visible": false,
                        "mappingProperty": "LandUseBeforeConvertion"
                      },
                      {
                        "id": 8,
                        "dataType": "TextField",
                        "visible": false,
                        "mappingProperty": "CloverInGrassSward"
                      },
                      {
                        "id": 9,
                        "dataType": "Select",
                        "visible": false,
                        "mappingProperty": "GrazingSystem"
                      }
                  ],
                  "mappingProperty": null,
                  "rowTotal": 0
              }
          ]
      }
  ]
}

  const totalTableMeta = {
    "title": "Total area (ha)",
    "sectorGroups": {
      "sectorGroupSummaries": null,
      "groups": [
        {
          "title": "Total",
          "rows": [
            {
              "title": "Total",
              "cells": [
                {
                  "dataType": "TextField",
                  "visible": true,
                  "mappingProperty": "OwnedAndTenantedLandTotal",
                },
                {
                  "dataType": "TextField",
                  "visible": true,
                  "mappingProperty": "SeasonalLandTotal",
                },
                {
                  "dataType": "TextField",
                  "visible": false,
                  "mappingProperty": "AnnualOccupancyOfSeasonalLand",
                },
                {
                  "dataType": "TextField",
                  "visible": false,
                  "mappingProperty": "NumberOfYearsCropInTheGround",
                },
                {
                  "dataType": "TextField",
                  "visible": false,
                  "mappingProperty": "FractionOfLandConvertedInLast20Years",
                },
                {
                  "dataType": "Select",
                  "visible": false,
                  "mappingProperty": "LandUseBeforeConvertion",
                },
                {
                  "dataType": "TextField",
                  "visible": false,
                  "mappingProperty": "CloverInGrassSward",
                },
                {
                  "dataType": "Select",
                  "visible": false,
                  "mappingProperty": "GrazingSystem"
                }
              ]
            }
          ],
        }
      ]
    }
  }

  useEffect(() => {
    dispatch(setLoading());
    dispatch(getFarmReport({ reportId }));
    dispatch(getLandAreaCropsOptions());
    dispatch(getGrazingSystemOptions());
    dispatch(getLandAreaCropsMetadata({ reportId }));
    dispatch(getLandAreaCropsFormData({ reportId }));
    dispatch(resetResponseState());
    //dispatch(resetLandAreaCropsColumnTotal());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(resetLandAreaCropsMetaData());
    }
  }, []);

  useEffect(() => {
    if (livestockOnly) {
      dispatch(unsetLoading());
      navigate('/data-entry/livestock/numbers-weights')
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [livestockOnly]);

  useEffect(() => {
    if (initialFormData && !_.isEmpty(initialFormData)) {
      const tempData = { ...initialFormData };
      dispatch(unsetLoading());
      setFormData(tempData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormData]);

  useEffect(() => {
    if (initialReportData && !_.isEmpty(initialReportData)) {
      const tempData = { ...initialReportData };
      dispatch(unsetLoading());
      setMiscData(tempData);
    }
  }, [initialReportData]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(unsetLoading());
      successMsg && toastr.success(successMsg);
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(getLandAreaCropsFormData({ reportId }));
      dispatch(resetResponseState());
      if (successMsg && nextClicked) {
        navigate('/data-entry/land-crops/fertiliser-lime');
      }
      setNextClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  useEffect(() => {
    const firstVisibleCellDetails = getFirstVisibleCellDetailsInDataEntry(sectors);
    setFirstVisibleCellDetails(firstVisibleCellDetails);
  }, [sectors]);

  const handleModalResponse = (positiveResponse) => {
    setModalOpen(false);
    if (positiveResponse) {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      window.pageYOffset = 0;
      dispatch(getLandAreaCropsMetadata({ reportId }));
      dispatch(getLandAreaCropsFormData({ reportId }));
    }
  }

  const isFormUpdated = () => {
    const landAreaCropsData = store.getState().landAreaCrops;
    const isUpdatedFormData = !isEqual(landAreaCropsData?.initialFormData, landAreaCropsData?.currentFormData);
    const isUpdateMiscData =  !isEqual(landAreaCropsData?.initialReportData, landAreaCropsData?.currentReportData);
    if(isUpdatedFormData || isUpdateMiscData){
      return true;
    }
    return false;
  }

  const handelSaveClick = (e) => {
    if(!isFormUpdated()){
      return;
    }
    dispatch(setLoading());
    dispatch(saveLandAreaCropsData({ reportId }));
  }

  const handleNextClick = (e) => {
    setNextClicked(true);
    if (props.locked || !isFormUpdated()){
      navigate('/data-entry/land-crops/fertiliser-lime');
    }
    else {
      dispatch(setLoading());
      dispatch(saveLandAreaCropsData({ reportId }));
    }
  }

  return (
    <ScrollSync>
      <Grid container spacing={2} className={props.locked && 'deactivated'}>
        <Grid item xs={12} md={12} lg={12}>
          <FormHeaderPaper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
            divider={false}
          >
            <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
            <SelectedReport reportName={reportDetails?.reportName} />
            <FormTitleTypography variant="h5" component="div">
              {'Land Area & Crops'}
            </FormTitleTypography>
            <FormDescriptionTypography variant="body2" paragraph>
              For all land uses on the farm, enter total area and land use history where relevant.
            </FormDescriptionTypography>
          </FormHeaderPaper>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop:'0 !important' }}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', paddingTop:'0' }}>
            <List sx={{ mb: 2 }}>
              <ListSubheader sx={{ bgcolor: "background.paper" }}>
                <ScrollSyncPane>
                  <>
                  <DataEntryButtonStack
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    handleModalResponse={handleModalResponse}
                    handelSaveClick={handelSaveClick}
                    handleNextClick={handleNextClick}
                  />
                  <CustomizedDivider/>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: '900px' }} size="small" aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }}></FormTableCell>
                          {columns.map((column) =>
                            <FormTableCell align="center" sx={{ width: CELL_WIDTH[column.cssClass] }}>
                              {column.title}
                            </FormTableCell>)
                          }
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                  </>
                </ScrollSyncPane>
              </ListSubheader>
              {sectors.map((sector, index) => (
                <React.Fragment key={index}>
                  <TableListItem>
                    <SectorTitleTypography variant='body1'>{sector.title}</SectorTitleTypography>
                  </TableListItem>
                  <TableListItem>
                    <DataEntryTable
                      key={sector.id}
                      sector={sector}
                      initialFormData={formData[sector.id] ? formData[sector.id] : {}}
                      landUseOptions={landUseOptions}
                      grazingSystemOptions={grazingSystemOptions}
                      includeSoilCarbon={reportDetails?.includeSoilCarbon}
                      firstVisibleCellDetails={firstVisibleCellDetails}
                      locked={props.locked}
                    />
                  </TableListItem>
                </React.Fragment>
              ))}
              {miscData && 
                <React.Fragment key={-2}>
                  <TableListItem>
                    <SectorTitleTypography variant='body1'>{'Misc'} </SectorTitleTypography>
                  </TableListItem>
                  <TableListItem>
                    <MiscTable
                      key={-12}
                      initialMiscData =  { miscData }
                      metaData = { miscTableMeta }
                      sector={totalTableMeta}
                      includeSoilCarbon={reportDetails?.includeSoilCarbon}
                    />
                  </TableListItem>
              </React.Fragment>
              }
              <React.Fragment key={-1}>
                <TableListItem>
                  <SectorTitleTypography variant='body1'>{totalTableMeta.title}</SectorTitleTypography>
                </TableListItem>
                <TableListItem>
                  <TotalTable
                    key={-11}
                    sector={totalTableMeta}
                    includeSoilCarbon={reportDetails?.includeSoilCarbon}
                  />
                </TableListItem>
              </React.Fragment>
            </List>
          </Paper>
        </Grid>
      </Grid>
    </ScrollSync>
  );
}

export default LandCrops;

