import { Grid,Paper } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FormDescriptionTypography, FormHeaderPaper, FormTitleTypography } from '../../../components/CustomStyles/StyledComponents';
import SiteAdminForm from "../../../components/Forms/SiteAdminForm";
import { Formik } from "formik";
import { setLoading, unsetLoading, getSiteAdminData,resetResponseState, updateSiteAdminData } from "../../../store/appAction";
import { useDispatch, useSelector } from "react-redux";
import toastr from "toastr";
import _ from 'lodash';
import { toastrCustomOptionsLonger } from "../../../constants/toastrOptions";
const SiteAdmin = () => {
    const dispatch = useDispatch();
    const siteAdminDetails = useSelector(state => state.common.siteAdmin);
    const { successMsg, errorMsg } = useSelector(state => state.common);

    const [intSiteAdminDetails, setIntSiteAdminDetails] = useState(undefined);
    const [formData, setFormData] = useState(undefined);

    useEffect(() => {
        dispatch(getSiteAdminData());
    }, []);
    const handleSubmit = data => {
        dispatch(updateSiteAdminData(data));
    };

    useEffect(() => {
        setIntSiteAdminDetails(siteAdminDetails);
    }, [siteAdminDetails]);

    useEffect(() => {
        if (intSiteAdminDetails && !_.isEmpty(intSiteAdminDetails)) {
            setFormData({
                bannerContent : intSiteAdminDetails?.bannerContent,
                isBannerVisible : intSiteAdminDetails?.isBannerVisible,
                isSiteLocked : intSiteAdminDetails?.isSiteLocked,
            });
            dispatch(unsetLoading());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intSiteAdminDetails]);

    useEffect(() => {
        if (successMsg || errorMsg) {
            dispatch(unsetLoading());
            successMsg && toastr.success(successMsg);
            errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
            dispatch(resetResponseState());
        }
    }, [successMsg, errorMsg]);
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <FormHeaderPaper
                        sx={{
                            p: 0,
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft:'1.2rem'
                        }}
                    >
                        <FormTitleTypography variant="h5" component="div">
                            Site Banner
                        </FormTitleTypography>
                        <FormDescriptionTypography variant="body2" paragraph>
                            {'Setup and enable the site banner.'}
                        </FormDescriptionTypography>
                    </FormHeaderPaper>
                </Grid>
                <Grid item md={12} >
                    <Paper
                        sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            marginLeft: '1.2rem'
                        }}
                    >
                    { formData && 
                        <Formik
                            enableReinitialize
                            render={props => <SiteAdminForm {...props}/>}
                            initialValues={ formData }
                            onSubmit={handleSubmit}
                        />
                    }
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}
export default SiteAdmin