import {
  Button, Grid, List,
  ListSubheader, Paper, Skeleton, Stack, Table, TableBody, TableContainer,
  TableHead, TableRow, Typography
} from '@mui/material';
import React, { useState, useEffect, Fragment, useLayoutEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';
import {
  FormHeaderPaper, FormTitleTypography, FormTableCell,
  SectorTitleTypography, TableListItem, FormTableRow, DataEntryTextField, FormDescriptionTypography, CustomizedDivider
} from '../../../components/CustomStyles/StyledComponents';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { isEqual } from "lodash";
import produce from 'immer';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import {
  getColumnTotal,
  getTransferredManureFormData,
  resetTransferredManureMetaData,
  getTransferredManureMetaData,
  resetColumnTotal,
  resetResponseState,
  saveTransferredManureData,
  setColumnTotal,
  setLoading,
  setTransferredManureFormData,
  unsetLoading
} from '../../../store/appAction';
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../../constants/toastrOptions';
import CancelModal from '../../../components/Modal/CancelModal';
import { addCommas, removeCommas } from '../../../helpers/stringFormatHelper';
import { getFirstVisibleCellDetailsInDataEntry } from '../../../helpers/dataEntryHelper';
import BackButton from '../../../components/Buttons/BackButton';
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import SelectedReport from '../../../components/SelectedReport/SelectedReport';
import store from '../../../store';

const CELL_WIDTH = {
  RowTitle: "16%",
  BeefLiquidManurePercentage: '9%',
  BeefSolidManurePercentage: '9%',
  DairyLiquidManurePercentage: '9%',
  DairySolidManurePercentage: '9%',
  PigsLiquidManurePercentage: '9%',
  PigsSolidManurePercentage: '9%',
  SheepLiquidManurePercentage: '9%',
  SheepSolidManurePercentage: '9%',
  WithOrWithoutLitterPercentage: '9%'
};

const LAND_AREA_TOTAL_KEY = 'TotalLandArea';
const MAX_COLUMN_TOTAL = 100;

const DataEntryTable = ({ sector, initialFormData, firstVisibleCellDetails,locked }) => {
  toastr.options = toastrCustomOptions;

  const dispatch = useDispatch();
  const [sectorDataInt, setSectorDataInt] = useState(undefined);
  const [sectorDataExt, setSectorDataExt] = useState(undefined);

  useEffect(() => {
    setSectorDataInt(initialFormData);
    setSectorDataExt(initialFormData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormData])

  useEffect(() => {
    if (sectorDataExt && !_.isEmpty(sectorDataExt)) {
      dispatch(setTransferredManureFormData({
        sectorId: sector.id,
        formData: sectorDataExt
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorDataExt]);

  const handleInputChange = useCallback(async (e, groupId, rowId, rowData, mappingProperty, rule, landAreaTotal) => {
    let value = removeCommas(e.target.value);
    const colTotal = await getColumnTotal();
    let tempColumnData = { ...colTotal };

    if (e.target.inputMode === 'numeric') {
      let numericRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
      if (numericRegex.test(value)) {
        let numericValue = parseFloat(value || 0);
        let currentColTotal =
          parseFloat(tempColumnData[mappingProperty] || 0) -
          parseFloat(rowData[mappingProperty] || 0);
        const max = rule?.max || 100;
        if (rule.min > numericValue || max < numericValue) {
          toastr.warning(`This input only accepts values between ${rule.min} and ${addCommas(max)}. Please enter the value again.`,
            undefined,
            toastrCustomOptionsLonger
          );
        } else if (MAX_COLUMN_TOTAL < currentColTotal + numericValue) {
          toastr.warning(`The total for this column cannot be greater than ${MAX_COLUMN_TOTAL}.`,
            undefined,
            toastrCustomOptionsLonger
          );
        } else {
          setSectorDataInt(produce((draft) => {
            draft[groupId][rowId][mappingProperty] = value;
          }));
          setSectorDataExt(produce((draft) => {
            draft[groupId][rowId][mappingProperty] = parseFloat(value);
          }));
          tempColumnData[mappingProperty] = currentColTotal + numericValue;
          dispatch(setColumnTotal(tempColumnData));
        }
      }
    }
  }, []);

  const getCellContent = ({ groupId, rowId, cell, rowData, landAreaTotal, firstVisibleCellDetails,locked }) => {
    switch (cell.dataType) {
      case 'TextField':
        return (
          <DataEntryTextField
            size="small"
            variant="outlined"
            inputProps={{ inputMode: 'numeric' }}
            style={{ width: 70, textAlign: 'center' }}
            value={(rowData && addCommas(rowData[cell.mappingProperty])) || ''}
            onChange={(e) => handleInputChange(
              e,
              groupId,
              rowId,
              rowData,
              cell.mappingProperty,
              cell.validations,
              landAreaTotal)}
              autoFocus={locked === false && rowId === firstVisibleCellDetails.rowId && cell.id === firstVisibleCellDetails.cellId} 
          />
        );
      case 'Empty':
      default:
        return (<FormTableCell sx={{ width: '8%' }}>{''}</FormTableCell>);
    }
  }

  const fetchRowTitle = (title, totalArea) => {
    const totalInKm = ['Hedges']; //temporarily check by title
    return title.concat(
      ' (', addCommas(totalArea || 0),
      ' ', totalInKm.includes(sector.title) ? 'km' : 'ha', ')');
  }

  return (
    <>{
      sector ?
        <ScrollSyncPane>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
              <TableBody>
                {(sector.sectorGroups.groups || []).map((group) => (
                  <Fragment>
                    {group.rows.map((row) => (
                      <FormTableRow>
                        <FormTableCell sx={{ width: CELL_WIDTH["RowTitle"] }}>
                          {sectorDataInt &&
                            fetchRowTitle(row.title, sectorDataInt[group.id] &&
                              sectorDataInt[group.id][row.id][LAND_AREA_TOTAL_KEY])}
                        </FormTableCell>
                        {row.cells.map((cell) => (
                          <FormTableCell sx={{ width: '8%' }}>
                            {sectorDataInt && cell.visible &&
                              getCellContent({
                                groupId: group.id,
                                rowId: row.id,
                                cell: cell,
                                rowData: sectorDataInt[group.id] && sectorDataInt[group.id][row.id],
                                landAreaTotal: sectorDataInt[group.id] && sectorDataInt[group.id][row.id],
                                firstVisibleCellDetails: firstVisibleCellDetails,
                                locked:locked
                              })}
                          </FormTableCell>
                        ))}
                      </FormTableRow>
                    ))}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScrollSyncPane>
        : <Skeleton variant="rectangular" width={'100%'} height={150} />
    }
    </>
  );
}

const TransferredManure = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const reportId = useSelector(state => state.common.reportId);
  const metaData = useSelector(state => state.transferredManure.metaData);
  const initialFormData = useSelector(state => state.transferredManure.initialFormData);
  const { successMsg, errorMsg } = useSelector(state => state.common);
  const farmId = useSelector(state => state.common.farmId);
  const adminFarm = useSelector(state => state.adminFarm);
  const {reportDetails: { reportName }} = useSelector(state => state.farmReport);

  const [formData, setFormData] = useState({})
  const [modalOpen, setModalOpen] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [colGroups, setColGroups] = useState(undefined);
  const [firstVisibleCellDetails, setFirstVisibleCellDetails] = useState(null);

  const sectors = metaData?.form?.sectors || [];
  const columns = metaData?.form?.columns || [];

  useEffect(() => {
    dispatch(setLoading());
    dispatch(getTransferredManureMetaData({ reportId }));
    dispatch(getTransferredManureFormData({ reportId }));
    dispatch(resetResponseState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(resetTransferredManureMetaData());
    }
  }, []);

  useEffect(() => {
    let groupedList = _(columns ? columns : [])
      .groupBy("group")
      .map((items, Group) => ({
        title: Group,
        length: items.length,
      }))
      .value();
    setColGroups(groupedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  useEffect(() => {
    if (initialFormData && !_.isEmpty(initialFormData)) {
      const tempData = { ...initialFormData };
      dispatch(unsetLoading());
      setFormData(tempData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormData]);

  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(unsetLoading());
      successMsg && toastr.success(successMsg);
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(getTransferredManureFormData({ reportId }));
      dispatch(resetResponseState());
      if (successMsg && nextClicked) {
        navigate('/data-entry/land-crops/pesticides');
      }
      setNextClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  useEffect(() => {
    const firstVisibleCellDetails = getFirstVisibleCellDetailsInDataEntry(sectors);
    setFirstVisibleCellDetails(firstVisibleCellDetails);
  }, [sectors]);

  const handleModalResponse = (positiveResponse) => {
    setModalOpen(false);
    if (positiveResponse) {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      window.pageYOffset = 0;
      dispatch(getTransferredManureMetaData({ reportId }));
      dispatch(getTransferredManureFormData({ reportId }));
    }
  }

  useLayoutEffect(() => {
    dispatch(resetColumnTotal());
  }, [dispatch]);

  const isFormUpdated = () => {
    const transferredManureData = store.getState().transferredManure;
    const isUpdated = !isEqual(transferredManureData?.initialFormData, transferredManureData?.currentFormData);
    return isUpdated;
  }

  const handelSaveClick = (e) => {
    if(!isFormUpdated()){
      return;
    }
    dispatch(setLoading());
    dispatch(saveTransferredManureData({ reportId }));
  }

  const handleNextClick = (e) => {
    setNextClicked(true);
    if (props.locked || !isFormUpdated())
      navigate('/data-entry/land-crops/pesticides');
    else {
      dispatch(setLoading());
      dispatch(saveTransferredManureData({ reportId }));
    };
  }

  return (
    <ScrollSync>
      <Grid container spacing={2} className={props.locked && 'deactivated'}>
        <Grid item xs={12} md={12} lg={12}>
          <FormHeaderPaper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
            divider={false}
          >
            <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
            <SelectedReport reportName={reportName} />
            <FormTitleTypography variant="h5" component="div">
              Transferred Manure
            </FormTitleTypography>
            <FormDescriptionTypography variant="body2" paragraph>
              Allocate each of the manure and slurries by livestock species that are applied to crops and grass on the farm.  The columns should sum to 100%
            </FormDescriptionTypography>
          </FormHeaderPaper>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop:'0 !important' }}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', paddingTop:'0' }}>
            <List sx={{ mb: 2 }}>
              {colGroups ?
                <ListSubheader sx={{ bgcolor: "background.paper" }}>
                  <ScrollSyncPane>
                    <>
                    <Grid container direction="row" >
                    <Grid item xs={2}>
                      <BackButton />
                    </Grid>
                    <Grid item xs={10} sx={{ display:'flex', alignItems:'center' }}>
                      <Stack spacing={2} sx={{ marginLeft: 'auto', float: 'right' }} direction="row">
                        <Button
                          variant="outlined"
                          sx={{ textTransform: 'none' }}
                          onClick={() => setModalOpen(true)}>
                          Cancel
                        </Button>
                        <Button variant="outlined"
                          sx={{ textTransform: 'none' }}
                          onClick={handelSaveClick}>
                          Save
                        </Button>
                        <Button variant="contained"
                          sx={{ textTransform: 'none' }}
                          className="activated"
                          onClick={handleNextClick}>
                          Next
                        </Button>
                      </Stack>
                    </Grid>
                </Grid>
                  <CustomizedDivider/>                    
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                        <TableHead>
                          <TableRow>
                            <FormTableCell sx={{ width: CELL_WIDTH["RowTitle"] }}></FormTableCell>
                            {colGroups && colGroups.map((column) =>
                              <FormTableCell
                                align="center"
                                colSpan={column.length}
                                style={{ fontSize: '1.05rem' }}
                              >
                                {column.title}
                              </FormTableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <FormTableCell sx={{ width: CELL_WIDTH["RowTitle"] }}></FormTableCell>
                            {
                              columns.map((column) =>
                                <FormTableCell align="center" sx={{ width: '8%' }}>
                                  {column.title}
                                </FormTableCell>
                              )}
                          </TableRow>
                        </TableHead>
                      </Table>
                    </TableContainer>
                    </>
                  </ScrollSyncPane>
                </ListSubheader>
                : <Skeleton variant="rectangular" colSpan={columns.length} width={'100%'} height={115} />
              }
              {sectors.map((sector, index) => (
                <React.Fragment key={index}>
                  <TableListItem>
                    <SectorTitleTypography variant='body1'>{sector.title}</SectorTitleTypography>
                  </TableListItem>
                  <TableListItem>
                    <DataEntryTable
                      key={sector.id}
                      sectorId={sector.id}
                      sector={sector}
                      initialFormData={formData[sector.id] ? formData[sector.id] : {}}
                      firstVisibleCellDetails={firstVisibleCellDetails}
                      locked={props.locked}
                    />
                  </TableListItem>
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </ScrollSync>
  );
}

export default TransferredManure;
