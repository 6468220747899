import * as React from 'react';
import Typography from '@mui/material/Typography';
import { TableBody, TableCell, TableFooter, TableRow, Box } from '@mui/material';

const EmptyTableBody = ({ title }) => {
  return (
<TableRow sx={{ height: '100px' }}>
  <TableCell colSpan={18}>
    <Box sx={{ display: 'flex', alignItems: 'center',  justifyContent: 'center'}}>
      <Typography variant='body1' padding={5}>{`No ${title || 'records'} to display.`}</Typography>
    </Box>
  </TableCell>
</TableRow>
  );
};

export default EmptyTableBody;