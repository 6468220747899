import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import toastr from "toastr";
import { deleteAdminFarm, getFarmOwnersData, getOfficesData, resetResponseState, unsetLoading,setAdminFarmId,setFarmId } from '../../store/appAction';
import { Autocomplete, Box, Button, Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, ListSubheader, MenuItem, Select, Stack, TextField } from '@mui/material';
import CancelModal from '../Modal/CancelModal';
import DeleteModal from '../Modal/DeleteModal';
import { ADMIN, CONSULTANT, FARMER, SUPPER_ADMIN } from '../../constants/userRoles';
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../constants/toastrOptions';
import { EDIT } from '../../constants/modes';
import { matchSorter } from 'match-sorter';

const SCOTLAND = 'Scotland';

const FarmForm = props => {

    const {
        values: {
            id,
            farmName,
            holdingNumber,
            farmOwnerUserId,
            organisationId,
            officeId,
            addressLine1,
            addressLine2,
            townCity,
            countryId,
            zipPostCode,
            businessReferenceNumber,
            farmAdvisoryService,
            license,
            isUpgraded,
            initialFarmOwner
        },
        errors,
        touched,
        handleSubmit,
        handleBlur,
        handleChange,
        handleReset,
        setFieldValue,
        resetForm,
        role,
        mode,
        isMyFarm
    } = props;
    toastr.options = toastrCustomOptions;
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const [farmOwners, setFarmOwners] = useState([]);
    const [organisations, setOrganisations] = useState([]);
    const [offices, setOffices] = useState([]);

    const [farmOwner, setFarmOwner] = useState(null);
    const [organisation, setOrganisation] = useState(null);
    const [office, setOffice] = useState(null);
    const [labelForBRN, setLabelForBRN] = useState('Business Reference Number (BRN)');

    const countries = useSelector(state => state.common.countries);

    const currentFarmOwners = useSelector(state => state.common.farmOwners);
    const currentOrganisations = useSelector(state => state.common.organisations);
    const currentOffices = useSelector(state => state.common.offices);

    const { successMsg, errorMsg } = useSelector(state => state.common);

    const scotland = _.find(countries, ['name', SCOTLAND]);
    const filterOptionsForOrganization = (options,{ inputValue }) => matchSorter(options, inputValue, {keys: ['id', 'organizationName']});
    const filterOptionsForFarmOwner = (options,{ inputValue }) => matchSorter(options, inputValue, {keys: ['id', 'name']});
    const filterOptionsForOffice = (options,{ inputValue }) => matchSorter(options, inputValue, {keys: ['id', 'officeName']});
    useEffect(() => {
        organisationId && dispatch(getOfficesData({ organisationId }));
        dispatch(getFarmOwnersData({ farmId: id }));
        return () => {
            resetForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (successMsg || errorMsg) {
            dispatch(getFarmOwnersData({ farmId: id }));
            dispatch(unsetLoading());
            if (successMsg) {
                toastr.success(successMsg);
                if (mode !== EDIT) {
                    handleReset();
                    setFarmOwner(null);
                    setOrganisation(_.find(currentOrganisations, { id: organisationId }) || null);
                    setOffice(null);
                }
            }
            errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
            dispatch(resetResponseState());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMsg, errorMsg]);

    useEffect(() => {
        setFarmOwners(currentFarmOwners);
        (mode === EDIT) && (currentFarmOwners?.length > 0) &&
            farmOwnerUserId && setFarmOwner(_.find(currentFarmOwners, { id: farmOwnerUserId }) || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFarmOwners]);

    useEffect(() => {
        setOrganisations(currentOrganisations);
        (mode === EDIT) && (currentOrganisations?.length > 0) &&
            organisationId && setOrganisation(_.find(currentOrganisations, { id: organisationId }) || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrganisations]);

    useEffect(() => {
        setOffices(currentOffices);
        (mode === EDIT) && (currentOffices?.length > 0) &&
            officeId && setOffice(_.find(currentOffices, { id: officeId }) || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOffices]);

    useEffect(() => {
        setFarmOwner(_.find(currentFarmOwners, { id: farmOwnerUserId }) || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [farmOwnerUserId]);

    useEffect(() => {
        setOrganisation(_.find(currentOrganisations, { id: organisationId }) || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrganisations]);

    useEffect(() => {
        setOrganisation(_.find(currentOrganisations, { id: organisationId }) || null);
        if (organisationId)
            dispatch(getOfficesData({ organisationId }))
        else {
            setFieldValue("officeId", "");
            setOffices([]);
            setOffice(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisationId]);

    useEffect(() => {
        setOffice(_.find(currentOffices, { id: officeId }) || null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [officeId]);

    useEffect(() => {
        const selectedCountry = countries?.find( ({ id }) => id === countryId);
        switch(selectedCountry?.name){
            case 'England' : setLabelForBRN('Single Business Identifier (SBI)'); break;
            case 'Wales' : setLabelForBRN('Customer Reference Number (CRN)'); break;
            case 'N Ireland' : setLabelForBRN('Business IDentifier (BID)'); break;
            default : setLabelForBRN('Business Reference Number (BRN)'); break;
        }
    }, [countryId]);

    const handleCancelModalResponse = (positiveResponse) => {
        setModalOpen(false);
        if (positiveResponse) {
            window.pageYOffset = 0;
            handleReset();
            if (mode !== EDIT) {
                setFarmOwner(null);
                setOrganisation(null);
                setOffice(null);
            }
        }
    }

    const handleDeleteModalResponse = (positiveResponse) => {
        setDeleteModalOpen(false);
        if (positiveResponse) {
            window.pageYOffset = 0;
            handleReset();
            setFarmOwner(null);
            setOrganisation(null);
            setOffice(null);
            dispatch(deleteAdminFarm());
            dispatch(setAdminFarmId(null));
            dispatch(setFarmId(null));
            handelBackResponse();
        }
    }

    const handelBackResponse = () => {
        navigate('/admin/farm/list');
    }

    const fetchOptions = (data, nameKey) => {
        return (
            Object.entries(data || {}).map(([optionId, optionData]) => (
                <MenuItem
                    id={optionData.id}
                    key={optionData.id}
                    value={optionData.id}
                >
                    {optionData[nameKey]}
                </MenuItem>
            )));
    }

    return (
        <>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3, p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            autoFocus
                            id="farmName"
                            name="farmName"
                            label="Farm Name"
                            error={Boolean(touched.farmName ? errors.farmName : false)}
                            onChange={handleChange}
                            value={farmName}
                            helperText={touched.farmName ? errors.farmName : ""}
                            disabled={mode === EDIT && isUpgraded === false}
                        />
                    </Grid>
                    {!isMyFarm ? (!_.isEmpty(farmOwners) ?
                    <Grid item xs={12} sm={6}>
                        {![FARMER].includes(role) &&
                            <Autocomplete
                                id="farmOwner-autocomplete"
                                disablePortal
                                value={farmOwner}
                                options={farmOwners}
                                getOptionLabel={(owner) => `${owner?.name}`}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                filterOptions={ filterOptionsForFarmOwner }
                                disabled={mode === EDIT && isUpgraded === false}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.id}>
                                            {option.name}
                                        </li>
                                    );
                                }}
                                onChange={(e, value) => {
                                    setFieldValue("farmOwnerUserId", value?.id || "");
                                }}
                                onOpen={handleBlur}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        id="farmOwnerUserId"
                                        name="farmOwnerUserId"
                                        label="Farm Owner"
                                        error={Boolean(touched.farmOwnerUserId ? errors.farmOwnerUserId : false)}
                                        value={farmOwnerUserId}
                                        helperText={touched.farmOwnerUserId ? errors.farmOwnerUserId : ""}
                                    />
                                )}
                            />
                        }
                        {[FARMER].includes(role) &&
                            <FormControl fullWidth error={Boolean(touched.farmOwnerUserId ? errors.farmOwnerUserId : false)}>
                                <InputLabel id="farmOwnerUserId-select">Farm Owner</InputLabel>
                                <Select
                                    disabled={ isMyFarm || ( mode === EDIT && isUpgraded === false) }
                                    fullWidth
                                    name="farmOwnerUserId"
                                    label="farm Owner"
                                    id="farmOwnerUserId"
                                    labelId="farmOwnerUserId-select"
                                    onChange={handleChange}
                                    value={farmOwnerUserId}
                                >
                                    {fetchOptions(farmOwners, 'name')}
                                </Select>
                            </FormControl>
                        }
                    </Grid> :
                    <Grid item xs={12} sm={6} container alignItems="center">
                      <div style={{ paddingLeft:'1rem', color:'#309f34' }}>Please wait, loading farm owners...</div>
                    </Grid>
                    ) :
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            label="Farm Owner"
                            value={initialFarmOwner}
                        />
                    </Grid>
                    }
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="holdingNumber"
                            name="holdingNumber"
                            label="Holding Number"
                            onChange={handleChange}
                            error={Boolean(touched.holdingNumber ? errors.holdingNumber : false)}
                            value={holdingNumber}
                            helperText={touched.holdingNumber ? errors.holdingNumber : ""}
                            disabled={mode === EDIT && isUpgraded === false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="businessReferenceNumber"
                            name="businessReferenceNumber"
                            error={Boolean(touched.businessReferenceNumber ? errors.businessReferenceNumber : false)}
                            label={labelForBRN}
                            onChange={handleChange}
                            value={businessReferenceNumber}
                            helperText={touched.businessReferenceNumber ? `${labelForBRN} is required` : ""}
                            disabled={mode === EDIT && isUpgraded === false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {[ADMIN, SUPPER_ADMIN, CONSULTANT].includes(role) &&
                            <Autocomplete
                                id="organisation-autocomplete"
                                disablePortal
                                value={organisation}
                                options={organisations}
                                getOptionLabel={(org) => `${org?.organizationName}`}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                disabled={mode === EDIT && isUpgraded === false}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.id}>
                                            {option.organizationName}
                                        </li>
                                    );
                                }}
                                filterOptions={filterOptionsForOrganization}
                                onChange={(e, value) => {
                                    setFieldValue("organisationId", value?.id || "");
                                    setFieldValue("officeId", "");
                                    setOffices([]);
                                    setOffice(null);
                                }}
                                onOpen={handleBlur}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        id="organisationId"
                                        name="organisationId"
                                        label="Organisation"
                                        error={Boolean(touched.organisationId ? errors.organisationId : false)}
                                        value={organisationId}
                                        helperText={touched.organisationId ? errors.organisationId : ""}
                                    />
                                )}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {
                            [ADMIN, SUPPER_ADMIN, CONSULTANT].includes(role) &&
                            <Autocomplete
                                id="office-autocomplete"
                                disablePortal
                                value={office}
                                options={offices}
                                getOptionLabel={(office) => `${office?.officeName}`}
                                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                                filterOptions={filterOptionsForOffice}
                                disabled={mode === EDIT && isUpgraded === false}
                                renderOption={(props, option) => {
                                    return (
                                        <li {...props} key={option.id}>
                                            {option.officeName}
                                        </li>
                                    );
                                }}
                                onChange={(e, value) => {
                                    setFieldValue("officeId", value?.id || "");
                                }}
                                onOpen={handleBlur}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        fullWidth
                                        id="officeId"
                                        name="officeId"
                                        label="Office"
                                        error={Boolean(touched.officeId ? errors.officeId : false)}
                                        value={officeId}
                                        helperText={touched.officeId ? errors.officeId : ""}
                                    />
                                )}
                            />
                        }
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="addressLine1"
                            name="addressLine1"
                            error={Boolean(touched.addressLine1 ? errors.addressLine1 : false)}
                            label="Address 1"
                            onChange={handleChange}
                            value={addressLine1}
                            helperText={touched.addressLine1 ? errors.addressLine1 : ""}
                            disabled={mode === EDIT && isUpgraded === false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="addressLine2"
                            name="addressLine2"
                            error={Boolean(touched.addressLine2 ? errors.addressLine2 : false)}
                            label="Address 2"
                            onChange={handleChange}
                            value={addressLine2}
                            helperText={touched.addressLine2 ? errors.addressLine2 : ""}
                            disabled={mode === EDIT && isUpgraded === false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="townCity"
                            name="townCity"
                            error={Boolean(touched.townCity ? errors.townCity : false)}
                            label="Town/City"
                            onChange={handleChange}
                            value={townCity}
                            helperText={touched.townCity ? errors.townCity : ""}
                            disabled={mode === EDIT && isUpgraded === false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            fullWidth
                            error={Boolean(touched.countryId ? errors.countryId : false)}
                        >
                            <InputLabel id="countryId-select">Country</InputLabel>
                            <Select
                                fullWidth
                                name="countryId"
                                label="Country"
                                id="countryId"
                                labelId="countryId-select"
                                value={countryId}
                                onChange={handleChange}
                                disabled={mode === EDIT && isUpgraded === false}
                            >
                                <ListSubheader disableSticky={true} sx={{ pl: 2 }}>UK</ListSubheader>
                                {(countries ? countries : []).map((option) => (
                                    (option.countryCategory === 1) && <MenuItem
                                        id={option.id}
                                        key={option.id}
                                        value={option.id}
                                        sx={{ pl: 3 }}
                                    >
                                        - {option.name}
                                    </MenuItem>
                                ))}
                                <ListSubheader disableSticky={true} sx={{ pl: 2 }}>----</ListSubheader>
                                {(countries ? countries : []).map((option) => (
                                    (option.countryCategory !== 1) && <MenuItem
                                        id={option.id}
                                        key={option.id}
                                        value={option.id}
                                    >
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            {touched.countryId && <FormHelperText>{errors.countryId}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="zipPostCode"
                            name="zipPostCode"
                            error={Boolean(touched.zipPostCode ? errors.zipPostCode : false)}
                            label="Zip or Postcode"
                            onChange={handleChange}
                            value={zipPostCode}
                            helperText={touched.zipPostCode ? errors.zipPostCode : ""}
                            disabled={mode === EDIT && isUpgraded === false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        {[ADMIN, SUPPER_ADMIN, CONSULTANT].includes(role) &&
                            <FormControl fullWidth error={Boolean(touched.organizationId ? errors.organizationId : false)}>
                                <InputLabel id="license-select">Licence{/* UK English */}</InputLabel>
                                <Select
                                    disabled={[CONSULTANT].includes(role ) || (mode === EDIT && isUpgraded === false)}
                                    fullWidth
                                    name="license"
                                    label="Licence"/* UK English */
                                    id="license"
                                    labelId="license-select"
                                    onChange={handleChange}
                                    defaultValue={'Paid'}//Should replaced by subscription module.
                                    value={license}
                                    helperText={touched.license ? errors.license : ""}
                                >
                                    {/* Should replaced by subscription module. */}
                                    <MenuItem
                                        id={1}
                                        key={'Paid'}
                                        value={1}
                                    >
                                        {'Paid'}
                                    </MenuItem>
                                    <MenuItem
                                        id={0}
                                        key={'Free'}
                                        value={0}
                                    >
                                        {'Free'}
                                    </MenuItem>
                                    {/*fetchOptions(licenseData, 'license')*/}
                                </Select>
                            </FormControl>}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    </Grid>
                    
                    <Stack spacing={2} sx={{ marginLeft: 'auto', marginTop: 3 }} direction="row" >
                        <Button variant="outlined" sx={{ left: 'auto', textTransform: 'none' }}
                            onClick={handelBackResponse}>
                            Back
                        </Button>
                        <Button variant="outlined" sx={{ textTransform: 'none' }} disabled={mode === EDIT && isUpgraded === false}
                            onClick={(e) => {
                                e.stopPropagation();
                                setModalOpen(true);
                            }}>
                            Cancel
                        </Button>
                        {(mode === EDIT) && [ADMIN, SUPPER_ADMIN].includes(role) &&
                            <Button variant="outlined" sx={{ textTransform: 'none' }} disabled={mode === EDIT && isUpgraded === false}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setDeleteModalOpen(true);
                                }}>
                                Delete
                            </Button>}
                        <Button type="submit" variant="contained" sx={{ textTransform: 'none' }} disabled={mode === EDIT && isUpgraded === false}>
                            Save
                        </Button>
                    </Stack>
                </Grid>
                <CancelModal isOpen={modalOpen} handleResponse={handleCancelModalResponse} />
                <DeleteModal
                    isOpen={deleteModalOpen}
                    handleResponse={handleDeleteModalResponse}
                    value='If you delete you will lose all farm data. Are you sure you want to delete?' />
            </Box>
        </>
    )
}

export default FarmForm