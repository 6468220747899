import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import produce from "immer";
import {
  Grid, Paper, List, FormControl, MenuItem
} from "@mui/material";
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import {
  FormTitleTypography, FormRowGroupTableRow, FormTableRow,
  FormTableCell, SectorTableTitleTypography, FormHeaderPaper,
  DataEntryTextField, FormDescriptionTypography, CustomizedDivider, DataEntrySelect, NoMaxWidthTooltip
} from "../../../components/CustomStyles/StyledComponents";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import ListSubheader from "@mui/material/ListSubheader";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import _ from "lodash";
import { isEqual } from "lodash";
import toastr from "toastr";
import { toastrCustomOptions, toastrCustomOptionsLonger } from "../../../constants/toastrOptions";
import { addCommas, removeCommas } from "../../../helpers/stringFormatHelper";
import { getFirstVisibleCellDetailsInDataEntry } from '../../../helpers/dataEntryHelper';
import EmptyTableBody from '../../../components/Tables/EmptyTableBody';
import {
  resetResponseState, unsetLoading, setLoading,
  getArableRotationMetadata, resetArableRotationMetadata, 
  getArableRotationFormData, setArableRotationFormData, 
  saveArableRotationData, getArableRotationOptions
} from "../../../store/appAction";
import DataEntryButtonStack from "../../../components/Buttons/DataEntryButtonStack";
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import SelectedReport from '../../../components/SelectedReport/SelectedReport';
import store from '../../../store';

const DataEntryTable = ({ sector, arableRotationOptions, initialFormData, firstVisibleCellDetails, locked, index, formData }) => {

  toastr.options = toastrCustomOptions;
  const dispatch = useDispatch();
  const [sectorDataInt, setSectorDataInt] = useState({});
  const [sectorDataExt, setSectorDataExt] = useState({});

  useEffect(() => {
    setSectorDataInt(initialFormData);
    setSectorDataExt(initialFormData);
  }, [initialFormData]);

  useEffect(() => {
    if (sectorDataExt && !_.isEmpty(sectorDataExt)) {
      dispatch(setArableRotationFormData({
        sectorId: sector.id,
        formData: sectorDataExt
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorDataExt]);

  const handleOnBlur = (e, rule) => {
    e.preventDefault();
    let component = e.currentTarget;
    let value = removeCommas(e.target.value);
    if (e.target.inputMode === "numeric") {
      if (value && rule.min > parseFloat(value)) {
        toastr.warning(
          `This input only accepts values between ${addCommas(
            rule.min
          )} and ${addCommas(rule.max)}.  Please enter the value again.`,
          undefined,
          toastrCustomOptionsLonger
        );
        component.focus();
      }
    }
  };

  const handleInputChange = useCallback((
    e, groupId, rowId, cell
  ) => {
    let value = removeCommas(e.target.value);
    if (cell.dataType === 'TextField') {
      if (e.target.inputMode === "numeric") {
        let numericRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
        if (numericRegex.test(value)) {
          setSectorDataInt(produce((draft) => {
            draft[groupId][rowId][cell.mappingProperty] = value;
          }));
          setSectorDataExt(produce((draft) => {
            draft[groupId][rowId][cell.mappingProperty] = parseFloat(value);
          }));
        }
      }
    }

    if (cell.dataType === 'Select') {
      const value = e.target.value;
      setSectorDataInt(produce((draft) => {
        draft[groupId][rowId][cell.mappingProperty] = value === '' ? null : value;
      }));
      setSectorDataExt(produce((draft) => {
        draft[groupId][rowId][cell.mappingProperty] = value === '' ? null : value;
      }));
    }

  }, []);

  const getCellContent = ({ groupId, rowId, cell, rowData, firstVisibleCellDetails, locked }) => {

    const getDropdownMenuItems = (mappingProperty) => {
      let items;
      switch (mappingProperty) {
        case 'TillageBefore':
        case 'TillageNow':
          items = [{ id: 0, name: 'Please Select' }, ...arableRotationOptions.Tillage];
          break;
        case 'NFertiliserBefore':
        case 'NFertiliserNow':
          items = [{ id: 0, name: 'Please Select' }, ...arableRotationOptions.NitrogenFertiliserType];
          break;
        case 'ManureBefore':
        case 'ManureNow':
          items = [{ id: 0, name: 'Please Select' }, ...arableRotationOptions.ManureType];
          break;  
        case 'CResidueBefore':
        case 'CResidueNow':
          items = [{ id: 0, name: 'Please Select' }, ...arableRotationOptions.CropResidueType];
          break;  
        case 'CCropBefore':
        case 'CCropNow':
          items = [{ id: 0, name: 'Please Select' }, ...arableRotationOptions.CompanionCropType];
          break;
        case 'FallowCCropBefore':
        case 'FallowCCropNow':
          items = [{ id: 0, name: 'Please Select' }, ...arableRotationOptions.CoverCropType];
          break;
        default:
          return null;
      }

      return items.map((option) => (
        option.name === 'Please Select' ?
        <MenuItem key={option.id} value={option.id}><em>{option.name}</em></MenuItem> :
        <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
      ));
    };
    switch (cell.dataType) {
      case "TextField":
        return (
          <DataEntryTextField
            size="small"
            variant="outlined"
            inputProps={{ inputMode: "numeric" }}
            style={{ width: 100, textAlign: "center" }}
            value={(rowData && rowData[cell.mappingProperty]) || ''}
            onChange={(e) =>
              handleInputChange(e, groupId, rowId, cell)
            }
            onBlur={(e) => handleOnBlur(e, cell.validations)
            }
            disabled={cell?.readOnly}
          />
        );

      case 'Select':
        return (
          <FormControl sx={{ width: '100%', margin: 0 }}>
            <DataEntrySelect
              disabled={cell?.readOnly}
              value={rowData && rowData[cell.mappingProperty] !== null ?
                rowData[cell.mappingProperty] : 0}
              displayEmpty
              onChange={(e) => handleInputChange(e, groupId, rowId, cell)}
            >
              {getDropdownMenuItems(cell.mappingProperty)}
            </DataEntrySelect>
          </FormControl >
        );
      default:
        return <FormTableCell></FormTableCell>;
    }
  };




  return (
    <>
      {
        (sector.sectorGroups.groups || []).map((group) => (
          <>
            <FormTableRow > <FormTableCell colSpan={20}> <SectorTableTitleTypography style={{ margin: '0 !important', padding: '0 !important' }} variant='body1'>{sector.title} - {group.title}</SectorTableTitleTypography>  </FormTableCell>  </FormTableRow>
            {
              group.rows.map((row) => (
                <FormTableRow>
                  <FormTableCell >{row.title}</FormTableCell>
                  {row.cells.map((cell) => (
                    <FormTableCell sx={{ minWidth: cell.dataType === 'Select' ? '170px' : '' }}>
                      {sectorDataInt && cell.visible &&
                        getCellContent({
                          groupId: group.id,
                          rowId: row.id,
                          cell: cell,
                          rowData: sectorDataInt[group.id] && sectorDataInt[group.id][row.id],
                          isOtherSales: false,
                          firstVisibleCellDetails: firstVisibleCellDetails,
                          locked: locked
                        })}
                    </FormTableCell>
                  ))}
                </FormTableRow>
              ))
            }
          </>
        ))
      }
    </>
  );
};


const ArableRotation = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const reportId = useSelector(state => state.common.reportId);
  const metaData = useSelector((state) => state.arableRotation.metaData);
  const initialFormData = useSelector((state) => state.arableRotation.initialFormData);
  const arableRotationOptions = useSelector((state) => state.arableRotation.arableRotationOptions);


  const { successMsg, errorMsg } = useSelector(state => state.common);
  const farmId = useSelector(state => state.common.farmId);
  const adminFarm = useSelector(state => state.adminFarm);
  const {reportDetails: { reportName }} = useSelector(state => state.farmReport);

  const [formData, setFormData] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [firstVisibleCellDetails, setFirstVisibleCellDetails] = useState(null);

  const sectors = metaData?.form?.sectors || [];

  useEffect(() => {
    window.pageYOffset = 0;
    dispatch(setLoading());
    dispatch(getArableRotationOptions());
    dispatch(getArableRotationMetadata({ reportId }));
    dispatch(getArableRotationFormData({ reportId }));
    dispatch(resetResponseState());

    return () => {
      dispatch(resetArableRotationMetadata());
    }
  }, []);



  useEffect(() => {
    const tempData = { ...initialFormData };
    setFormData(tempData);
    setTimeout(() => {
      dispatch(unsetLoading());
    }, 4000);
  }, [initialFormData]);


  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(unsetLoading());
      successMsg && toastr.success(successMsg);
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(resetResponseState());
      if (successMsg && nextClicked) {
        navigate('/data-entry/soil-carbon/grassland');
      }
      setNextClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  useEffect(() => {
    const firstVisibleCellDetails = getFirstVisibleCellDetailsInDataEntry(sectors);
    setFirstVisibleCellDetails(firstVisibleCellDetails);
  }, [sectors]);

  const isFormUpdated = () => {
    const arableRotationData = store.getState().arableRotation;
    const isUpdated = !isEqual(arableRotationData?.initialFormData, arableRotationData?.currentFormData);
    return isUpdated;
  }

  const handelSaveClick = (e) => {
    if (!isFormUpdated()) {
      return;
    }
    dispatch(setLoading());
    dispatch(saveArableRotationData({ reportId }));
  }

  const handleNextClick = (e) => {
    setNextClicked(true);
    if (props.locked || !isFormUpdated()) {
      navigate('/data-entry/soil-carbon/grassland');
    }
    else {
      dispatch(setLoading());
      dispatch(saveArableRotationData({ reportId }));
    }
  }

  const handleModalResponse = (positiveResponse) => {
    setModalOpen(false);
    if (positiveResponse) {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      window.pageYOffset = 0;
      dispatch(getArableRotationMetadata({ reportId }));
      dispatch(getArableRotationFormData({ reportId }));
    }
  };

  const renderCropResidesTooltip = () => {
    const text = `
    <div>Low residue crops include most root and leafy vegetables,  </div>
    <div>processing peas and processing beans  </div>
    `;
    return <div dangerouslySetInnerHTML={{ __html: text }} style={{ padding: '5px' }} />
  };

  const renderManuareTooltip = () => {
    const text = `
    <div>Different types of manure contain </div>
    <div>different amounts of carbon. Cattle manure has the  </div>
    <div>highest carbon: nitrogen ratio, with other </div>
    <div>manures containing less. Your "now" </div>
    <div>classification has been estimated based </div>
    <div>on your current practices. Please use this  </div>
    <div> as a guide for your "before" value</div>
    `;
    return <div dangerouslySetInnerHTML={{ __html: text }} style={{ padding: '5px' }} />
  };

  const renderChangeYearTooltip = () => {
    const text = `
    <div>If multiple management changes have occurred in the past 20 years,  </div>
    <div>please enter the average year of the changes. For example,  </div>
    <div>if you changed grazing intensity in 2005 and changed your  </div>
    <div> nitrogen and liming practices in 2009,you would enter 2008 </div>
    `;
    return <div dangerouslySetInnerHTML={{ __html: text }} style={{ padding: '5px' }} />
  };

  return (
    <ScrollSync>
      <Grid container spacing={2} className={props.locked && 'deactivated'}>
        <Grid item xs={12} md={12} lg={12}>
          <FormHeaderPaper
            sx={{
              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
            divider={false}
          >
            <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName} />
            <SelectedReport reportName={reportName} />
            <FormTitleTypography variant="h5" component="div">
              {"Arable Rotation"}
            </FormTitleTypography>
            <FormDescriptionTypography variant="body2" paragraph>
              If you have changed the way you manage your arable land in the past 20 years then it is likely there is ongoing change to your soil carbon stocks. If you haven't changed your management in the past 20 years then this section can be skipped. Please complete this section after finishing the Land and Crops and Livestock sections
            </FormDescriptionTypography>
          </FormHeaderPaper>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop: '0 !important' }}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column", paddingTop: '0' }}>
            <List sx={{ mb: 2 }}>
              <ListSubheader sx={{ bgcolor: "background.paper" }}>
                <ScrollSync>
                  <>
                    <DataEntryButtonStack
                      modalOpen={modalOpen}
                      setModalOpen={setModalOpen}
                      handleModalResponse={handleModalResponse}
                      handelSaveClick={handelSaveClick}
                      handleNextClick={handleNextClick}
                    />
                    <CustomizedDivider />
                  </>
                </ScrollSync>
              </ListSubheader>
              { 
                <TableContainer component={Paper} sx={{ mb: 3 }}>
                  <Table
                    size="large"
                    aria-label="customized table"
                  >
                    <TableHead>
                      <FormTableRow >
                        <FormTableCell rowSpan={3} style={{ position: 'sticky' }}></FormTableCell>

                        <FormTableCell rowSpan={2} colSpan={2} > Tillage </FormTableCell>
                        <FormTableCell rowSpan={2} colSpan={2}> Nitrogen Fertiliser </FormTableCell>

                        <FormTableCell rowSpan={2} colSpan={2} align="center"> {"Manure"}
                          <NoMaxWidthTooltip title={renderManuareTooltip()}><InfoOutlinedIcon  color='primary' sx={{ marginLeft: '5px', verticalAlign: 'middle' , cursor: 'pointer'}} fontSize='small' /></NoMaxWidthTooltip>
                        </FormTableCell>

                        <FormTableCell rowSpan={2} colSpan={2} align="center"> {"Crop residues"}
                          <NoMaxWidthTooltip title={renderCropResidesTooltip()}><InfoOutlinedIcon  color='primary' sx={{ marginLeft: '5px', verticalAlign: 'middle' , cursor: 'pointer'}} fontSize='small' /></NoMaxWidthTooltip>
                        </FormTableCell>

                        <FormTableCell rowSpan={2} colSpan={2} > Perennials in rotation (%) </FormTableCell>
                        <FormTableCell rowSpan={2} colSpan={2} > Companion crop </FormTableCell>
                        <FormTableCell colSpan={4} > Fallow </FormTableCell>
                      </FormTableRow>

                      <FormTableRow>
                        <FormTableCell colSpan={2}> (%) In Rotation  </FormTableCell>
                        <FormTableCell colSpan={2}> Cover crop </FormTableCell>
                      </FormTableRow>

                      <FormTableRow>

                        <FormTableCell > Before </FormTableCell>
                        <FormTableCell > Now </FormTableCell>
                        <FormTableCell > Before </FormTableCell>
                        <FormTableCell > Now </FormTableCell>
                        <FormTableCell > Before </FormTableCell>
                        <FormTableCell > Now </FormTableCell>
                        <FormTableCell > Before </FormTableCell>
                        <FormTableCell > Now </FormTableCell>
                        <FormTableCell > Before </FormTableCell>
                        <FormTableCell > Now </FormTableCell>
                        <FormTableCell > Before </FormTableCell>
                        <FormTableCell > Now </FormTableCell>
                        <FormTableCell > Before </FormTableCell>
                        <FormTableCell > Now </FormTableCell>
                        <FormTableCell > Before </FormTableCell>
                        <FormTableCell > Now </FormTableCell>
                      </FormTableRow>
                    </TableHead>

                    <TableBody>
                      { _.isEmpty(formData) &&  <EmptyTableBody title="data"/>  }
                      {initialFormData && formData && firstVisibleCellDetails && sectors.map((sector, index) => (
                        <>
                          <React.Fragment key={index}>
                              <DataEntryTable
                              key={sector.id}
                              sector={sector}
                              arableRotationOptions={arableRotationOptions}
                              initialFormData={formData[sector.id] ? formData[sector.id] : {}}
                              firstVisibleCellDetails={firstVisibleCellDetails}
                              locked={props.locked}
                              index={index}
                              
                            />
                          </React.Fragment>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              }
            </List>
          </Paper>
        </Grid>
      </Grid>
    </ScrollSync>
  );
};

export default ArableRotation;
