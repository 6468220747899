
export const GET_ARABLE_ROTATION_METADATA = 'GET_ARABLE_ROTATION_METADATA'
export const GET_ARABLE_ROTATION_METADATA_SUCCESS = 'GET_ARABLE_ROTATION_METADATA_SUCCESS'
export const GET_ARABLE_ROTATION_METADATA_FAIL = 'GET_ARABLE_ROTATION_METADATA_FAIL'
export const RESET_ARABLE_ROTATION_METADATA = 'RESET_ARABLE_ROTATION_METADATA'

export const GET_ARABLE_ROTATION_FORM_DATA_SUCCESS = 'GET_ARABLE_ROTATION_FORM_DATA_SUCCESS'
export const GET_ARABLE_ROTATION_FORM_DATA_FAIL = 'GET_ARABLE_ROTATION_FORM_DATA_FAIL'
export const SET_ARABLE_ROTATION_FORM_DATA = 'SET_ARABLE_ROTATION_FORM_DATA'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'

export const GET_ARABLE_ROTATION_OPTIONS_SUCCESS = 'GET_ARABLE_ROTATION_OPTIONS_SUCCESS'
export const GET_ARABLE_ROTATION_OPTIONS_FAIL = 'GET_ARABLE_ROTATION_OPTIONS_FAIL'