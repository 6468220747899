export const WHOLE_FARM_DETAILED_TABLES = {
    directCarbonDioxideResults: {
        tableTitle: 'Direct Carbon Dioxide',
        columns: ['Diesel', 'Electricity', 'Other fuels', 'Renewable heat and electricity', 'Total direct carbon dioxide']
    },
    indirectCarbonDioxideResults: {
        tableTitle: 'Indirect Carbon Dioxide (Farm inputs)',
        columns: ['Fertiliser', 'Lime', 'Pesticides', 'Bedding', 'Feed', 'Energy', 'Plastic use / disposal', 'Carcass disposal', 'Transport', 'Total indirect carbon dioxide (other)']
    },
    methaneResults: {
        tableTitle: 'Methane',
        columns: ['Feed digestion (GWP100)','Manure management (GWP100) ', 'Total methane (GWP100)', 'Total methane (GWP*) <sup>†</sup>']
    },
    nitrousOxideResults: {
        tableTitle: 'Nitrous Oxide',
        columns: ['Imported fertilisers and manures', 'Grazing, manure spreading, and manure management', 'Crop residues', 'Total nitrous oxide']
    },
    carbonSequestrationResults: {
        tableTitle: 'Carbon Stock Changes',
        tableTitleTooltip: 'Estimates of changes in carbon stocks have been made using Tier 1 models. This is recognised under the GHG protocol as a valid approach to estimate emissions from carbon stocks but can only be used as an indication of sequestration potential. Therefore, any sequestration shown in this table is not included in your total carbon footprint',
        columns: ['Soil Carbon', 'Biochar', 'Estimated Biochar Sequestration*', 'Hedges', 'Forestry']
    },
    totalEmissionsResults: {
        tableTitle: 'Total Emissions',
        columns: ['Gross emissions from farming', "Farm Area"]
    },
    productEmissionResults: {
        tableTitle: 'Product Emission Table',
        columns: ['Enterprise', 'Product (unit)', 'Total output', 'Total emissions (kg CO₂-eq)', 'Emissions per unit (kg CO₂-eq)'],
        noFooter: true,
    }
}

export const SUMMARY_TABLE = {
    emissionSummaryResults: {
        tableTitle: 'Emission Summary Table',
        columns: ['Direct CO₂', 'Indirect CO₂', 'Methane (CO₂-eq)', 'Nitrous oxide (CO₂-eq)',  'Carbon stock emissions', 'Gross Emissions (kg CO₂-eq)']
    },
    productEmissionResults: {
        tableTitle: 'Product Emission Table',
        columns: ['Enterprise', 'Product (unit)', 'Total output', 'Total emissions (kg CO₂-eq)', 'Emissions per unit (kg CO₂-eq)'],
        noFooter: true,
    }

}

export const ENTERPRISE_DETAILED_TABLES = [
    {
        title: 'Quick glance emissions',
        key: 'quickGlanceEmissions',
        columns: [
            {
                title: "Enterprise Source",
                key: "title",
            },
            // {
            //     title: "Source Percentage",
            //     key: "sourcePercentage",
            // },
            {
                title: "kg CO₂ -eq / kg product",
                key: "emission",
                isDynamic: true
            },
            {
                title: "Compared To",
                key: "comparisonValue",
            },
            {
                title: "Opportunity Level",
                key: "opportunityLevel",
            },]
    },
    {
        title: 'Physical performance',
        key: 'physicalPerformance',
        columns: [
            {
                title: "Performance Indicator",
                key: "title",
            },
            {
                title: "Value",
                key: "value",
            },
            {
                title: "Compared To",
                key: "comparisonValue",
            },
            // {
            //     title: "Opportunity Level",
            //     key: "opportunityLevel",
            // },
        ]
    }
]

export const FARMER_FRIENDLY_TABLES = [
    {
        title: 'Physical performance',
        key: 'physicalPerformance',
        noFooter: true,
        columns: [
            {
                title: "Performance Indicator",
                key: "title",
            },
            {
                title: "Value",
                key: "value",
            },
            {
                title: "Compared To",
                key: "comparisonValue",
            },
            // {
            //     title: "Opportunity Level",
            //     key: "opportunityLevel",
            // },
        ]
    },
    {
        title: 'Enterprise Emissions Summary',
        key: 'quickGlanceEmissions',
        columns: [
            {
                title: "Source",
                key: "title",
            },
            {
                title: "Your emissions (kg CO₂ -eq / kg dwt)",
                key: "emission",
            },
            {
                title: "Opportunity for improvement",
                key: "opportunityLevel",
            },
            {
                title: "Comparison",
                key: "comparisonValue",
            },

        ]
    },

]