
export const MAPPING_PROPS_NUMERIC_4X = [
    'StartWeight',
    'EndWeight',
    'AverageWeightOfBoughtAnimals',
    'AverageWeightOfSoldAnimals',
    'DailyLiveWeightGain'
];

export const MAPPING_PROPS_SOIL_CARBON = [
    'FractionOfLandConvertedInLast20Years',
    'LandUseBeforeConvertion',
    'GrazingSystem'
];

export const REPORT_STATUS = [
    'Incomplete'/*0 'ReportInitiated'*/,
    'Incomplete'/*1 'DataChecksErrors'*/,
    'Incomplete'/*2 'DataChecksPassed'*/,
    'Incomplete'/*3 'CalculationInProgress'*/,
    'Incomplete'/*4 'CalculationCompleted'*/,
    'Under Validation' /*5 'ReviewRequested'*/,
    'Under Validation'/*6 'ReviewInProgress'*/,
    'Validated'/*7 'ReviewCompletedAndValid '*/,
    'Valid (not in benchmarks)'/*8 'ReviewCompletedAndInvalid '*/,
    'Incomplete'/*9 CalculationErrorOccured*/,
]
export const REPORT_STATUS_OPTIONS = [
    { key: 'Incomplete', value: [0, 1, 2, 3, 4, 9] },
    { key: 'Under Validation', value: [5, 6] },
    { key: 'Validated', value: [7] },
    { key: 'Valid (not in benchmarks)', value: [8] },
]

export const LICENSE_OPTIONS = [
    { key: 'Free', value: 0 },
    { key: 'Paid', value: 1 },
]

// export const REPORT_STATUS = [
//     'ReportInitiated',
//     'DataChecksErrors',
//     'DataChecksPassed',
//     'CalculationInProgress',
//     'CalculationCompleted',
//     'ReviewRequested',
//     'ReviewInProgress',
//     'ReviewCompleted'
// ]

