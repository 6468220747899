export const ResultStatus = Object.freeze({
    NotCalculated : 0,
    Completed : 1,
    Error : 2
});

export const ReportStatus = Object.freeze({
    ReportInitiated : 0,
    DataChecksErrors : 1,
    DataChecksPassed : 2,
    CalculationInProgress : 3,
    CalculationCompleted : 4,
    ReviewRequested : 5,
    ReviewInProgress : 6,
    ReviewCompletedAndValid : 7,
    ReviewCompletedAndInvalid : 8,
    CalculationErrorOccured : 9,
    isCalculated : (status) => { return (status===ReportStatus.CalculationCompleted)},
    isPreCalculation : (status) => { return (status < ReportStatus.CalculationCompleted)},
    isUnderReview :(status) => { return ([ ReportStatus.ReviewRequested, ReportStatus.ReviewInProgress].includes(status))},
    canSubmit: (status) => { return ([ ReportStatus.CalculationCompleted, ReportStatus.DataChecksErrors].includes(status))}
});


export const showNonValidatedReportText = (status) => {
    return (   status !== ReportStatus.ReviewCompletedAndValid 
            && status !== ReportStatus.ReviewCompletedAndInvalid);
}
