import { Button, Grid, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toastr from "toastr";
import { InfoTooltip, ListTableCell, ListTableRow } from '../../../components/CustomStyles/StyledComponents';
import { deleteOrganisation, getAdminOrganisations, resetResponseState, setCurrentAdminOrganisation, setLoading, unsetLoading } from '../../../store/appAction';
import DeleteIcon from '@mui/icons-material/Delete';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DeleteModal from '../../../components/Modal/DeleteModal';
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../../constants/toastrOptions';
import EmptyTableBody from '../../../components/Tables/EmptyTableBody';
import { endpoints } from '../../../api/endpoints';
import * as api from '../../../api/base';
import ConfirmModal from '../../../components/Modal/ConfirmModal';


const Organisations = () => {
    toastr.options = toastrCustomOptions;
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const { successMsg, errorMsg } = useSelector(state => state.common);
    const organisationList = useSelector(state => state.adminOrganisation.organisations);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const selectedOrganization = useSelector(state => state.adminOrganisation.selectedOrganisation);
    const [modalOneOpen, setModalOneOpen] = useState(false);
    const [modalTwoOpen, setModalTwoOpen] = useState(false);
    const [modalThreeOpen, setModalThreeOpen] = useState(false);
    const [modalFourOpen, setModalFourOpen] = useState(false);
    const [modalFiveOpen, setModalFiveOpen] = useState(false);
    const [modalSixOpen, setModalSixOpen] = useState(false);
    const [modalSevenOpen, setModalSevenOpen] = useState(false);
    const [modalEightOpen, setModalEightOpen] = useState(false);

    useEffect(() => {
        dispatch(setLoading());
        dispatch(getAdminOrganisations());
        return () => {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
       organisationList && setTimeout(() => {
            dispatch(unsetLoading());
        }, 200);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisationList]);
    
    useEffect(() => {
        if (successMsg || errorMsg) {
            dispatch(unsetLoading());
            if (successMsg) {
                toastr.success(successMsg);
                dispatch(getAdminOrganisations());
                dispatch(setCurrentAdminOrganisation({}));

            }
            errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
            dispatch(resetResponseState());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMsg, errorMsg]);

    const handleOrganisationCreate = () => {
        dispatch(setCurrentAdminOrganisation({}));
        navigate('/admin/organisations/create')
    }

    const handleOrganisationEditDetails = (e, organisation) => {
        dispatch(setCurrentAdminOrganisation(organisation));
        navigate('/admin/organisations/edit')
    }

    const handelOrganisationOffices = (e, organisation) => {
        dispatch(setCurrentAdminOrganisation(organisation));
        navigate('/admin/offices/list')
    }

    const handleDelete = async (e, organisation) => {
        dispatch(setLoading());
        const response = await api.get(endpoints.admin.organizations.organizationUsers.replace("ORGANIZATION_ID", organisation.id));
        if (response && response.status === 200) {
            if (response.data?.hasAnyUsers) {
                setModalOneOpen(true);
            } else {
                const response = await api.get(endpoints.admin.organizations.organizationFarms.replace("ORGANIZATION_ID", organisation.id));
                if(response && response.status === 200){
                    if(response.data?.hasAnyFarms){
                        dispatch(setCurrentAdminOrganisation(organisation));
                        setModalThreeOpen(true)
                    }else{
                        const response = await api.get(endpoints.admin.organizations.organizationOffices.replace("ORGANIZATION_ID", organisation.id));
                        if(response && response.status === 200){
                            if(response.data?.hasAnyOffices){
                                dispatch(setCurrentAdminOrganisation(organisation));
                                setModalEightOpen(true);
                            }else{
                                dispatch(setCurrentAdminOrganisation(organisation));
                                setModalSevenOpen(true);
                            }
                        }else{
                            dispatch(unsetLoading());
                            toastr.error(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page.", undefined, toastrCustomOptionsLonger);      
                        }
                    }
                }else{
                    toastr.error(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page.", undefined, toastrCustomOptionsLonger);
                }
                dispatch(unsetLoading());
            }
        } else {
            toastr.error(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page.", undefined, toastrCustomOptionsLonger);
        }
        dispatch(unsetLoading());
    }

    const handleModalTwoResponse = async (positiveResponse) => {
        setModalTwoOpen(false);
        if (positiveResponse) {
            dispatch(setLoading());
            dispatch(deleteOrganisation());
        }else{
            dispatch(unsetLoading());
        }
    }

    const handleModalThreeResponse = async (positiveResponse) => {
        if (positiveResponse) {
            setModalThreeOpen(false);
            dispatch(setLoading());
            const response = await api.get(endpoints.admin.organizations.organizationOffices.replace("ORGANIZATION_ID", selectedOrganization.id));
            if(response && response.status === 200){
                if(response.data?.hasAnyOffices){
                    setModalFiveOpen(true);
                }else{
                    dispatch(setCurrentAdminOrganisation(selectedOrganization));
                    setModalFourOpen(true);
                }
            }else{
                dispatch(unsetLoading());
                toastr.error(response?.data?.error?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page.", undefined, toastrCustomOptionsLonger);      
            }
        }else{
            setModalThreeOpen(false);
            dispatch(unsetLoading());
        }
    }

    const handleModalFourResponse = async (positiveResponse) => {
        if (positiveResponse) {
            setModalFourOpen(false);
            dispatch(setLoading());
            dispatch(deleteOrganisation());
        }else{
            setModalFourOpen(false);
            dispatch(unsetLoading());
        }
    }

    const handleModalFiveResponse = async (positiveResponse) => {
        if (positiveResponse) {
            setModalFiveOpen(false);
            setModalSixOpen(true);
        }else{
            setModalFiveOpen(false);
            dispatch(unsetLoading());
        }
    }

    const handleModalSixResponse = async (positiveResponse) => {
        if (positiveResponse) {
            setModalSixOpen(false);
            dispatch(setLoading());
            dispatch(deleteOrganisation());
        }else{
            setModalSixOpen(false);
            dispatch(unsetLoading());
        }
    }

    const handleModalSevenResponse = async (positiveResponse) => {
        if (positiveResponse) {
            setModalSevenOpen(false);
            dispatch(setLoading());
            dispatch(deleteOrganisation());
        }else{
            setModalSevenOpen(false);
            dispatch(unsetLoading());
        }
    }

    const handleModalEightResponse = async (positiveResponse) => {
        if (positiveResponse) {
            setModalEightOpen(false);
            dispatch(setLoading());
            dispatch(deleteOrganisation());
        }else{
            setModalEightOpen(false);
            dispatch(unsetLoading());
        }
    }

    const handleChangePage = (e, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (e) => {
        setRowsPerPage(parseInt(e.target.value, 10));
        setPage(0);
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={9}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                ></Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
                <Paper
                    sx={{
                        p: 2,
                        flexDirection: 'column',
                        textAlign: 'center'
                    }}>
                    <Button variant="contained"
                        sx={{ textTransform: 'none' }}
                        onClick={handleOrganisationCreate}>
                        Create
                    </Button>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', width: '50%' }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ width: '95%' }} size="small" aria-label="customized table">
                            <TableHead>
                                <ListTableCell sx={{ textAlign: 'left', width: '10%' }}>Logo</ListTableCell>
                                <ListTableCell sx={{ textAlign: 'left', width: '80%' }}>Organisation</ListTableCell>
                                <ListTableCell sx={{ textAlign: 'left', width: '20%' }}>Action</ListTableCell>
                            </TableHead>
                            {organisationList?.length > 0 ?
                                <TableBody>
                                    {organisationList?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                        <ListTableRow key={index}>
                                            <ListTableCell>
                                            {row.organizationLogoUrl?
                                            <Box component="img" sx={{
                                                    height: '100%'
                                                }}
                                                alt="Org Logo"
                                                src={row.organizationLogoUrl}/>:''}
                                            </ListTableCell>
                                            <ListTableCell
                                                sx={{ cursor: 'pointer' }}
                                                onClick={(e) => handleOrganisationEditDetails(e, row)}>
                                                {row.organizationName}
                                            </ListTableCell>
                                            <ListTableCell>
                                                <InfoTooltip title="Delete Organisation">
                                                    <DeleteIcon
                                                        id='delete'
                                                        sx={{ cursor: 'pointer', mr: 1 }}
                                                        onClick={(e) => handleDelete(e, row)}
                                                    />
                                                </InfoTooltip>
                                                <InfoTooltip title="Display Offices">
                                                    <ApartmentIcon
                                                        id='office'
                                                        sx={{ cursor: 'pointer' }}
                                                        onClick={(e) => handelOrganisationOffices(e, row)}
                                                    />
                                                </InfoTooltip>
                                            </ListTableCell>
                                        </ListTableRow>
                                    ))}
                                </TableBody> :
                                <EmptyTableBody />
                            }
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={organisationList?.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                <ConfirmModal
                    isOpen={modalOneOpen}
                    value='You cannot delete an organisation that has users. Please delete the users from this organisation first.'
                    handleResponse={(value)=>{
                        setModalOneOpen(false);
                        dispatch(unsetLoading());
                    }} 
                />
                <DeleteModal
                    isOpen={modalTwoOpen}
                    handleResponse={handleModalTwoResponse}
                    value={'Are you sure you wish to delete this organisation?'}
                />
                <DeleteModal
                    isOpen={modalThreeOpen}
                    value='All farms assigned to this organisation will no longer have an organisation link. Do you wish to continue?'
                    handleResponse={(value)=>{
                        handleModalThreeResponse(value);
                    }} 
                />
                <DeleteModal
                    isOpen={modalFourOpen}
                    handleResponse={handleModalFourResponse}
                    value={'Are you sure you wish to delete this organisation?'}
                />
                <DeleteModal
                    isOpen={modalFiveOpen}
                    handleResponse={handleModalFiveResponse}
                    value={'All offices in the organisation will be deleted. Do you wish to continue?'}
                />
                <DeleteModal
                    isOpen={modalSixOpen}
                    handleResponse={handleModalSixResponse}
                    value={'Are you sure you wish to delete this organisation?'}
                />
                <DeleteModal
                    isOpen={modalSevenOpen}
                    handleResponse={handleModalSevenResponse}
                    value={'Are you sure you wish to delete this organisation?'}
                />
                <DeleteModal
                    isOpen={modalEightOpen}
                    handleResponse={handleModalEightResponse}
                    value={'All offices in the organisation will be deleted. Do you wish to continue?'}
                />
                </Paper>
            </Grid>
        </Grid >

    )
}

export default Organisations