import { Button, Grid, MenuItem, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, } from "@mui/material";
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import toastr from "toastr";
import { InfoTooltip, ListTableCell, ListTableRow, SearchTableCell } from "../../../components/CustomStyles/StyledComponents";
import { deleteAdminFarm, getAdminFarm, getAllFarms, resetResponseState, setAdminFarmId, setFarmId, setLoading, unsetLoading, resetFarmReportDetails } from "../../../store/appAction";
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CopyReportModal from "../../../components/Modal/CopyReportModal";
import DeleteModal from "../../../components/Modal/DeleteModal";
import { toastrCustomOptions, toastrCustomOptionsLonger,toastrCustomOptionsNoFarmSelectPopUp } from "../../../constants/toastrOptions";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import EmptyTableBody from "../../../components/Tables/EmptyTableBody";
import _, { debounce } from "lodash";
import SortButton from "../../../components/Buttons/SortButton";
import TableSearchText from "../../../components/Inputs/TableSearchText";
import TableSearchDatePicker from "../../../components/Inputs/TableSearchDatePicker";
import TableSearchSelect from "../../../components/Inputs/TableSearchSelect";
import { LICENSE_OPTIONS } from "../../../constants/arrays";
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import { endpoints } from '../../../api/endpoints';
import { SUPPER_ADMIN, ADMIN } from '../../../constants/userRoles';
import * as api from '../../../api/base';

const SORTING = 'friendlyFarmId';
const SORT_ORDER = 'asc';
const PAGE = 0;
const ROWS_PER_PAGE = 10;

const Farms = () => {
    dayjs.extend(utc);
    toastr.options = toastrCustomOptions;
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const { successMsg, errorMsg } = useSelector(state => state.common);
    const farmDetails = useSelector(state => state.adminFarm.farmDetails);
    const farmList = useSelector(state => state.adminFarm.farms);
    const user = useSelector(state => state.auth.user);

    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [copyModalOpen, setCopyModalOpen] = useState(false);

    const farmId = useSelector(state => state.common.farmId);
    const adminFarm = useSelector(state => state.adminFarm);
    const permissions = useSelector(state => state.auth.permissions);

    const [searchQuery, setSearchQuery] = useState({
        sorting: SORTING,
        sortOrder: SORT_ORDER,
        skipCount: PAGE,
        maxResultCount: ROWS_PER_PAGE
    });
    const [pagination, setPagination] = useState({
        page: PAGE,
        rowsPerPage: ROWS_PER_PAGE
    });
    const [skip, setSkip] = useState(false);

    const menuOpen = Boolean(anchorEl);

    useEffect(() => {
        dispatch(setLoading());
        return () => {
            dispatch(resetFarmReportDetails());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        farmList && setTimeout(() => {
            setSkip(false);
            dispatch(unsetLoading());
        }, 200);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [farmList]);

    useEffect(() => {
        const { page, rowsPerPage } = pagination;

        !skip && farmList?.items && setSearchQuery(prevState => ({
            ...prevState,
            skipCount: page * rowsPerPage,
            maxResultCount: rowsPerPage
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination]);

    useEffect(() => {
        dispatch(getAllFarms(searchQuery));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);


    useEffect(() => {
        if (successMsg || errorMsg) {
            dispatch(unsetLoading());
            if (successMsg) {
                toastr.success(successMsg);
                dispatch(getAllFarms(searchQuery));
            }
            errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
            dispatch(resetResponseState());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMsg, errorMsg]);

    const handleChangePage = (event, newPage) => {
        setPagination(prevState => ({
            ...prevState,
            page: newPage
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setPagination(prevState => ({
            ...prevState,
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10)
        }));
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleEditDetails = (e, farmId) => {
        handleMenuClose();
        dispatch(setAdminFarmId(farmId));
        dispatch(setFarmId(farmId));
        navigate('/admin/farm/edit')
    }

    const handleReportList = (event, farmId) => {
        setAnchorEl(null);
        dispatch(setFarmId(farmId));
        dispatch(setAdminFarmId(farmId));
        farmId && dispatch(getAdminFarm(farmId));
        navigate('/farm-reports/list')
    }

    const handleDelete = async (event, farmId) => {
        setAnchorEl(null);
        dispatch(setAdminFarmId(farmId));
        const response = await api.get(endpoints.admin.farm.farm.concat('?farmId=', farmId));
        if (response && response.status === 200) {
            if(response?.data?.isUpgraded === false){
                toastr.warning("This farm cannot be updated as it belongs to a user that has not been approved for Agrecalc cloud.", undefined, toastrCustomOptionsNoFarmSelectPopUp);
            }else{
                setDeleteModalOpen(true); 
            }
        }
    }

    const handleCopyModalResponse = (positiveResponse) => {
        setCopyModalOpen(false);
    }

    const handleDeleteModalResponse = (positiveResponse) => {
        setDeleteModalOpen(false);
        if (positiveResponse) {
            dispatch(setLoading());
            dispatch(deleteAdminFarm());
        }
    }

    const setSorting = ({ sortOrder, sorting }) => {
        setSearchQuery(prevState => ({
            ...prevState,
            sortOrder, sorting
        }));
    }

    const buildSearchQuery = (key, value) => {
        setSkip(true);
        setPagination({
            page: 0,
            rowsPerPage: 10
        });
        setSearchQuery(prevState => ({
            ...prevState,
            [key]: value === undefined || value === '' ? undefined : value,
            skipCount: PAGE,
            maxResultCount: ROWS_PER_PAGE
        }));
        // dispatch(setLoading());
    };

    const debouncedBuildSearchQuery = useMemo(() => {
        return debounce(buildSearchQuery, 500);
    }, []);

    // useEffect(() => {
    //     return () => {
    //         debouncedBuildSearchQuery.cancel();
    //     };
    // });

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={9}>
            <Paper
                    style={{ paddingTop:'0px' }}
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
                <Paper
                style={{ paddingTop:'0px' }}
                    sx={{
                        p: 2,
                        flexDirection: 'column',
                        textAlign: 'center'
                    }}
                >
                    {
                    Object.values(permissions ?? {}).some(value => ['AgreCalc.Farms', 'AgreCalc.Farms.Create'].includes(value)) && 
                    <Button variant="contained"
                        sx={{ textTransform: 'none' }}
                        onClick={() => navigate('/admin/farm/create')}>
                        Create
                    </Button>
                    }
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ width: '100%' }} size="small" aria-label="customized table">
                            <TableHead>
                                <ListTableCell sx={{ textAlign: 'left', width: '8%' }}>ID
                                    <SortButton
                                        sortKey={'friendlyFarmId'}
                                        currentSortKey={searchQuery?.sorting}
                                        handleSorting={setSorting}
                                    />
                                </ListTableCell>
                                <ListTableCell sx={{ textAlign: 'left', width: '8%' }}>Farm
                                    <SortButton
                                        sortKey={'farmName'}
                                        currentSortKey={searchQuery?.sorting}
                                        handleSorting={setSorting}
                                    /></ListTableCell>
                                <ListTableCell sx={{ textAlign: 'left', width: '8%' }}>Holding
                                    <SortButton
                                        sortKey={'holdingNumber'}
                                        currentSortKey={searchQuery?.sorting}
                                        handleSorting={setSorting}
                                    /></ListTableCell>
                                <ListTableCell sx={{ textAlign: 'left', width: '8%' }}>BRN
                                    <SortButton
                                        sortKey={'businessReferenceNumber'}
                                        currentSortKey={searchQuery?.sorting}
                                        handleSorting={setSorting}
                                    /></ListTableCell>
                                <ListTableCell sx={{ textAlign: 'left', width: '8%' }}>Owner
                                    <SortButton
                                        sortKey={'farmOwner'}
                                        currentSortKey={searchQuery?.sorting}
                                        handleSorting={setSorting}
                                    /></ListTableCell>
                                <ListTableCell sx={{ textAlign: 'left', width: '10%' }}>Organisation
                                    <SortButton
                                        sortKey={'organisation'}
                                        currentSortKey={searchQuery?.sorting}
                                        handleSorting={setSorting}
                                    /></ListTableCell>
                                <ListTableCell sx={{ textAlign: 'left', width: '8%' }}>Office
                                    <SortButton
                                        sortKey={'office'}
                                        currentSortKey={searchQuery?.sorting}
                                        handleSorting={setSorting}
                                    /></ListTableCell>
                                <ListTableCell sx={{ textAlign: 'left', width: '10%' }}>Created On
                                    <SortButton
                                        sortKey={'creationTime'}
                                        currentSortKey={searchQuery?.sorting}
                                        handleSorting={setSorting}
                                    /></ListTableCell>
                                
                                { (user?.role?.includes(SUPPER_ADMIN) || user?.role === ADMIN) && <ListTableCell sx={{ textAlign: 'left', width: '8%' }}>Licence {/* UK English */}
                                    <SortButton
                                        sortKey={'license'}
                                        currentSortKey={searchQuery?.sorting}
                                        handleSorting={setSorting}
                                    />
                                </ListTableCell>}
                                { (user?.role?.includes(SUPPER_ADMIN) || user?.role === ADMIN) && <ListTableCell sx={{ textAlign: 'left', width: '8%' }}>Licence Start Date</ListTableCell>}
                                { (user?.role?.includes(SUPPER_ADMIN) || user?.role === ADMIN) &&<ListTableCell sx={{ textAlign: 'left', width: '8%' }}>Licence End Date</ListTableCell>}
                                <ListTableCell sx={{ textAlign: 'left', width: '8%' }}>Action</ListTableCell>
                            </TableHead>
                            <TableBody>
                                <ListTableRow>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="friendlyFarmId"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                            autoFocus={true}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="farmName"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="holdingNumber"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="businessReferenceNumber"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="farmOwner"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="organisation"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="office"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchDatePicker
                                            searchKey="creationTime"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchSelect
                                            searchKey="license"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        >
                                            {LICENSE_OPTIONS.map((option) => (
                                                <MenuItem key={option.key} value={option.value}>{option.key}</MenuItem>
                                            ))}
                                        </TableSearchSelect>
                                    </SearchTableCell>
                                    { (user?.role?.includes(SUPPER_ADMIN) || user?.role === ADMIN) && <SearchTableCell></SearchTableCell> }
                                    { (user?.role?.includes(SUPPER_ADMIN) || user?.role === ADMIN) && <SearchTableCell></SearchTableCell> }
                                    { (user?.role?.includes(SUPPER_ADMIN) || user?.role === ADMIN) && <SearchTableCell></SearchTableCell> }
                                </ListTableRow>
                                {farmList?.items?.length > 0 ?
                                    (farmList?.items || []).map((row, index) => (
                                        <ListTableRow key={index}>
                                            <ListTableCell
                                                sx={{ cursor: 'pointer' }}
                                                onClick={(e) => handleEditDetails(e, row.id)}>
                                                {row.friendlyFarmId}</ListTableCell>
                                            <ListTableCell
                                                sx={{ cursor: 'pointer' }}
                                                onClick={(e) => handleEditDetails(e, row.id)}>
                                                {row.farmName}
                                            </ListTableCell>
                                            <ListTableCell>{row.holdingNumber}</ListTableCell>
                                            <ListTableCell>{row.businessReferenceNumber}</ListTableCell>
                                            <ListTableCell>{row.farmOwner}</ListTableCell>
                                            <ListTableCell>{row.organisation}</ListTableCell>
                                            <ListTableCell>{row.office}</ListTableCell>
                                            <ListTableCell>{dayjs.utc(row.creationTime || '').local().format('D/M/YY')}</ListTableCell>
                                            { (user?.role?.includes(SUPPER_ADMIN) || user?.role === ADMIN)  && <ListTableCell>{_.find(LICENSE_OPTIONS, { value: row.license })?.key}</ListTableCell> }
                                            { (user?.role?.includes(SUPPER_ADMIN) || user?.role === ADMIN)  && <ListTableCell >{ row.licenseStartDate ? dayjs.utc(row.licenseStartDate || '').local().format('D/M/YY'):''}</ListTableCell> }
                                            { (user?.role?.includes(SUPPER_ADMIN) || user?.role === ADMIN)  && <ListTableCell>{ row.licenseEndDate  ? dayjs.utc(row.licenseEndDate || '').local().format('D/M/YY'):''}</ListTableCell> }
                                            <ListTableCell sx={{ textAlign: 'center' }}>
                                                <InfoTooltip title="Delete Farm">
                                                    <DeleteIcon
                                                        id='delete'
                                                        sx={{ cursor: 'pointer' }}
                                                        aria-controls={menuOpen}
                                                        onClick={(e) => handleDelete(e, row.id)}
                                                    />
                                                </InfoTooltip>
                                                <InfoTooltip title="Farm Reports">
                                                    <InsertDriveFileIcon
                                                        id='report'
                                                        sx={{ cursor: 'pointer' }}
                                                        aria-controls={menuOpen}
                                                        onClick={(e) => handleReportList(e, row.id)}
                                                    />
                                                </InfoTooltip>
                                            </ListTableCell>
                                        </ListTableRow>
                                    ))
                                    : <EmptyTableBody />}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={farmList?.totalCount}
                        rowsPerPage={pagination.rowsPerPage}
                        page={pagination.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <DeleteModal
                        value={'Are you sure you want to delete this farm?'}
                        isOpen={deleteModalOpen}
                        handleResponse={handleDeleteModalResponse}
                    />
                    <CopyReportModal isOpen={copyModalOpen} handleResponse={handleCopyModalResponse} />
                </Paper>
            </Grid>
        </Grid >
    )
}

export default Farms