import * as actionTypes from './types';
import { updateObject } from '../utility';

const initialState = {
  user: {},
  forgotPassword: null,
  isLoggedIn: false,
  permissions: null,
  farmPermissions: null
  //loading: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIGN_IN_SUCCESS: return signInSuccess(state, action);
    case actionTypes.GET_USER_INFO_SUCCESS: return getUserInfoSuccess(state, action);
    case actionTypes.GET_USER_PERMISSIONS_SUCCESS: return getUserPermissionsSuccess(state, action);
    case actionTypes.GET_USER_PERMISSIONS_FOR_FARM_SUCCESS: return getUserPermissionsForFarmSuccess(state, action);
    case actionTypes.SIGN_UP_SUCCESS: return signUpSuccess(state, action);
    case actionTypes.SIGN_UP_FAIL: return signUpFail(state, action);
    case actionTypes.FORGOT_PASSWORD_SUCCESS: return reqResetPswSuccess(state, action);
    case actionTypes.FORGOT_PASSWORD_FAIL: return reqResetPswFail(state, action);
    case actionTypes.SIGN_OUT: return signOut(state, action);
    case actionTypes.RESET_AUTH_RESPONSE: return resetAuthResponse(state, action);
    default:
      return state;
  }
};

const signInSuccess = (state, action) => {
  const updatedState = {
    user: action.payload,
    isLoggedIn: true
  }
  return updateObject(state, updatedState);
}

const getUserInfoSuccess = (state, action) => {
  const updatedState = {
    user: {
      ...state.user,
      ...action.payload
    },
  }
  return updateObject(state, updatedState);
}

const getUserPermissionsSuccess = (state, action) => {
  const updatedState = {
    permissions: {
      ...state.permissions,
      ...action.payload
    },
  }
  return updateObject(state, updatedState);
}

const getUserPermissionsForFarmSuccess = (state, action) => {
  const updatedState = {
    farmPermissions: {
      ...state.permissions,
      ...action.payload
    },
  }
  return updateObject(state, updatedState);
}

const signUpSuccess = (state, action) => {
  const updatedState = {
    user: action.payload,
    isLoggedIn: true
  }
  return updateObject(state, updatedState);
}

const signUpFail = (state) => {
  return updateObject(state, { error: true });
};

const reqResetPswSuccess = (state, action) => {
  const updatedState = {
    forgotPassword: action.payload,
    isLoggedIn: true
  }
  return updateObject(state, updatedState);
}

const reqResetPswFail = (state) => {
  return updateObject(state, { error: true });
};

const signOut = (state) => {
  const updatedState = initialState;
  return updateObject(state, updatedState);
}

const resetAuthResponse = (state, action) => {
  const updatedState = {
    isLoggedIn: false
  }
  return updateObject(state, updatedState);
}

export default authReducer;
