import * as actionTypes from './types';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';
import store from '../..';

export const getArableRotationMetadata = ({ reportId }) => async (dispatch) => {

  const response = await api.get(endpoints.soilCarbon.arableRotation.metaData.concat('?reportId=', reportId));
  if (response && response.status === 200) {
    return dispatch(getArableRotationMetadataSuccess(response?.data));
  }
  else
    return dispatch(getArableRotationMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getArableRotationMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_ARABLE_ROTATION_METADATA_SUCCESS,
    payload: response
  };
};

const getArableRotationMetadataFail = () => {
  return {
    type: actionTypes.GET_ARABLE_ROTATION_METADATA_FAIL,
  };
};

export const resetArableRotationMetadata = () => {
  return {
    type: actionTypes.RESET_ARABLE_ROTATION_METADATA,
  };
};

export const getArableRotationFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.soilCarbon.arableRotation.formData.concat('?reportId=', reportId));
  if (response && response.status === 200 && response?.data) {
    return dispatch(getArableRotationFormDataSuccess(response?.data?.data));
  } else
    return dispatch(getArableRotationFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getArableRotationFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_ARABLE_ROTATION_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getArableRotationFormDataFail = (response) => {
  return {
    type: actionTypes.GET_ARABLE_ROTATION_FORM_DATA_FAIL,
    payload: response
  };
};

export const saveArableRotationData = ({ reportId }) => async (dispatch) => {
  const { currentFormData } = store.getState().arableRotation
  const response = await api.post(endpoints.soilCarbon.arableRotation.saveData,
    {
      reportId: reportId,
      data: currentFormData
    });
  if (response && response.status === 200) {
    return dispatch(saveArableRotationDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(saveArableRotationDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const saveArableRotationDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveArableRotationDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};

export const setArableRotationFormData = (response) => {
  return {
    type: actionTypes.SET_ARABLE_ROTATION_FORM_DATA,
    payload: response
  };
};


export const getArableRotationOptions = () => async (dispatch) => {
  try {
    const response = await api.get(endpoints.landCrops.soilCarbon.lookup.arableRotationOptions);
    if (response && response.status === 200) {
      let options={}
      for(const e of (response?.data || [])){
        options[e.enumName]=e.arableEnums;
      }
      return dispatch(getArableRotationSuccess(options));
    }
    else
      return dispatch(getArableRotationOptionsFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
  } catch (e) {
    return dispatch(getArableRotationOptionsFail(e));
  }
};

const getArableRotationSuccess = (response) => {
  return {
    type: actionTypes.GET_ARABLE_ROTATION_OPTIONS_SUCCESS,
    payload: response
  };
};

const getArableRotationOptionsFail = (response) => {
  return {
    type: actionTypes.GET_ARABLE_ROTATION_OPTIONS_FAIL,
    payload: response
  };
};

