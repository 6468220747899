import { Chip, Grid, MenuItem, Paper, Table, TableBody, TableContainer, TableHead, TablePagination, TableRow, Button } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import toastr from "toastr";
import { InfoTooltip, ListTableCell, ListTableRow, SearchTableCell, NoMaxWidthTooltip } from '../../../components/CustomStyles/StyledComponents';
import { deleteAdminReport, getAllAdminReports, resetResponseState, setAdminFarmId, setFarmId, setLoading, setReportId, unsetLoading,getAdminFarm , clearAllAdminReportsData } from '../../../store/appAction';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteReportModal from '../../../components/Modal/DeleteReportModal';
import { toastrCustomOptions, toastrCustomOptionsLonger,toastrCustomOptionsNoFarmSelectPopUp } from '../../../constants/toastrOptions';
import { REPORT_STATUS, REPORT_STATUS_OPTIONS } from '../../../constants/arrays';
import EmptyTableBody from '../../../components/Tables/EmptyTableBody';
import SortButton from '../../../components/Buttons/SortButton';
import TableSearchText from '../../../components/Inputs/TableSearchText';
import { debounce } from 'lodash';
import TableSearchDatePicker from '../../../components/Inputs/TableSearchDatePicker';
import TableSearchSelect from '../../../components/Inputs/TableSearchSelect';
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import { ADMIN, FARMER,CONSULTANT } from '../../../constants/userRoles';
import { endpoints } from '../../../api/endpoints';
import * as api from '../../../api/base';
import {saveAs} from 'file-saver';
import queryString from 'query-string';

const SORTING = 'friendlyReportId';
const SORT_ORDER = 'asc';
const PAGE = 0;
const ROWS_PER_PAGE = 10;

const Reports = () => {
    dayjs.extend(utc);
    toastr.options = toastrCustomOptions;
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const { successMsg, errorMsg } = useSelector(state => state.common);
    const reportList = useSelector(state => state.adminReports.reports);
    const { loading } = useSelector(state => state.common);
    const permissions = useSelector(state => state.auth.permissions);  

    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [currentReport, setCurrentReport] = useState(false);
    const [searchQuery, setSearchQuery] = useState({
        sorting: SORTING,
        sortOrder: SORT_ORDER,
        skipCount: PAGE,
        maxResultCount: ROWS_PER_PAGE
    });
    const farmId = useSelector(state => state.common.farmId);
    const user = useSelector(state => state.auth.user);
    const selectedFarmId = useSelector(state => ([FARMER].includes(user.role) ?
    state.common.farmId : state.adminFarm.farmId));
    const [pagination, setPagination] = useState({
        page: PAGE,
        rowsPerPage: ROWS_PER_PAGE
    });
    const adminFarm = useSelector(state => state.adminFarm);
    const [skip, setSkip] = useState(false);

    const menuOpen = Boolean(anchorEl);

    useEffect(() => {
        dispatch(setLoading());
        return () => { 
            dispatch(clearAllAdminReportsData());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        reportList && setTimeout(() => {
            setSkip(false);
            dispatch(unsetLoading());
        }, 400);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportList]);

    useEffect(() => {
        const { page, rowsPerPage } = pagination;

        !skip && reportList?.items && setSearchQuery(prevState => ({
            ...prevState,
            skipCount: page * rowsPerPage,
            maxResultCount: rowsPerPage
        }));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pagination]);


    useEffect(() => {
        dispatch(getAllAdminReports(searchQuery));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    useEffect(() => {
        if (successMsg || errorMsg) {
            dispatch(unsetLoading());
            if (successMsg) {
                toastr.success(successMsg);
                dispatch(getAllAdminReports(searchQuery));
            }
            errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
            dispatch(resetResponseState());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [successMsg, errorMsg]);

    const handleChangePage = (event, newPage) => {
        setPagination(prevState => ({
            ...prevState,
            page: newPage
        }));
    };

    const handleChangeRowsPerPage = (event) => {
        setPagination(prevState => ({
            ...prevState,
            page: 0,
            rowsPerPage: parseInt(event.target.value, 10)
        }));
    };

    const handleDelete = async (event, reportId) => {
        setAnchorEl(null);
        setCurrentReport(reportId);
        if(reportId){
            const response = await api.get(endpoints.farmReport.details.concat('?reportId=', reportId));
            if (response && response.status === 200) {
              if(response?.data?.isUpgraded === false){
                toastr.warning("This farm report cannot be updated as it belongs to a user that has not been approved for Agrecalc cloud.", undefined, toastrCustomOptionsNoFarmSelectPopUp);
              }else{
                setDeleteModalOpen(true);
              }
            }
          }
    }

    const exportData = async () => {
        dispatch(setLoading());
        const response = await api.getFileAsBlob(endpoints.reportExport.monthlyReport.concat('?', queryString.stringify(searchQuery)));
        if (response && response.status === 200) {
            const blob = new Blob([response.data], {
                type: 'application/vnd.ms-excel'
            });
            saveAs(blob, `Report.xlsx`);
            dispatch(unsetLoading());

        }else{
            dispatch(unsetLoading());
            toastr.error("An error occurred while attempting to export your data. Please try again.", undefined, toastrCustomOptionsNoFarmSelectPopUp);
        }
    }

    const handleDeleteModalResponse = (positiveResponse) => {
        setDeleteModalOpen(false);
        if (positiveResponse) {
            dispatch(deleteAdminReport(currentReport));
        }
    }

    const handleFarmEditDetails = (event, farmId) => {
        dispatch(setFarmId(farmId));
        dispatch(setAdminFarmId(farmId));
        navigate('/admin/farm/edit')

    }

    const handleReportEditDetails = (event, reportId, farmId) => {
        if(Object.values(permissions ?? {}).some(value => ['AgreCalc.Reports', 'AgreCalc.Reports.Read', 'AgreCalc.Reports.Update'].includes(value))){
            dispatch(setAdminFarmId(farmId));
            dispatch(getAdminFarm(farmId));
            dispatch(setFarmId(farmId));
            dispatch(setReportId(reportId));
            navigate('/data-entry/report/edit');
        }
    }

    const setSorting = ({ sortOrder, sorting }) => {
        setSearchQuery(prevState => ({
            ...prevState,
            sortOrder, sorting
        }));
    }

    const buildSearchQuery = (key, value) => {
        setSkip(true);
        setPagination({
            page: 0,
            rowsPerPage: 10
        });
        setSearchQuery(prevState => ({
            ...prevState,
            [key]: value || undefined,
            skipCount: PAGE,
            maxResultCount: ROWS_PER_PAGE
        }));
        // dispatch(setLoading());
    };

    const debouncedBuildSearchQuery = useMemo(() => {
        return debounce(buildSearchQuery, 500);
    }, []);

    // useEffect(() => {
    //     return () => {
    //         debouncedBuildSearchQuery.cancel();
    //     };
    // });

    const renderStatusTooltip = () => {
        const text = `
        <div><strong>Incomplete</strong> - This report has either not been submitted or has missing information.</div>
        <div><strong>Under Validation</strong> - This report has been submitted and is currently being reviewed for validation.</div>
        <div><strong>Valid (not in benchmarks)</strong> - This report has been completed and not accepted to the benchmarking database.</div>
        <div><strong>Validated</strong> - This report has been completed and accepted to the benchmarking database.</div>
        `;
        return <div dangerouslySetInnerHTML={{__html: text}}/>
    };

    const addTextColor = (cellData) => {
        switch(cellData){
            case 'Incomplete':  return <ListTableCell color='#C94D49'>{cellData}</ListTableCell>  
            case 'Under Validation':  return <ListTableCell color='#EDA710'>{cellData}</ListTableCell> 
            case 'Validated':  return <ListTableCell color='#5ace5d'>{cellData}</ListTableCell>
            case 'Valid (not in benchmarks)':  return <ListTableCell color='#595959'>{cellData}</ListTableCell> 
            default : return <ListTableCell>{cellData}</ListTableCell>
        }
      }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={8} lg={9}>
                <Paper
                    style={{ paddingTop:'0px' }}
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
                </Paper>
            </Grid>
            <Grid item xs={12} md={4} lg={3}>
                <Paper
                style={{ paddingTop:'0px' }}
                    sx={{
                        p: 2,
                        flexDirection: 'column',
                        textAlign: 'center'
                    }}
                >
                {
                    // Following code line has comment out due to the AGC-2313
                    //Object.values(permissions ?? {}).some(value => ['AgreCalc.Reports.ExportCSV'].includes(value)) &&
                    <Button variant="contained"
                        disabled={loading}
                        sx={{ textTransform: 'none' }}
                        onClick={(e) => exportData()}>
                        Export Data
                    </Button>
                }
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <TableContainer component={Paper}>
                        <Table sx={{ width: '100%' }} size="small" aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <ListTableCell sx={{ textAlign: '', width: '11%' }}>Report ID
                                        <SortButton
                                            sortKey={'friendlyReportId'}
                                            currentSortKey={searchQuery?.sorting}
                                            handleSorting={setSorting}
                                        />
                                        <NoMaxWidthTooltip title={'Click on the ID value to navigate to the Report'}>
                                            <InfoOutlinedIcon  color='primary' sx={{  verticalAlign: 'middle' , cursor: 'pointer'}} fontSize='small' />
                                        </NoMaxWidthTooltip>
                                    </ListTableCell>
                                    <ListTableCell sx={{ textAlign: 'left', width: '11%' }}>Farm ID
                                        <SortButton
                                            sortKey={'friendlyFarmId'}
                                            currentSortKey={searchQuery?.sorting}
                                            handleSorting={setSorting}
                                        />
                                        <NoMaxWidthTooltip title={'Click on the Farm ID to navigate to the Farm'}>
                                            <InfoOutlinedIcon  color='primary' sx={{  verticalAlign: 'middle' , cursor: 'pointer'}} fontSize='small' />
                                        </NoMaxWidthTooltip>
                                    </ListTableCell>
                                    <ListTableCell sx={{ textAlign: 'left', width: '10%' }}>Farm Name
                                        <SortButton
                                            sortKey={'farmName'}
                                            currentSortKey={searchQuery?.sorting}
                                            handleSorting={setSorting}
                                        />
                                    </ListTableCell>
                                    <ListTableCell sx={{ textAlign: 'left', width: '10%' }}>Reference
                                        <SortButton
                                            sortKey={'reference'}
                                            currentSortKey={searchQuery?.sorting}
                                            handleSorting={setSorting}
                                        />
                                    </ListTableCell>
                                    <ListTableCell sx={{ textAlign: 'left', width: '11%' }}>Year End
                                        <SortButton
                                            sortKey={'yearEnd'}
                                            currentSortKey={searchQuery?.sorting}
                                            handleSorting={setSorting}
                                        />
                                    </ListTableCell>
                                    <ListTableCell sx={{ textAlign: 'left', width: '10%' }}>Organisation
                                        <SortButton
                                            sortKey={'organisation'}
                                            currentSortKey={searchQuery?.sorting}
                                            handleSorting={setSorting}
                                        />
                                    </ListTableCell>
                                    <ListTableCell sx={{ textAlign: 'left', width: '10%' }}>Office
                                        <SortButton
                                            sortKey={'office'}
                                            currentSortKey={searchQuery?.sorting}
                                            handleSorting={setSorting}
                                        />
                                    </ListTableCell>
                                    <ListTableCell sx={{ textAlign: 'left', width: '10%' }}>Projects
                                    </ListTableCell>
                                    <ListTableCell sx={{ textAlign: 'left', width: '10%' }}>Status
                                        <SortButton
                                            sortKey={'status'}
                                            currentSortKey={searchQuery?.sorting}
                                            handleSorting={setSorting}
                                        />
                                        <NoMaxWidthTooltip title={renderStatusTooltip()}>
                                            <InfoOutlinedIcon  color='primary' sx={{ marginLeft: '5px', verticalAlign: 'middle' , cursor: 'pointer'}} fontSize='small' />
                                        </NoMaxWidthTooltip>
                                    </ListTableCell>
                                    <ListTableCell sx={{ textAlign: 'left', width: '12%' }}>Updated On
                                        <SortButton
                                            sortKey={'lastUpdated'}
                                            currentSortKey={searchQuery?.sorting}
                                            handleSorting={setSorting}
                                        />
                                    </ListTableCell>
                                    <ListTableCell sx={{ textAlign: 'left', width: '8%' }}>Updated By
                                        <SortButton
                                            sortKey={'lastUpdatedBy'}
                                            currentSortKey={searchQuery?.sorting}
                                            handleSorting={setSorting}
                                        />
                                    </ListTableCell>
                                    <ListTableCell sx={{ textAlign: 'center', width: '6%' }}>Action</ListTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <ListTableRow>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="friendlyReportId"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                            autoFocus={true}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="friendlyFarmId"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="farmName"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="reference"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchDatePicker
                                            inputFormat='MM-YY'
                                            views={['month', 'year']}
                                            searchKey="yearEnd"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="organisation"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="office"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="projects"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchSelect
                                            searchKey="status"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        >
                                            {REPORT_STATUS_OPTIONS.map((option) => (
                                                <MenuItem key={option.key} value={option.value}>{option.key}</MenuItem>
                                            ))}
                                        </TableSearchSelect>
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchDatePicker
                                            searchKey="lastUpdated"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell>
                                        <TableSearchText
                                            searchKey="lastUpdatedBy"
                                            handleSearchQuery={debouncedBuildSearchQuery}
                                        />
                                    </SearchTableCell>
                                    <SearchTableCell></SearchTableCell>
                                </ListTableRow>
                                {reportList?.items?.length > 0 ?
                                    (reportList?.items || []).map((row, index) => (
                                        <ListTableRow key={index}>
                                            <ListTableCell
                                                sx={{ cursor: 'pointer' }}
                                                onClick={(e) => handleReportEditDetails(e, row.reportId, row.farmId)}>
                                                {row.friendlyReportId}
                                            </ListTableCell>
                                            <ListTableCell
                                                sx={{ cursor: 'pointer' }}
                                                onClick={(e) => handleFarmEditDetails(e, row.farmId)}>
                                                {row.friendlyFarmId}
                                            </ListTableCell>
                                            <ListTableCell
                                                sx={{ cursor: 'pointer' }}
                                                onClick={(e) => handleFarmEditDetails(e, row.farmId)}>
                                                {row.farmName}
                                            </ListTableCell>
                                            <ListTableCell>{row.reference}</ListTableCell>
                                            <ListTableCell>{dayjs(row.yearEnd).format('MMMM YYYY')}</ListTableCell>
                                            <ListTableCell>{row.organisation}</ListTableCell>
                                            <ListTableCell>{row.office}</ListTableCell>
                                            <ListTableCell>
                                                {row.projects.map((project) => (
                                                    <Chip
                                                        sx={{ m: 0.2 }}
                                                        size="small"
                                                        color="success"
                                                        label={project}
                                                        variant="outlined" />
                                                ))
                                                }
                                            </ListTableCell>
                                            { addTextColor(REPORT_STATUS[row.status]) }
                                            <ListTableCell>{row.lastUpdated ? dayjs(row.lastUpdated).format('D/M/YY H:mm') : '--'}</ListTableCell>
                                            <ListTableCell>{row.lastUpdatedBy}</ListTableCell>
                                            <ListTableCell sx={{ textAlign: 'center' }}>
                                                <InfoTooltip title="Delete Report">
                                                    <DeleteIcon
                                                        id='delete'
                                                        sx={{ cursor: 'pointer' }}
                                                        aria-controls={menuOpen}
                                                        onClick={(e) => handleDelete(e, row.reportId)}
                                                    />
                                                </InfoTooltip>
                                            </ListTableCell>
                                        </ListTableRow>
                                    ))
                                    : <EmptyTableBody title="reports" />}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={reportList?.totalCount}
                        rowsPerPage={pagination.rowsPerPage}
                        page={pagination.page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <DeleteReportModal
                        isOpen={deleteModalOpen}
                        handleResponse={handleDeleteModalResponse} />
                </Paper>
            </Grid>
        </Grid>
    )
}

export default Reports