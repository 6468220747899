import * as actionTypes from './types';
import { endpoints } from '../../../api/endpoints';
import * as api from '../../../api/base';
import store from '../..';

export const getAdminOffices = () => async (dispatch) => {
    const response = await api.get(endpoints.admin.office.office);
    if (response && response.status === 200) {
        return dispatch(getAdminOfficesSuccess(response.data));
    }
    else {
        return dispatch(getAdminOfficesFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
}

export const getAdminOfficesByOrg = (organisationId) => async (dispatch) => {
    const response = await api.get(endpoints.admin.office.listByOrg.replace('ORG_ID', organisationId));
    if (response && response.status === 200) {
        return dispatch(getAdminOfficesSuccess(response.data));
    }
    else {
        return dispatch(getAdminOfficesFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
}

const getAdminOfficesSuccess = (response) => {
    return {
        type: actionTypes.GET_ADMIN_OFFICE_LIST_SUCCESS,
        payload: response
    };
};

const getAdminOfficesFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
}

export const createOffice = (data) => async (dispatch) => {
    const { name, organisationId, file } = data;
    const formData = new FormData();
    if(file){
        formData.append('file',file)
    }
    formData.append('officeDTO', JSON.stringify({
        OfficeName: name,
        OrganizationId:organisationId
    }));
    
    try {
        const response = await api.postMultipartFormData(endpoints.admin.office.office,
            formData
        );
        if (response && response.status === 200) {
            return dispatch(createOfficeSuccess("Office has been created successfully."));
        }
        else
            return dispatch(createOfficeFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    } catch (e) {
        return dispatch(createOfficeFail(e?.message));
    }
};

const createOfficeSuccess = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_SUCCESS,
        payload: response
    };
};

const createOfficeFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
};

export const setCurrentAdminOffice = (officeId) => {
    return {
        type: actionTypes.SET_CURRENT_ADMIN_OFFICE,
        payload: officeId
    };
};

export const updateOffice = (data) => async (dispatch) => {
    const { id, name, organisationId, file } = data;
    const formData = new FormData();
    if(file){
        formData.append('file',file)
    }
    formData.append('officeDTO', JSON.stringify({
        OfficeName: name,
        OrganizationId: organisationId
    }));
    formData.append('OfficeId', id);

    try {
        const response = await api.putMultipartFormData(endpoints.admin.office.office,
            formData
        );
        if (response && response.status === 200) {
            return dispatch(updateOfficeSuccess("Office has been updated successfully."));
        }
        else {
            return dispatch(updateOfficeFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
        }
    } catch (e) {
        return dispatch(updateOfficeFail(e?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const updateOfficeSuccess = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_SUCCESS,
        payload: response
    };
};

const updateOfficeFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
};


export const deleteOffice = () => async (dispatch) => {
    const { id } = store.getState().adminOffice.selectedOffice;
    const response = await api.del(endpoints.admin.office.office.concat('?Id=', id));
    if (response && (response.status === 200 || response.status === 204)) {
        return dispatch(deleteOfficeSuccess(response.data || "Successfully deleted the Office"));
    }
    else {
        return dispatch(deleteOfficeFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const deleteOfficeSuccess = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_SUCCESS,
        payload: response
    };
};

const deleteOfficeFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response

    };
};

