import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import produce from "immer";
import { Grid, Paper, List, ListSubheader, Button , Stack} from "@mui/material";
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody"
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import toastr from "toastr";
import _ from "lodash";
import dayjs from 'dayjs';
import {
  FormTitleTypography, FormTableRow, FormTableCell,
  SectorTitleTypography, DataEntryTextField, FormHeaderPaper,
  CustomizedDivider
} from "../../../components/CustomStyles/StyledComponents";
import CustomizableCancelModal from "../../../components/Modal/CustomizableCancelModal";
import { toastrCustomOptions, toastrCustomOptionsLonger } from "../../../constants/toastrOptions";
import { addCommas, removeCommas } from "../../../helpers/stringFormatHelper";
import { getFirstVisibleCellDetailsInDataEntry } from '../../../helpers/dataEntryHelper';
import {
  getMonthlyStockData,
  setMonthlyStockData,
  resetMonthlyStockData,
  saveMonthlyStockData,
  resetResponseState,
  unsetLoading,
  setLoading
} from "../../../store/appAction";
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import SelectedReport from '../../../components/SelectedReport/SelectedReport';
import queryString from 'query-string';

const DataEntryTable = ({ key, sector, initialFormData, firstVisibleCellDetails, locked, sectorIndex }) => {
  toastr.options = toastrCustomOptions;
  const dispatch = useDispatch();
  const { reportDetails } = useSelector(state => state.farmReport);
  const [sectorDataInt, setSectorDataInt] = useState(undefined);
  const [sectorDataExt, setSectorDataExt] = useState(undefined);

  const startDate = dayjs(reportDetails?.yearEnd).startOf('month');
  const monthsArray = Array.from({ length: 12 }, (_, i) => {
    return dayjs(startDate).subtract(i, 'months').format('MMM YYYY');
  }).reverse();

  useEffect(() => {
    setSectorDataInt(initialFormData);
    setSectorDataExt(initialFormData);
  }, [initialFormData]);

  useEffect(() => {
    if (sectorDataExt && !_.isEmpty(sectorDataExt)) {
      dispatch(setMonthlyStockData({
        sectorId: sector.sectorId,
        formData: sectorDataExt
      }));
    }
  }, [sectorDataExt]);


  const handleInputChange = useCallback((                
    e,
    sectorId,
    groupId,
    esItemId,
    stockId,
    stock,
    stockKey,
    rowData,
    esGroupIndex,
    esItemIndex,
    calculatedAverage
  ) => {
    let value = parseFloat(removeCommas(e.target.value) || 0);
    const previousRowData = {...rowData};
    previousRowData[stockKey] = value;
    let average = 0.00;
    const month1 = parseFloat(previousRowData['month1']);
    const month2 = parseFloat(previousRowData['month2']);
    const month3 = parseFloat(previousRowData['month3']);
    const month4 = parseFloat(previousRowData['month4']);
    const month5 = parseFloat(previousRowData['month5']);
    const month6 = parseFloat(previousRowData['month6']);
    const month7 = parseFloat(previousRowData['month7']);
    const month8 = parseFloat(previousRowData['month8']);
    const month9 = parseFloat(previousRowData['month9']);
    const month10 = parseFloat(previousRowData['month10']);
    const month11 = parseFloat(previousRowData['month11']);
    const month12 = parseFloat(previousRowData['month12']);
    let lineTotal = month1 + month2 + month3 + month4 + month5 + month6 + month7 + month8 + month9 + month10 + month11 + month12 ;
    average = parseFloat(lineTotal / 12).toFixed(2);
    if(e.target.inputMode === "numeric"){
      let numericRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
      if (numericRegex.test(value)) {
        setSectorDataInt(produce((draft) => {
          draft.esGroups[esGroupIndex].esItems[esItemIndex].stock[stockKey] = value;
          draft.esGroups[esGroupIndex].esItems[esItemIndex].stock['average'] = average;
        }));
        setSectorDataExt(produce((draft) => {
          draft.esGroups[esGroupIndex].esItems[esItemIndex].stock[stockKey] = value;
          draft.esGroups[esGroupIndex].esItems[esItemIndex].stock['average'] =  average;
        }));
      }

    }
  }, []);
      const getCellContent = ({ sectorId, groupId, esItemId, stockId, stock, stockKey, rowData,disabled, esGroupIndex, esItemIndex}) => {

        // TODO : This should develop effeciency way in future
          let calculatedAverage = 0.00;
          if(rowData){
            const month1 = parseFloat(rowData['month1']);
            const month2 = parseFloat(rowData['month2']);
            const month3 = parseFloat(rowData['month3']);
            const month4 = parseFloat(rowData['month4']);
            const month5 = parseFloat(rowData['month5']);
            const month6 = parseFloat(rowData['month6']);
            const month7 = parseFloat(rowData['month7']);
            const month8 = parseFloat(rowData['month8']);
            const month9 = parseFloat(rowData['month9']);
            const month10 = parseFloat(rowData['month10']);
            const month11 = parseFloat(rowData['month11']);
            const month12 = parseFloat(rowData['month12']);
            let lineTotal = month1 + month2 + month3 + month4 + month5 + month6 + month7 + month8 + month9 + month10 + month11 + month12;
            calculatedAverage = parseFloat(lineTotal / 12).toFixed(2);
          }
        return (
          <DataEntryTextField
            disabled={disabled}
            size="small"
            variant="outlined"
            inputProps={{ inputMode: "numeric" }}
            style={{ width:  70, textAlign: "center" }}
            value={( stockKey === 'average' ? (calculatedAverage ) :  rowData && addCommas(rowData[stockKey])) || ''}
            onChange={(e) =>
              handleInputChange(
                e,
                sectorId,
                groupId,
                esItemId,
                stockId,
                stock,
                stockKey,
                rowData,
                esGroupIndex,
                esItemIndex,
                calculatedAverage
              )
            }
          />
        )
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ mb: 3 }}>
        {(sector.esGroups || []).map((esGroup, esGroupIndex) => (
          <Fragment>
            <SectorTitleTypography sx={{ mb: 2 }}>
              {sector.sectorName} - {esGroup.groupName}
            </SectorTitleTypography>
            <Table
              sx={{ minWidth: 700 }}
              size="small"
              aria-label="customized table"
            >
              <TableHead>
                <FormTableRow>
                  <FormTableCell sx={{ width: '2%' }}></FormTableCell>
                  <FormTableCell sx={{ width: '2%' }}>Closing livestock number at end of previous year</FormTableCell>
                  <FormTableCell sx={{ width: '2%' }}></FormTableCell>
                  <FormTableCell sx={{ width: '2%' }}></FormTableCell>
                  <FormTableCell sx={{ width: '2%' }}></FormTableCell>
                  <FormTableCell sx={{ width: '2%' }}></FormTableCell>
                  <FormTableCell sx={{ width: '2%' }}></FormTableCell>
                  <FormTableCell sx={{ width: '2%' }}></FormTableCell>
                  <FormTableCell sx={{ width: '2%' }}></FormTableCell>
                  <FormTableCell sx={{ width: '2%' }}></FormTableCell>
                  <FormTableCell sx={{ width: '2%' }}></FormTableCell>
                  <FormTableCell sx={{ width: '2%' }}></FormTableCell>
                  <FormTableCell sx={{ width: '2%' }}></FormTableCell>
                  <FormTableCell sx={{ width: '2%' }}>Average number of livestock over year(no)</FormTableCell>
                </FormTableRow>
              </TableHead>

              <TableBody>
                <FormTableRow>
                  <FormTableCell sx={{ width: '2%' }}></FormTableCell>
                  {
                    monthsArray?.map((month)=>{
                      return (<FormTableCell sx={{ width: '2%' }}>{ month }</FormTableCell>)
                    })
                  }
                  <FormTableCell sx={{ width: '2%' }}></FormTableCell>
                </FormTableRow>
                {
                  esGroup.esItems.map((esItem, esItemIndex)=> {
                    return(
                      
                      <FormTableRow>
                        <FormTableCell sx={{ width: '2%' }}>{esItem.itemName}</FormTableCell>
                          <FormTableCell sx={{ width: '2%' }}>{ getCellContent({
                              sectorId: sector.sectorId,
                              groupId: esGroup.groupId,
                              esItemId: esItem.itemId,
                              stockId: esItem.stock.id,
                              stock: esItem.stock,
                              stockKey:  'month1',
                              rowData: sectorDataInt?.esGroups[esGroupIndex]?.esItems[esItemIndex]?.stock,
                              disabled: false,
                              esGroupIndex: esGroupIndex,
                              esItemIndex: esItemIndex
                          })}</FormTableCell>

                          <FormTableCell sx={{ width: '2%' }}>{ getCellContent({
                              sectorId: sector.sectorId,
                              groupId: esGroup.groupId,
                              esItemId: esItem.itemId,
                              stockId: esItem.stock.id,
                              stock: esItem.stock,
                              stockKey:  'month2',
                              rowData: sectorDataInt?.esGroups[esGroupIndex]?.esItems[esItemIndex]?.stock,
                              disabled: false,
                              esGroupIndex: esGroupIndex,
                              esItemIndex: esItemIndex
                          }) }</FormTableCell>

                          <FormTableCell sx={{ width: '2%' }}>{ getCellContent({
                              sectorId: sector.sectorId,
                              groupId: esGroup.groupId,
                              esItemId: esItem.itemId,
                              stockId: esItem.stock.id,
                              stock: esItem.stock,
                              stockKey:  'month3',
                              rowData: sectorDataInt?.esGroups[esGroupIndex]?.esItems[esItemIndex]?.stock,
                              disabled: false,
                              esGroupIndex: esGroupIndex,
                              esItemIndex: esItemIndex
                          }) }</FormTableCell>

                          <FormTableCell sx={{ width: '2%' }}>{ getCellContent({
                              sectorId: sector.sectorId,
                              groupId: esGroup.groupId,
                              esItemId: esItem.itemId,
                              stockId: esItem.stock.id,
                              stock: esItem.stock,
                              stockKey:  'month4',
                              rowData: sectorDataInt?.esGroups[esGroupIndex]?.esItems[esItemIndex]?.stock,
                              disabled: false,
                              esGroupIndex: esGroupIndex,
                              esItemIndex: esItemIndex
                          }) }</FormTableCell>

                          <FormTableCell sx={{ width: '2%' }}>{ getCellContent({
                              sectorId: sector.sectorId,
                              groupId: esGroup.groupId,
                              esItemId: esItem.itemId,
                              stockId: esItem.stock.id,
                              stock: esItem.stock,
                              stockKey:  'month5',
                              rowData: sectorDataInt?.esGroups[esGroupIndex]?.esItems[esItemIndex]?.stock,
                              disabled: false,
                              esGroupIndex: esGroupIndex,
                              esItemIndex: esItemIndex
                          }) }</FormTableCell>

                          <FormTableCell sx={{ width: '2%' }}>{ getCellContent({
                              sectorId: sector.sectorId,
                              groupId: esGroup.groupId,
                              esItemId: esItem.itemId,
                              stockId: esItem.stock.id,
                              stock: esItem.stock,
                              stockKey:  'month6',
                              rowData: sectorDataInt?.esGroups[esGroupIndex]?.esItems[esItemIndex]?.stock,
                              disabled: false,
                              esGroupIndex: esGroupIndex,
                              esItemIndex: esItemIndex
                          }) }</FormTableCell>

                          <FormTableCell sx={{ width: '2%' }}>{ getCellContent({
                              sectorId: sector.sectorId,
                              groupId: esGroup.groupId,
                              esItemId: esItem.itemId,
                              stockId: esItem.stock.id,
                              stock: esItem.stock,
                              stockKey:  'month7',
                              rowData: sectorDataInt?.esGroups[esGroupIndex]?.esItems[esItemIndex]?.stock,
                              disabled: false,
                              esGroupIndex: esGroupIndex,
                              esItemIndex: esItemIndex
                          }) }</FormTableCell>

                          <FormTableCell sx={{ width: '2%' }}>{ getCellContent({
                              sectorId: sector.sectorId,
                              groupId: esGroup.groupId,
                              esItemId: esItem.itemId,
                              stockId: esItem.stock.id,
                              stock: esItem.stock,
                              stockKey:  'month8',
                              rowData: sectorDataInt?.esGroups[esGroupIndex]?.esItems[esItemIndex]?.stock,
                              disabled: false,
                              esGroupIndex: esGroupIndex,
                              esItemIndex: esItemIndex
                          }) }</FormTableCell>

                          <FormTableCell sx={{ width: '2%' }}>{ getCellContent({
                              sectorId: sector.sectorId,
                              groupId: esGroup.groupId,
                              esItemId: esItem.itemId,
                              stockId: esItem.stock.id,
                              stock: esItem.stock,
                              stockKey:  'month9',
                              rowData: sectorDataInt?.esGroups[esGroupIndex]?.esItems[esItemIndex]?.stock,
                              disabled: false,
                              esGroupIndex: esGroupIndex,
                              esItemIndex: esItemIndex
                          }) }</FormTableCell>

                          <FormTableCell sx={{ width: '2%' }}>{ getCellContent({
                              sectorId: sector.sectorId,
                              groupId: esGroup.groupId,
                              esItemId: esItem.itemId,
                              stockId: esItem.stock.id,
                              stock: esItem.stock,
                              stockKey:  'month10',
                              rowData: sectorDataInt?.esGroups[esGroupIndex]?.esItems[esItemIndex]?.stock,
                              disabled: false,
                              esGroupIndex: esGroupIndex,
                              esItemIndex: esItemIndex
                          }) }</FormTableCell>

                          <FormTableCell sx={{ width: '2%' }}>{ getCellContent({
                              sectorId: sector.sectorId,
                              groupId: esGroup.groupId,
                              esItemId: esItem.itemId,
                              stockId: esItem.stock.id,
                              stock: esItem.stock,
                              stockKey:  'month11',
                              rowData: sectorDataInt?.esGroups[esGroupIndex]?.esItems[esItemIndex]?.stock,
                              disabled: false,
                              esGroupIndex: esGroupIndex,
                              esItemIndex: esItemIndex
                          }) }</FormTableCell>

                          <FormTableCell sx={{ width: '2%' }}>{ getCellContent({
                              sectorId: sector.sectorId,
                              groupId: esGroup.groupId,
                              esItemId: esItem.itemId,
                              stockId: esItem.stock.id,
                              stock: esItem.stock,
                              stockKey:  'month12',
                              rowData: sectorDataInt?.esGroups[esGroupIndex]?.esItems[esItemIndex]?.stock,
                              disabled: false,
                              esGroupIndex: esGroupIndex,
                              esItemIndex: esItemIndex
                          }) }</FormTableCell>

                          <FormTableCell sx={{ width: '2%' }}>{ getCellContent({
                              sectorId: sector.sectorId,
                              groupId: esGroup.groupId,
                              esItemId: esItem.itemId,
                              stockId: esItem.stock.id,
                              stock: esItem.stock,
                              stockKey: 'average',
                              rowData: sectorDataInt?.esGroups[esGroupIndex]?.esItems[esItemIndex]?.stock,
                              disabled: true,
                              esGroupIndex: esGroupIndex,
                              esItemIndex: esItemIndex
                          }) }</FormTableCell>
                      </FormTableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </Fragment>
        ))}
      </TableContainer>
    </>
  );
};

const MonthlyStockTool = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const reportId = useSelector(state => state.common.reportId);
  const initialFormData = useSelector(state => state.monthlyStock.initialFormData);
  const { successMsg, errorMsg } = useSelector(state => state.common);
  const farmId = useSelector(state => state.common.farmId);
  const adminFarm = useSelector(state => state.adminFarm);
  const {reportDetails: { reportName }} = useSelector(state => state.farmReport);
  const [formData, setFormData] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [firstVisibleCellDetails, setFirstVisibleCellDetails] = useState(null);

  const sectors = initialFormData?.sectors || [];

  useEffect(() => {
    dispatch(setLoading());
    dispatch(getMonthlyStockData({ reportId }));
    dispatch(resetResponseState());
    return () => {
      dispatch(resetMonthlyStockData());
    }
  }, []);

  useEffect(() => {
    const tempData = { ...initialFormData };
    setFormData(tempData);
    setTimeout(() => {
      dispatch(unsetLoading());
    }, 500);
  }, [initialFormData]);


  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(unsetLoading());
      successMsg && toastr.success(successMsg);
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(resetResponseState());
      if (successMsg) {
        dispatch(resetMonthlyStockData());
        navigate(`/data-entry/livestock/numbers-weights?${queryString.stringify({ monthlyStockTool: true })}`);
      }
      setNextClicked(false);
    }
  }, [successMsg, errorMsg]);

  useEffect(() => {
    const firstVisibleCellDetails = getFirstVisibleCellDetailsInDataEntry(sectors);
    setFirstVisibleCellDetails(firstVisibleCellDetails);
  }, [sectors]);

  const handleModalResponse = (positiveResponse) => {
    setDeleteModalOpen(false);
    if (positiveResponse) {
      dispatch(setLoading());
      dispatch(getMonthlyStockData({ reportId }));
    }
  }

  const handelSaveClick = (e) => {
    dispatch(setLoading());
    dispatch(saveMonthlyStockData({ reportId }));
  }

  return (
    <ScrollSync>
    <Grid container spacing={2} className={props.locked && 'deactivated'}>
      <Grid item xs={12} md={12} lg={12}>
        <FormHeaderPaper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
          divider={false}
        >
          <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
          <SelectedReport reportName={reportName} />
          <FormTitleTypography variant="h5" component="div">
            Livestock Monthly Stock Totals Tool
          </FormTitleTypography>
        </FormHeaderPaper>
      </Grid>

      <Grid item xs={12} sx={{ paddingTop:'0 !important' }}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", paddingTop:'0' }}>
          <List >
            <ListSubheader sx={{ bgcolor: "background.paper" }}>
              <ScrollSyncPane>
                <>
                </>
              </ScrollSyncPane>
            </ListSubheader>

          </List>
        </Paper>
      </Grid>

      <Grid item xs={12} sx={{ paddingTop:'0 !important' }}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", paddingTop:'0' }}>
          <List sx={{ mb: 2 }}>
            <ListSubheader sx={{ bgcolor: "background.paper" }}>
              <ScrollSyncPane>
                <>
                <Grid container direction="row" >
                    <Grid item xs={4}>
                    <Button
                          variant="outlined"
                          sx={{ textTransform: 'none' }}
                          onClick={() => { navigate('/data-entry/livestock/numbers-weights'); }}>
                          Close livestock monthly stock totals tool
                        </Button>
                    </Grid>
                    <Grid item xs={8} sx={{ display:'flex', alignItems:'center' }}>
                      <Stack spacing={2} sx={{ marginLeft: 'auto', float: 'right' }} direction="row">
                        <Button
                          variant="outlined"
                          sx={{ textTransform: 'none' }}
                          onClick={() => setDeleteModalOpen(true)}>
                          Cancel
                        </Button>
                        <Button variant="outlined"
                          sx={{ textTransform: 'none' }}
                          onClick={handelSaveClick}>
                          Save
                        </Button>
                        
                      </Stack>
                    </Grid>
                  </Grid>
                <CustomizedDivider/>
                </>
              </ScrollSyncPane>
            </ListSubheader>

            {formData && formData.sectors && formData.sectors.map((sector, index) => (
              <>
                <React.Fragment key={index}>
                  <DataEntryTable
                    key={sector.id}
                    sector={sector}
                    initialFormData={formData.sectors.find(s => s.sectorId === sector.sectorId) || {} }
                    firstVisibleCellDetails={firstVisibleCellDetails}
                    locked={props.locked}
                    sectorIndex = {index}
                  />
                </React.Fragment>
              </>
            ))}
          </List>
            <CustomizableCancelModal
                isOpen={deleteModalOpen}
                value='Choose Yes to lose all of the values you have entered in this session or choose No to continue.'
                handleResponse={(value)=>{
                  handleModalResponse(value);
                }} 
            />
        </Paper>
      </Grid>
    </Grid >
    </ScrollSync>
  );
};
export default MonthlyStockTool;
