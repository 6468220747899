import { Button, Grid, Paper, Typography, Link, 
        List, ListSubheader, ListItemText} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';
import { Cyrb53 } from "../../helpers/hashHelper";
import { ResultStatus } from '../../helpers/reportResultsHelper';


const ErrorList = ({ errorList }) => {
    return (
        <List>
            <ListSubheader variant="caption" fontWeight="bold" sx={{ color: 'error.main' }} mb={1}>
                Please check the following:
            </ListSubheader>
            {errorList.map((validations, index) => (
                <ListItemText id={'val-err-'+Cyrb53(validations.errorMessage, index)} key={Cyrb53(validations.errorMessage, index)} variant="caption" fontWeight="bold" sx={{ color: 'error.main' }} mb={1}>
                    {validations.errorMessage}
                </ListItemText>))}
        </List>
    );
}

ErrorList.propTypes = {
    errorList: PropTypes.array
};

const DataChecksErrors = ({ errorList, resultStatus} ) => {
    let navigate = useNavigate();

    const handleBackToReport = () => {
        navigate('/data-entry/report/edit')
    }
    return (
        <Grid container spacing={2}>
            { !errorList && (resultStatus === ResultStatus?.NotCalculated || resultStatus === ResultStatus?.Completed )?
                <Grid item xs={12}>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="body1" paragraph fontWeight="bold" mb={1}>
                            This report has not been calculated yet or it has no data. Please return to the data entry and finish the report. 
                        </Typography>
                    </Paper>
                </Grid> :

                <Grid item xs={12}>
                    <Paper sx={{p: 2, display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="body1" paragraph fontWeight="bold" color="error" mb={1}>
                            Unfortunately this report failed to calculate
                        </Typography>
                        <Typography variant="body2" paragraph color="error" mb={1}>
                            Please check your inputs or contact Agrecalc support (<Link variant="body2" color="error" mb={1} href="mailto:support@agrecalc.com" underline="always">support@agrecalc.com</Link>) for assistance.
                        </Typography>
                    </Paper>
                    <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column'}}>
                        {errorList ? <ErrorList errorList={errorList} /> : ''}
                    </Paper>
                </Grid>
            }
            <Grid item xs={12} p={2}>
                <Button variant="contained"
                    sx={{ textTransform: 'none', ml: 2 }}
                    onClick={handleBackToReport}>
                    Back to Report
                </Button>
            </Grid>

        </Grid >
    );
}

DataChecksErrors.propTypes = {
    errorList: PropTypes.array,
    resultStatus: PropTypes.object
};

export default DataChecksErrors;
