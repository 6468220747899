import {
  Button, FormControl, Grid, List,
  ListSubheader, MenuItem, Paper,
  Skeleton, Stack, Typography
} from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toastr from 'toastr';
import _ from 'lodash';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { isEqual } from "lodash";
import {
  FormHeaderPaper, FormTitleTypography, FormRowGroupTableRow,
  FormTableCell, SectorTitleTypography, TableListItem, DataEntryTextField, DataEntrySelect, FormDescriptionTypography, CustomizedDivider
} from '../../../components/CustomStyles/StyledComponents';
import { toastrCustomOptions, toastrCustomOptionsLonger } from '../../../constants/toastrOptions';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import produce from 'immer';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import {
  getOrganicMaterialManuresFormData,
  getOrganicMaterialManuresMetaData,
  resetOrganicMaterialManuresMetaData,
  resetResponseState,
  getOrganicMaterialManuresOptions,
  saveOrganicMaterialManuresData,
  setOrganicMaterialManuresFormData,
  setLoading,
  unsetLoading
} from '../../../store/appAction';
import CancelModal from '../../../components/Modal/CancelModal';
import { addCommas, m3Superscript } from '../../../helpers/stringFormatHelper';
import { getFirstVisibleCellDetailsInDataEntry } from '../../../helpers/dataEntryHelper';
import BackButton from '../../../components/Buttons/BackButton';
import DataEntryButtonStack from '../../../components/Buttons/DataEntryButtonStack';
import SelectedFarm from '../../../components/SelectedFarm/SelectedFarm';
import SelectedReport from '../../../components/SelectedReport/SelectedReport';
import store from '../../../store';

const CELL_WIDTH = {
  RowTitle: "25%",
  ManureType: "26%",
  TotalN: "11%",
  TotalP: "11%",
  Quantity: "11%",
  Add: "8%",
  Delete: "8%",
};

const LAND_AREA_TOTAL_KEY = 'LandAreaTotal';
const ORGANIC_MATERIAL_MANURES_ID_KEY = 'ManureId';
const QUANTITY_KEY = 'Quantity';
const Total_N_KEY = 'TotalN';
const Total_P_KEY = 'TotalP';

const DataEntryTable = ({ sector, initialFormData, options, isMounted, firstVisibleCellDetails, locked }) => {
  toastr.options = toastrCustomOptions;

  const dispatch = useDispatch();
  const [sectorDataInt, setSectorDataInt] = useState(undefined);
  const [sectorDataExt, setSectorDataExt] = useState(undefined);

  useEffect(() => {
    setSectorDataInt(initialFormData);
    setSectorDataExt(initialFormData);
  }, [initialFormData])

  useEffect(() => {
    if (sectorDataExt && !_.isEmpty(sectorDataExt)) {
      dispatch(setOrganicMaterialManuresFormData({
        sectorId: sector.id,
        formData: sectorDataExt
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectorDataExt]);

  const handleInputChange = (e, groupId, rowId, subRowIdx, mappingProperty, cell, totalArea) => {
    let value = e.target.value;
    if (e.target.inputMode === 'numeric') {
      let numericRegex = /^[0-9]*(\.[0-9]{0,2})?$/;
      if (numericRegex.test(value)) {
        let numericValue = parseFloat(value || 0);
        const rule = cell.validations;
        const max = rule.max || parseFloat(rule.max * (totalArea || 0));
        if (max === 0) {
          toastr.warning(`Please enter Land Area information and try again.`,
            undefined,
            toastrCustomOptionsLonger
          );
        } else if (rule.min > numericValue || max < numericValue) {
          toastr.warning(`This input only accepts values between ${addCommas(rule.min)} and ${addCommas(max)}.  Please enter the value again.`,
            undefined,
            toastrCustomOptionsLonger
          );
        } else {
          setSectorDataInt(produce((draft) => {
            draft[groupId][rowId][subRowIdx].value[mappingProperty] = value || null;
          }));
          setSectorDataExt(produce((draft) => {
            draft[groupId][rowId][subRowIdx].value[mappingProperty] = numericValue || null;
          }));
        }
      }
    }
    else {
      const selectObj = _.find(options, { id: value });

      setSectorDataInt(produce((draft) => {
        draft[groupId][rowId][subRowIdx].value[mappingProperty] = value;
        draft[groupId][rowId][subRowIdx].value[Total_N_KEY] = selectObj?.kgNPerTonne || null;
        draft[groupId][rowId][subRowIdx].value[Total_P_KEY] = selectObj?.kgPerTonne || null;
      }));
      setSectorDataExt(produce((draft) => {
        draft[groupId][rowId][subRowIdx].value[mappingProperty] = value || null;
        draft[groupId][rowId][subRowIdx].value[Total_N_KEY] = selectObj?.kgNPerTonne || null;
        draft[groupId][rowId][subRowIdx].value[Total_P_KEY] = selectObj?.kgPerTonne || null;
      }));
    }
  };

  const getCellContent = (groupId, rowId, subRowIdx, cell, cellValue, totalArea, firstVisibleCellDetails, locked) => {
    switch (cell.dataType) {
      case "Select":
        return (
          <FormTableCell sx={{
            width: CELL_WIDTH[cell.variant]
          }}>
            <FormControl sx={{ width: '90%', margin: 0 }}>
              <DataEntrySelect
                value={cellValue || ''}
                displayEmpty
                onChange={(e) => handleInputChange(e, groupId, rowId, subRowIdx,
                  cell.mappingProperty, cell, totalArea)}
                autoFocus={locked === false && rowId === firstVisibleCellDetails.rowId && cell.id === firstVisibleCellDetails.cellId && subRowIdx === 0}
              >
                <MenuItem value=""><em>Please Select</em></MenuItem>
                {options.map((option) => (
                  <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
                ))}
              </DataEntrySelect>
            </FormControl>
          </FormTableCell>
        );
      case "TextField":
        return (
          <FormTableCell sx={{ width: CELL_WIDTH[cell.mappingProperty] }}>
            <DataEntryTextField
              size="small"
              variant="outlined"
              inputProps={{ inputMode: "numeric" }}
              style={{ width: 75, textAlign: "center" }}
              value={cellValue || ''}
              onChange={(e) => handleInputChange(e, groupId, rowId, subRowIdx,
                cell.mappingProperty, cell, totalArea)}
              autoFocus={locked === false && rowId === firstVisibleCellDetails.rowId && cell.id === firstVisibleCellDetails.cellId && subRowIdx === 0}  
            />
          </FormTableCell>
        );
      default:
        break;
    }
  }

  const fetchRowTitle = (title, totalArea) => {
    const totalInKm = ['Hedges']; //temporarily check by title
    return title.concat(
      ' (', addCommas(totalArea || 0),
      ' ', totalInKm.includes(sector.title) ? 'km' : 'ha', ')');
  }

  const addNewRow = (groupId, subRowValues, rowId) => {
    let newId = uuidv4();
    let map = Object.keys(subRowValues).reduce((accumulator, mappingProperty) => {
      return { ...accumulator, [mappingProperty]: null };
    }, {});
    setSectorDataInt(produce((draft) => {
      draft[groupId][rowId].push({
        added: true,
        recordId: newId,
        value: map
      });
    }));
    setSectorDataExt(produce((draft) => {
      draft[groupId][rowId].push({
        added: true,
        recordId: newId,
        value: map
      });
    }));
  }

  const deleteNewRow = (groupId, subRowIdx, rowId) => {
    setSectorDataInt(produce((draft) => {
      let rowObj = draft[groupId][rowId][subRowIdx];
      if (rowObj.added)
        draft[groupId][rowId].splice(subRowIdx, 1);
      else
        rowObj.deleted = true;
    }));
    setSectorDataExt(produce((draft) => {
      let rowObj = draft[groupId][rowId][subRowIdx];
      if (rowObj.added)
        draft[groupId][rowId].splice(subRowIdx, 1);
      else
        rowObj.deleted = true;
    }));
  }

  return (
    <>
      {sector ?
        <ScrollSyncPane>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
              <TableBody>
                {(sector?.sectorGroups?.groups || []).map((group) => (
                  <Fragment>
                    {(group?.rows || []).map((row) => {
                      const rowData = sectorDataInt &&
                        sectorDataInt[group.id] ?
                        sectorDataInt[group.id][row.id] : [];
                      const activeRowData = rowData.filter((subRow) => !subRow.deleted);
                      return (
                        <Fragment>
                          <FormRowGroupTableRow>
                            <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }} rowSpan={activeRowData.length + 1} >
                              {fetchRowTitle(row.title, row.totalArea)}
                            </FormTableCell>
                          </FormRowGroupTableRow>
                          {rowData.map((subRow, subRowIdx) => {
                            let subRowValues = subRow.value;
                            let lastElementOfActiveRowData = activeRowData[activeRowData.length - 1];
                            return (
                              !subRow.deleted &&
                              <FormRowGroupTableRow>
                                {(row?.cells || []).map((cell) => (
                                  getCellContent(group.id, row.id, subRowIdx, cell,
                                    subRowValues[cell.mappingProperty], row.totalArea, firstVisibleCellDetails, locked)
                                ))}
                                <FormTableCell sx={{ width: '8%' }}>
                                  {isMounted && lastElementOfActiveRowData.recordId === subRow.recordId &&
                                    ![subRowValues[ORGANIC_MATERIAL_MANURES_ID_KEY], subRowValues[QUANTITY_KEY]]
                                      .includes(null) &&
                                    <Button
                                      style={{ padding: '3px 8px' }}
                                      onClick={() => addNewRow(group.id, subRowValues, row.id)}
                                    >
                                      <AddIcon />
                                    </Button>
                                  }
                                </FormTableCell>
                                <FormTableCell sx={{ width: '8%' }}>
                                  {(isMounted && activeRowData.length > 1) &&
                                    <Button
                                      style={{ padding: '3px 8px' }}
                                      onClick={() => deleteNewRow(group.id, subRowIdx, row.id)}
                                    >
                                      <DeleteIcon />
                                    </Button>
                                  }
                                </FormTableCell>
                              </FormRowGroupTableRow>
                            );
                          })}
                        </Fragment>
                      )
                    })}
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </ScrollSyncPane>
        : <Skeleton variant="rectangular" width={'100%'} height={150} />
      }
    </>
  );
};

const OrganicMaterialManures = (props) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const reportId = useSelector(state => state.common.reportId);
  const metaData = useSelector(state => state.organicMaterialManures.metaData);
  const options = useSelector(state => state.organicMaterialManures.options);
  const initialFormData = useSelector(state => state.organicMaterialManures.initialFormData);
  const
    { reportDetails: { reportName },
      reportConfig: { landAndCropsOnly }
    } = useSelector(state => state.farmReport);
  const { successMsg, errorMsg } = useSelector(state => state.common);
  const farmId = useSelector(state => state.common.farmId);
  const adminFarm = useSelector(state => state.adminFarm);
  const [isMounted, setIsMounted] = useState(false);

  const [formData, setFormData] = useState({})
  const [modalOpen, setModalOpen] = useState(false);
  const [nextClicked, setNextClicked] = useState(false);
  const [firstVisibleCellDetails, setFirstVisibleCellDetails] = useState(null);
  // const [dataManures, setDataManures] = useState({});

  const sectors = metaData?.form?.sectors || [];
  const columns = metaData?.form?.columns || [];

  useEffect(() => {
    if (initialFormData && !_.isEmpty(initialFormData)) {
      const tempData = { ...initialFormData };
      setFormData(tempData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFormData]);


  useEffect(() => {
    window.pageYOffset = 0;
    dispatch(setLoading());
    dispatch(getOrganicMaterialManuresOptions());
    dispatch(getOrganicMaterialManuresMetaData({ reportId }));
    dispatch(getOrganicMaterialManuresFormData({ reportId }));
    dispatch(resetResponseState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(resetOrganicMaterialManuresMetaData());
    }
  }, []);

  useEffect(() => {
    setTimeout(function() { setIsMounted(true); dispatch(unsetLoading());}, 3000);
    return () => setIsMounted(false)
  }, []);

  useEffect(() => {
    if (successMsg || errorMsg) {
      dispatch(getOrganicMaterialManuresFormData({ reportId }));
      setTimeout(() => {
        dispatch(unsetLoading());
    }, 2000);
      successMsg && toastr.success(successMsg);
      errorMsg && toastr.error(errorMsg, undefined, toastrCustomOptionsLonger);
      dispatch(getOrganicMaterialManuresFormData({ reportId }));
      dispatch(resetResponseState());
      if (successMsg && nextClicked) {
        if (landAndCropsOnly)
          navigate('/data-entry/land-crops/pesticides');
        else
          navigate('/data-entry/land-crops/transferred-manure');
      }
      setNextClicked(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successMsg, errorMsg]);

  useEffect(() => {
    const firstVisibleCellDetails = getFirstVisibleCellDetailsInDataEntry(sectors);
    setFirstVisibleCellDetails(firstVisibleCellDetails);
  }, [sectors]);

  const handleModalResponse = (positiveResponse) => {
    setModalOpen(false);
    if (positiveResponse) {
      // window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      window.pageYOffset = 0;
      dispatch(getOrganicMaterialManuresMetaData({ reportId }));
      dispatch(getOrganicMaterialManuresFormData({ reportId }));
    }
  }

  const isFormUpdated = () => {
    const organicMaterialManuresData = store.getState().organicMaterialManures;
    const isUpdated = !isEqual(organicMaterialManuresData?.initialFormData, organicMaterialManuresData?.currentFormData);
    return isUpdated;
  }

  const handelSaveClick = (e) => {
    if(!isFormUpdated()){
      return;
    }
    dispatch(setLoading());
    dispatch(saveOrganicMaterialManuresData({ reportId }));
  }

  const handleNextClick = (e) => {
    setNextClicked(true);
    if (props.locked || !isFormUpdated()) {
      if (landAndCropsOnly)
        navigate('/data-entry/land-crops/pesticides');
      else
        navigate('/data-entry/land-crops/transferred-manure');
    } else {
      dispatch(setLoading());
      dispatch(saveOrganicMaterialManuresData({ reportId }));
    };
  }

  return (
    <ScrollSync>
      <Grid container spacing={2} className={props.locked && 'deactivated'}>
        <Grid item xs={12} md={12} lg={12}>
          <FormHeaderPaper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
            divider={false}
          >
            <SelectedFarm farmId={farmId} farmName={adminFarm.farmDetails.farmName}/>
            <SelectedReport reportName={reportName} />
            <FormTitleTypography variant="h5" component="div">
              {'Imported Organic Materials & Manures'}
            </FormTitleTypography>
            <FormDescriptionTypography variant="body2" paragraph>
              Enter all organic amendments and imported manures applied to farm soils.  Home-produced manures are not included.
            </FormDescriptionTypography>
          </FormHeaderPaper>
        </Grid>
        <Grid item xs={12} sx={{ paddingTop:'0 !important' }}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', paddingTop:'0' }}>
            <List sx={{ mb: 2 }}>
              <ListSubheader sx={{ bgcolor: "background.paper" }}>
                <ScrollSyncPane>
                  <>
                  <DataEntryButtonStack
                    modalOpen={modalOpen}
                    setModalOpen={setModalOpen}
                    handleModalResponse={handleModalResponse}
                    handelSaveClick={handelSaveClick}
                    handleNextClick={handleNextClick}
                  />
                  <CustomizedDivider/>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} size="small" aria-label="customized table">
                      <TableHead>
                        <TableRow>
                          <FormTableCell sx={{ width: CELL_WIDTH['RowTitle'] }}></FormTableCell>
                          {columns.map((column) =>
                            <FormTableCell align="center" sx={{ width: CELL_WIDTH[column.cssClass] }} >
                              {m3Superscript(column.title)}
                            </FormTableCell>
                          )}
                          <FormTableCell
                            align="center"
                            sx={{ width: '8%' }}
                          >
                            Add
                          </FormTableCell>
                          <FormTableCell
                            align="center"
                            sx={{ width: '8%' }}
                          >
                            Delete
                          </FormTableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </TableContainer>
                  </>
                </ScrollSyncPane>
              </ListSubheader>
              {sectors.map((sector, index) => (
                <React.Fragment key={index}>
                  <TableListItem>
                    <SectorTitleTypography variant='body1'>{sector.title}</SectorTitleTypography>
                  </TableListItem>
                  <TableListItem>
                    <DataEntryTable
                      key={sector.id}
                      sector={sector}
                      sectorId={sector.id}
                      options={options}
                      initialFormData={formData[sector.id] ? formData[sector.id] : {}}
                      isMounted={isMounted}
                      firstVisibleCellDetails={firstVisibleCellDetails}
                      locked={props.locked}
                    />
                  </TableListItem>
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </ScrollSync>
  );
}

export default OrganicMaterialManures;