import { Card, CardActionArea, CardContent, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import React, { } from 'react'
import { ChartTitleTypography, FormDescriptionTypographyPDF, FormHeaderPaper, FormTitleTypographyPDF, ParagraphTypographyPDF, WholeFarmSummaryTableCellPDF } from '../../../../components/CustomStyles/StyledComponents';
import CircularPieChart from '../../../../components/Charts/CircularPieChart';
import ColoredPieChart from '../../../../components/Charts/ColoredPieChart';
import VerticalBarChart from '../../../../components/Charts/VerticalBarChart';
import { addCommas } from '../../../../helpers/stringFormatHelper';

const Charts = ({ results = {} }) => {
    return (
        <>
            <Paper sx={{
                maxWidth: '860px',
                //minHeight: '500px',
                pageBreakAfter: 'auto',
                marginTop: '70px',
            }}
                p={2}
            >
                <Typography variant='h5' sx={{ mb: 0 , mt:5}}>Section 1: Whole Farm Report</Typography>
                <Grid item xs={12} md={12} lg={12} mb={2}>
                    <Paper
                        sx={{
                            p: 0,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <FormTitleTypographyPDF fontSize={20} variant="h6" component="div">
                            Whole Farm Summary
                        </FormTitleTypographyPDF>
                    </Paper>
                </Grid>
                <Grid item xs={12} mb={1} px={0}>
                    <TableContainer>
                        <Table sx={{ minWidth: 650, borderRadius: 'none' }} size="small">
                            <TableBody>
                                <TableRow>
                                    <WholeFarmSummaryTableCellPDF sx={{ width: '30%' }} component="th" scope="row">
                                        Total (Gross) Emissions
                                    </WholeFarmSummaryTableCellPDF>
                                    <WholeFarmSummaryTableCellPDF sx={{ width: '70%' }} align="right">
                                        {addCommas(results?.totalEmissions)}
                                    </WholeFarmSummaryTableCellPDF>
                                </TableRow>
                                {/* TODO: The following code snippets have been commented out due to the requirement of AGC-1692. Please uncomment the following snippets as they are needed. */}
                                {/* <TableRow>
                                    <WholeFarmSummaryTableCellPDF sx={{ width: '30%' }} component="th" scope="row">
                                        Net Emissions[1]
                                    </WholeFarmSummaryTableCellPDF>
                                    <WholeFarmSummaryTableCellPDF sx={{ width: '70%' }} align="right">
                                        {addCommas(results?.netEmissions)}
                                    </WholeFarmSummaryTableCellPDF>
                                </TableRow> */}
                                <TableRow>
                                    <WholeFarmSummaryTableCellPDF sx={{ width: '30%' }} component="th" scope="row">
                                        Total farm area (ha)
                                    </WholeFarmSummaryTableCellPDF>
                                    <WholeFarmSummaryTableCellPDF sx={{ width: '70%' }} align="right">
                                        {results?.totalFarmArea}
                                    </WholeFarmSummaryTableCellPDF>
                                </TableRow>
                                <TableRow>
                                    <WholeFarmSummaryTableCellPDF sx={{ width: '30%' }} component="th" scope="row">
                                        Enterprise Sectors
                                    </WholeFarmSummaryTableCellPDF>
                                    <WholeFarmSummaryTableCellPDF sx={{ width: '70%' }} align="right">
                                        {results?.enterpriseSectors}
                                    </WholeFarmSummaryTableCellPDF>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* TODO: The following code snippets have been commented out due to the requirement of AGC-1692. Please uncomment the following snippets as they are needed. */}
                    {/* <ParagraphTypographyPDF variant="body2" paragraph mt={1} mb={0}>
                        {'[1] Net Emissions = Gross Emissions minus sequestration from soil, hedges and woodland. No data is available on soil carbon at this time. If data on hedges and woodland has not been provided at the time of survey, it will not be accounted for.'}
                    </ParagraphTypographyPDF> */}
                </Grid>
                <Grid item xs={12} px={0}>
                    <Grid container direction='row' spacing={0}>
                        <Grid item xs={12} pl={0}>
                            <Paper sx={{ width: '450px', margin: '0 auto', mt: 3 }}>
                                <ColoredPieChart
                                    isAnimationActive={false}
                                    key={1}
                                    title='Emissions by Enterprise'
                                    dataset={results.emissionByEnterprise}
                                    height={250}
                                    width={400}
                                    cx={100}
                                    outerRadius={100}
                                    legendWidth='180px'
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                    <Grid container direction='row' spacing={0}>
                        <Grid item xs={12} pl={0}>
                            <Paper sx={{ width: '600px', margin: '0 auto', mt: 3 }}>
                                <VerticalBarChart
                                    isAnimationActive={false}
                                    key={3}
                                    title='Emissions by Source'
                                    dataset={results.emissionBySource}
                                    height={240}
                                />
                            </Paper>
                        </Grid>
                        <ParagraphTypographyPDF variant="body2" paragraph mt={1} mb={0}>
                            {'* Other include crop residues, lime, transport and waste.'}
                        </ParagraphTypographyPDF>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

export default Charts