import * as actionTypes from './types';
import { updateObject } from '../utility'; 

const initialState = {
  reportId: null,
  tempReportId: null,
  farmId: '',
  errorMsg: '',
  successMsg: '',
  loading: false,
  user: {},
  countries: [],
  farmOwners: [],
  offices: [],
  organisations: [],
  userRoles: [],
  siteAdmin: null,
  backendVersion: {}
}

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_REPORT_ID: return setReportId(state, action);
    case actionTypes.SET_TEMP_REPORT_ID: return setTempReportId(state, action);
    case actionTypes.SET_FARM_ID: return setFarmId(state, action);
    case actionTypes.RESPONSE_STATE_SUCCESS: return responseStateSuccess(state, action);
    case actionTypes.RESPONSE_STATE_FAIL: return responseStateFail(state, action);
    case actionTypes.SET_LOADING: return setLoadingState(state, true);
    case actionTypes.UNSET_LOADING: return setLoadingState(state, false);
    case actionTypes.RESET_RESPONSE_STATE: return resetResponseState(state, action);
    case actionTypes.SET_USER: return setUserDetails(state, action);
    case actionTypes.GET_ALL_COUNTRIES_SUCCESS: return setCountries(state, action);
    case actionTypes.GET_FARM_OWNERS_DATA_SUCCESS: return setFarmOwnerDataSuccess(state, action);
    case actionTypes.GET_FARM_OWNERS_DATA_FAIL: return setFarmOwnerDataFail(state, action);
    case actionTypes.GET_OFFICE_DATA_SUCCESS: return setOfficeDataSuccess(state, action);
    case actionTypes.GET_OFFICE_DATA_FAIL: return setOfficeDataFail(state, action);
    case actionTypes.GET_ORGANISATION_DATA_SUCCESS: return setOrganisationDataSuccess(state, action);
    case actionTypes.GET_ORGANISATION_DATA_FAIL: return setOrganisationDataFail(state, action);
    case actionTypes.GET_USER_ROLE_DATA_SUCCESS: return setUserRolesSuccess(state, action);
    case actionTypes.GET_USER_ROLE_DATA_FAIL: return setUserRolesFail(state, action);
    case actionTypes.GET_BACKEND_VERSION_SUCCESS: return setBackendVersion(state, action);
    case actionTypes.GET_SITE_ADMIN_DATA_SUCCESS: return setSiteAdminDataSuccess(state, action);
    default:
      return state;
  }
}

const setReportId = (state, action) => {
  const updatedState = {
    reportId: action.payload,
    tempReportId: null
  }
  return updateObject(state, updatedState);
}

const setTempReportId = (state, action) => {
  const updatedState = {
    reportId: null,
    tempReportId: state.reportId
  }
  return updateObject(state, updatedState);
}

const setFarmId = (state, action) => {
  const updatedState = {
    farmId: action.payload,
    reportId: null,
  }
  return updateObject(state, updatedState);
}

const responseStateSuccess = (state, action) => {
  const updatedState = {
    successMsg: action.payload
  }
  return updateObject(state, updatedState);
}

const responseStateFail = (state, action) => {
  const updatedState = {
    errorMsg: action.payload
  }
  return updateObject(state, updatedState);
}

const setLoadingState = (state, isLoading) => {
  const updatedState = {
    loading: isLoading
  }
  return updateObject(state, updatedState);
}

const resetResponseState = (state, action) => {
  const updatedState = {
    errorMsg: '',
    successMsg: ''
  }
  return updateObject(state, updatedState);
}

const setUserDetails = (state, action) => {
  const updatedState = {
    user: action.payload,
  }
  return updateObject(state, updatedState);
}

const setCountries = (state, action) => {
  const updatedState = {
    countries: action.payload,
  }
  return updateObject(state, updatedState);
}


const setOfficeDataSuccess = (state, action) => {
  const updatedState = {
    offices: action.payload,
  }
  return updateObject(state, updatedState);
}

const setOfficeDataFail = (state, action) => {
  const updatedState = {
    offices: [],
  }
  return updateObject(state, updatedState);
}

const setOrganisationDataSuccess = (state, action) => {
  const updatedState = {
    organisations: action.payload,
  }
  return updateObject(state, updatedState);
}

const setOrganisationDataFail = (state, action) => {
  const updatedState = {
    organisations: {},
  }
  return updateObject(state, updatedState);
}

const setFarmOwnerDataSuccess = (state, action) => {
  const updatedState = {
    farmOwners: action.payload,
  }
  return updateObject(state, updatedState);
}

const setFarmOwnerDataFail = (state, action) => {
  const updatedState = {
    farmOwners: {},
  }
  return updateObject(state, updatedState);
}

const setUserRolesSuccess = (state, action) => {
  const updatedState = {
    userRoles: action.payload,
  }
  return updateObject(state, updatedState);
}

const setUserRolesFail = (state, action) => {
  const updatedState = {
    userRoles: {},
  }
  return updateObject(state, updatedState);
}

const setBackendVersion = (state, action) => {
  const updatedState = {
    backendVersion: action.payload,
  }
  return updateObject(state, updatedState);
}

const setSiteAdminDataSuccess = (state, action) => {
  const updatedState = {
    siteAdmin: action.payload,
  }
  return updateObject(state, updatedState);
}

export default commonReducer;
