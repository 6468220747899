import * as actionTypes from './types';
import { endpoints } from '../../../api/endpoints';
import * as api from '../../../api/base';
import store from '../..';

export const getAdminOrganisations = () => async (dispatch) => {
    const response = await api.get(endpoints.admin.org);
    if (response && response.status === 200) {
        return dispatch(getAdminOrgsSuccess(response.data));
    }
    else {
        return dispatch(getAdminOrgsFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
}

const getAdminOrgsSuccess = (response) => {
    return {
        type: actionTypes.GET_ADMIN_ORG_LIST_SUCCESS,
        payload: response
    };
};

const getAdminOrgsFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
}
export const setCurrentAdminOrganisation = (organisation) => {
    return {
        type: actionTypes.SET_CURRENT_ADMIN_ORG,
        payload: organisation
    };
};

export const createOrganisation = (data) => async (dispatch) => {
    const { name, file } = data;
    const formData = new FormData();
    if(file){
        formData.append('file',file)
    }
    formData.append('OrganizationName',name);
    try {
        const response = await api.postMultipartFormData(endpoints.admin.org,
            formData
        );
        if (response && response.status === 200) {
            dispatch(setCurrentAdminOrganisation(response.data));
            return dispatch(createOrganisationSuccess("Organisation has been created successfully."));
        }
        else {
            return dispatch(createOrganisationFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
        }
    } catch (e) {
        return dispatch(createOrganisationFail(e?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const createOrganisationSuccess = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_SUCCESS,
        payload: response
    };
};

const createOrganisationFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
};

export const updateOrganisation = (data) => async (dispatch) => {
    const { id, name, file } = data;
    const formData = new FormData();
    if(file){
        formData.append('file',file)
    }
    formData.append('organizationDTO', JSON.stringify({
        organizationName: name,
    }));
    formData.append('OrgId',id);
    try {
        const response = await api.putMultipartFormData(endpoints.admin.org,
            formData
        );
        if (response && response.status === 200) {
            return dispatch(updateOrganisationSuccess("Organisation has been updated successfully."));
        }
        else {
            return dispatch(updateOrganisationFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
        }
    } catch (e) {
        return dispatch(updateOrganisationFail(e?.message || "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const updateOrganisationSuccess = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_SUCCESS,
        payload: response
    };
};

const updateOrganisationFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response
    };
};


export const deleteOrganisation = () => async (dispatch) => {
    const { id } = store.getState().adminOrganisation.selectedOrganisation;
    const response = await api.del(endpoints.admin.org.concat('?Id=', id));
    if (response && (response.status === 200 || response.status === 204)) {
        return dispatch(deleteOrganisationSuccess(response.data || "Successfully deleted the organisation"));
    }
    else {
        return dispatch(deleteOrganisationFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
    }
};

const deleteOrganisationSuccess = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_SUCCESS,
        payload: response
    };
};

const deleteOrganisationFail = (response) => {
    return {
        type: actionTypes.RESPONSE_STATE_FAIL,
        payload: response

    };
};

