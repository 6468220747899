import * as actionTypes from './types';
import { updateObject } from '../../utility';

const initialState = {
  metaData: {},
  initialFormData: {},
  currentFormData: {},
  errorMsg: '',
  successMsg: ''
};

const grasslandReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_GRASSLAND_METADATA_SUCCESS: return setMetadataSuccess(state, action);
    case actionTypes.GET_GRASSLAND_METADATA_FAIL: return setMetadataFail(state, action);
    case actionTypes.RESET_GRASSLAND_METADATA: return resetMetaData(state, action);
    case actionTypes.GET_GRASSLAND_FORM_DATA_SUCCESS: return setFormDataSuccess(state, action);
    case actionTypes.GET_GRASSLAND_FORM_DATA_FAIL: return setFormDataFail(state, action);
    case actionTypes.SET_GRASSLAND_FORM_DATA: return setFormData(state, action);
    case actionTypes.GET_GRASSLAND_OPTIONS_SUCCESS: return setGrasslandOptionsSuccess(state, action);
    case actionTypes.GET_GRASSLAND_OPTIONS_FAIL: return setGrasslandOptionsFail(state, action);

    default:
      return state;
  }
};

const setMetadataSuccess = (state, action) => {
  const updatedState = {
    metaData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setMetadataFail = (state, action) => {
  const updatedState = {
    metaData: {},
  }
  return updateObject(state, updatedState);
}

const resetMetaData = (state, action) => {
  const updatedState = {
    metaData: {},
    initialFormData: {},
    currentFormData: {},
    grasslandOptions: {}
  }
  return updateObject(state, updatedState);
}

const setFormDataSuccess = (state, action) => {
  const updatedState = {
    initialFormData: action.payload,
    currentFormData: action.payload,
  }
  return updateObject(state, updatedState);
}

const setFormDataFail = (state, action) => {
  const updatedState = {
    metaData: {},
  }
  return updateObject(state, updatedState);
}

const setFormData = (state, action) => {
  const { sectorId, formData } = action.payload;
  const updatedState = {
    currentFormData: {
      ...state.currentFormData,
      [sectorId]: {
        ...formData
      }
    },
  }
  return updateObject(state, updatedState);
}

const setGrasslandOptionsSuccess = (state, action) => {
  const updatedState = {
    grasslandOptions: action.payload,
  }
  return updateObject(state, updatedState);
}

const setGrasslandOptionsFail = (state, action) => {
  const updatedState = {
    grasslandOptions: [],
  }
  return updateObject(state, updatedState);
}

export default grasslandReducer;
