import { Paper, Typography, Grid } from '@mui/material'
import React, { } from 'react'
import { DocumentTitleTypographyPDF } from '../../../../components/CustomStyles/StyledComponents';
import dayjs from 'dayjs';

const MainPage = ({ farmId, farmName, reportId, reportName, reportingPeriodEnd, modelNumber,yearEnd, reportLogoUrl, config ,permissions,coefficientSheetName, status, showNonValidatedReportText}) => {
    const reportingPeriodStart =  dayjs(reportingPeriodEnd).subtract(1, 'year');
    return (
        <>
            <Paper sx={{
                maxWidth: '860px',
                //minHeight: '500px',
                pageBreakAfter: 'always',
                marginTop: '70px',
            }}
                p={2}
            >

                { (showNonValidatedReportText && !(Object.values(permissions ?? {}).some(value => ['AgreCalc.Reports.ExportPDF'].includes(value)))) &&
                    <div
                        style={{
                            maxWidth: '50rem',
                            position: 'fixed',
                            top: '2rem', // Move the watermark down by 20px from the top
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            pointerEvents: 'none', // Allows clicks to pass through the watermark
                            zIndex: '9999', // Ensures the watermark stays on top
                        }}
                    >
                        <div
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transform: 'rotate(-45deg)', // Rotate to a diagonal position
                            }}
                        >
                            <span
                                style={{
                                    opacity: '0.5', // Adjust the opacity for watermark effect
                                    fontSize: '2rem', // Adjust the size of the watermark text
                                    fontWeight: 'bold', // Optional: make the text bold
                                    color: 'gray', // Optional: set the color of the watermark text
                                }}
                            >
                                Non-validated Report
                            </span>
                        </div>
                    </div>
                }
                    
               
                { reportLogoUrl && <Grid container direction="row" justifyContent="center" alignItems="center" >
                    <img style={{ maxWidth: '200px', maxHeight: '200px' }} src={reportLogoUrl} alt="Report Logo" />
                </Grid>}
                <DocumentTitleTypographyPDF variant="h3" component="div" fontWeight="500" my={5}>
                    Carbon Footprint Report 
                </DocumentTitleTypographyPDF>
                <Typography variant='h4' paragraph fontWeight="bold" mb={2}>
                    {'Farm:'}
                    <Typography variant='h4' paragraph display="inline" ml={1} mb={0}>
                        {`${farmName} (ID ${farmId})`}
                    </Typography>
                </Typography>
                <Typography variant='h4' paragraph fontWeight="bold" mb={2}>
                    {'Report:'}
                    <Typography variant='h4' paragraph display="inline" ml={1} mb={0}>
                        {`${reportName} (ID ${reportId})`}
                    </Typography>
                </Typography>
                <Typography variant='h4' paragraph fontWeight="bold" mb={2}>
                    {'Enterprise:'}
                    <Typography variant='h4' paragraph display="inline" ml={1} mb={0}>
                        {`${config.enterpriseTitle} `}
                    </Typography>
                </Typography>
                <Typography variant='h4' paragraph fontWeight="bold" mb={2}>
                    {'Reporting Period:'}
                    <Typography variant='h4' paragraph display="inline" ml={1} mb={0}>
                        {`${dayjs(reportingPeriodStart).format('M/YYYY')} - ${dayjs(reportingPeriodEnd).format('M/YYYY')}`}
                    </Typography>
                </Typography>
                <Typography variant='h4' paragraph fontWeight="bold" mb={2}>
                    {'Year End:'}
                    <Typography variant='h4' paragraph display="inline" ml={1} mb={0}>
                        {dayjs(yearEnd).format('MMM YYYY')}
                    </Typography>
                </Typography>
                <Typography variant='h4' paragraph fontWeight="bold" mb={2}>
                    {'Model Version:'}
                    <Typography variant='h4' paragraph display="inline" ml={1} mb={0}>
                        {`${modelNumber}`}
                    </Typography>
                </Typography>
                <Typography variant='h4' paragraph fontWeight="bold" mb={2}>
                    {'Coefficient Sheet Name:'}
                    <Typography variant='h4' paragraph display="inline" ml={1} mb={0}>
                        {coefficientSheetName ? coefficientSheetName : '--'}
                    </Typography>
                </Typography>
            </Paper>
        </>
    )
}

export default MainPage