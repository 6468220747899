import { Button, Grid, Typography, } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getFarmReport, getFarmerFriendlyReport, getWholeFarmReport, setLoading, unsetLoading } from '../../../../store/appAction';
import { useReactToPrint } from "react-to-print";
import { Container } from '@mui/system';
import MainPage from './MainPage';
import Contents from './Contents';
import Charts from './Charts';
import Tables from './Tables';
import EnterpriseContents from '../Enterprise/Contents';
import EnterpriseCharts from './EnterpriseCharts';
import EnterpriseTables from './EnterpriseTables';
import Header from './Header';
import Footer from '../Common/Footer';
import '../../../../assets/fonts/Montserrat-VariableFont_wght-normal.js'
import { FARMER } from '../../../../constants/userRoles';
import { getPageStyles } from '../../../../helpers/pageStyleHelper';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import _ from 'lodash';
import { showNonValidatedReportText} from '../../../../helpers/reportResultsHelper.js';

const Preview = () => {
    let location = useLocation();
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const farm = useSelector(state => ([FARMER].includes(user.role) ?
        state.profile.farm : state.adminFarm.farmDetails));
    const farmOwner = useSelector(state => ([FARMER].includes(user.role) ?
        state.profile.account : state.adminFarm.farmOwnerDetails));
    const report = useSelector(state => state.farmReport.reportDetails);
    const reportId = useSelector(state => state.common.reportId);
    const detailed = useSelector(state => state.wholeFarm.detailed);
    const permissions = useSelector(state => state.auth.permissions);
    const farmPermissions = useSelector(state => state.auth.farmPermissions);
    const [reportData, setReportData] = useState({});

    const [bid, setBid] = useState('');
    const [reportingPeriod, setReportingPeriod] = useState({});
    const [chartData, setChartData] = useState({});
    const [tableData, setTableData] = useState({});
    const [enterpriseData, setEnterpriseData] = useState([]);
    const printRef = useRef();

    // useEffect(() => {
    //     dispatch(getWholeFarmChart({ reportId }));
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    useEffect(() => {
        dispatch(setLoading());

        const { compareTo, reference } = queryString.parse(location.search);
        dispatch(getFarmReport({ reportId }));
        dispatch(getFarmerFriendlyReport({
            reportId: reportId,
            reportComparisonType: compareTo,
            comparisonReferenceId: reference,
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportId]);

    useEffect(() => {
        setReportData(report || {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [report]);

    useEffect(() => {
        if (detailed && !_.isEmpty(detailed)) {
            const {
                bid,
                reportingPeriodStart,
                reportingPeriodEnd,
                wholeFarmReportResult: {
                    wholeFarmChartResult,
                    wholeFarmDetailedResult
                }, enterpriseReportResult
            } = detailed || {};

            setBid(bid);
            setReportingPeriod({
                start: reportingPeriodStart,
                end: reportingPeriodEnd
            });
            setTableData(wholeFarmDetailedResult || {});
            setChartData(wholeFarmChartResult || {});
            setEnterpriseData(enterpriseReportResult || []);
            setTimeout(() => {
                dispatch(unsetLoading());
            }, (enterpriseReportResult || [])?.length > 4 ? 4000 : 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detailed]);

    const handlePrint = useReactToPrint({
        content: () => printRef.current,
        copyStyles: true,
        pageStyle: getPageStyles(),
        removeAfterPrint: true,
        documentTitle: `Agrecalc report - [${reportData?.friendlyReportId}].pdf`,
        // print: async (printIframe) => {
        //     // Do whatever you want here, including asynchronous work
        //     //    saveAs(new Blob(printIframe.contentWindow.document),'test.pdf')
        //     var x = window.open();
        //     x.document.open();
        //     x.document.write(printIframe.contentDocument.body.innerHTML);
        //     x.document.close();
        // },
    });

    const ReportPreview = () => (
        <Container sx={{ pointerEvents: 'none' }}>
            <Grid ref={printRef} className='to-pdf' container px={2} my={2}>
                <Header
                    farm={farm}
                    farmOwner={farmOwner}
                    reportId={reportData?.friendlyReportId}
                    bid={bid}
                    modelNumber={detailed?.calculatorReportVersion}
                    yearEnd={reportData?.yearEnd}
                    reportName={reportData?.reportName}
                    reportCreated={reportData?.reportCreated}
                />
                <MainPage
                    farmId={farm?.friendlyFarmId}
                    farmName={farm?.farmName}
                    reportId={reportData?.friendlyReportId}
                    reportName={reportData?.reportName}
                    reportingPeriod={reportingPeriod}
                    modelNumber={detailed?.calculatorReportVersion}
                    yearEnd={reportData?.yearEnd}
                    reportLogoUrl={detailed?.reportLogoUrl}
                    permissions={farmPermissions}
                    coefficientSheetName={reportData?.coefficientSheetName}
                    status={reportData?.status}
                    showNonValidatedReportText={showNonValidatedReportText(reportData?.status)}
                />
                <Charts results={chartData} />
                <Contents />
                {/* <Tables results={tableData} /> */}
                {enterpriseData.map(({ enterpriseTitle,
                    comparedTo,
                    enterpriseChartResult,
                    enterpriseDetailedResult,
                }, index) => (
                    <>
                        <EnterpriseTables
                            results={{
                                ...enterpriseDetailedResult,
                                ...enterpriseChartResult, enterpriseTitle
                            }}
                            firstChild={index === 0}
                        />
                        {/* <EnterpriseCharts
                            results={enterpriseChartResult}
                            configs={{
                                ...enterpriseDetailedResult,
                                enterpriseTitle,
                                comparedTo,
                            }}
                        /> */}
                    </>
                ))}
                <Footer />
            </Grid >
        </Container >
    );

    return (
        <Container
            sx={{
                width: '950px',
            }} p={0}>
            <Grid container direction='row' spacing={0}>
                <Grid item xs={12} mb={3}>
                    <Button
                        variant="outlined"
                        sx={{ textTransform: 'none', marginTop: '23px', float: 'right' }}
                        onClick={handlePrint}
                    >
                        Print
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Container
                        sx={{
                            py: 4,
                            height: '1280px',
                            width: '900px',
                            overflow: 'scroll',
                            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                        }}>
                        <ReportPreview />
                    </Container>
                </Grid>
            </Grid>
        </Container >
    )
}

export default Preview