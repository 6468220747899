import { Box, Button, Checkbox, FormControl, FormControlLabel, MenuItem, Modal, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FarmDetailsSelect } from '../CustomStyles/StyledComponents';
import { useSelector } from 'react-redux';

const ReportCompareToModal = ({ isOpen = false, compareToIdx, farmerFriendly,
  handleResponse, handleCompareToSelect, handleSetFarmerFriendly }) => {

  const compareToList = useSelector(state => state.enterprise.compareToList);
  const permissions = useSelector(state => state.auth.permissions);  
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleClose = () => {
    // setOpen(false);
    handleResponse(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack spacing={2} sx={{ marginLeft: 'auto', marginBottom: '15px' }} direction="column">
          <Typography id="modal-modal-title" variant="body1" component="h2" sx={{ marginBottom: 0 }}>
            Please select what you would like to compare the enterprises within this report to.
          </Typography>
          {Object.values(permissions ?? {}).some(value => ['AgreCalc.Benchmarks'].includes(value)) && 
          <FormControl
            variant="outlined"
            sx={{ width: '100%', float: 'left' }}
          >
            <b style={{ fontSize: '1.15rem' }}>Compare To</b>
            <FarmDetailsSelect
              displayEmpty
              value={compareToIdx !== undefined ? compareToIdx : ''}
              onChange={handleCompareToSelect}
              placeholder='Select to compare...'
            >
              <MenuItem value=""><em>Select compare to...</em></MenuItem>
              {compareToList.map((option, index) => (
                <MenuItem
                  id={index}
                  key={index}
                  value={index}>
                  {option.itemTitle}
                </MenuItem>
              ))}
            </FarmDetailsSelect>
          </FormControl>
          }
          <FormControlLabel
            control={
              <Checkbox
                name="advisoryService"
                color="secondary"
                sx={{ p: 0 }}
                value={farmerFriendly}
                onChange={handleSetFarmerFriendly}
              />
            }
            label="Short report"
          />
        </Stack>
        <Stack spacing={2} sx={{ marginLeft: 'auto' }} direction="row">
          <Button
            //disabled={compareToIdx === undefined} //commented out due to the AGC-1985
            variant="contained"
            sx={{ textTransform: 'none' }}
            onClick={() => handleResponse(true)}
          >
            Ok
          </Button>
          <Button
            variant="outlined"
            sx={{ textTransform: 'none' }}
            onClick={() => handleResponse(false)}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}

export default ReportCompareToModal;
