import * as actionTypes from './types';
import * as api from '../../../api/base';
import { endpoints } from '../../../api/endpoints';
import store from '../..';

export const getSoilPropertiesMetadata = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.soilCarbon.soilProperties.metaData.concat('?reportId=', reportId));
  
  if (response && response.status === 200) {
    return dispatch(getSoilPropertiesMetadataSuccess(response?.data));
  }
  else
    return dispatch(getSoilPropertiesMetadataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const getSoilPropertiesMetadataSuccess = (response) => {
  return {
    type: actionTypes.GET_SOIL_PROPERTIES_METADATA_SUCCESS,
    payload: response
  };
};

const getSoilPropertiesMetadataFail = () => {
  return {
    type: actionTypes.GET_SOIL_PROPERTIES_METADATA_FAIL,
  };
};

export const resetSoilPropertiesMetadata = () => {
  return {
    type: actionTypes.RESET_SOIL_PROPERTIES_METADATA,
  };
};

export const getSoilPropertiesFormData = ({ reportId }) => async (dispatch) => {
  const response = await api.get(endpoints.soilCarbon.soilProperties.formData.concat('?reportId=', reportId));
  if (response && response.status === 200 && response?.data) {
    return dispatch(getSoilPropertiesFormDataSuccess(response?.data?.data));
  } else
    return dispatch(getSoilPropertiesFormDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
}

const getSoilPropertiesFormDataSuccess = (response) => {
  return {
    type: actionTypes.GET_SOIL_PROPERTIES_FORM_DATA_SUCCESS,
    payload: response
  };
};

const getSoilPropertiesFormDataFail = () => {
  return {
    type: actionTypes.GET_SOIL_PROPERTIES_FORM_DATA_FAIL,
  };
};

export const saveSoilPropertiesData = ({ reportId }) => async (dispatch) => {
  const { currentFormData } = store.getState().soilProperties;
  const response = await api.post(endpoints.soilCarbon.soilProperties.saveData,
    {
      reportId: reportId,
      data: currentFormData
    });
  if (response && response.status === 200) {
    return dispatch(saveSoilPropertiesDataSuccess("Report data has been successfully saved."));
  }
  else
    return dispatch(saveSoilPropertiesDataFail(response?.data?.error?.message|| "An error has occurred.  Try to refresh this page.  If the error persists please visit our Support page."));
};

const saveSoilPropertiesDataSuccess = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_SUCCESS,
    payload: response
  };
};

const saveSoilPropertiesDataFail = (response) => {
  return {
    type: actionTypes.RESPONSE_STATE_FAIL,
    payload: response
  };
};

export const setSoilPropertiesFormData = (response) => {
  return {
    type: actionTypes.SET_SOIL_PROPERTIES_FORM_DATA,
    payload: response
  };
};