import { Box, Button, Grid, Stack, TextField, Switch } from '@mui/material';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import toastr from "toastr";
import { toastrCustomOptions } from '../../constants/toastrOptions';
import CancelModal from '../Modal/CancelModal';
const SiteAdminForm = props => {
    const {
        values: {
            bannerContent,
            isBannerVisible,
            isSiteLocked
        },
        errors,
        touched,
        handleSubmit,
        handleChange,
        handleReset,
        resetForm,
        mode,
        role,
        setFieldValue
    } = props;
    toastr.options = toastrCustomOptions;
    let navigate = useNavigate();
    const [modalOpen, setModalOpen] = useState(false);
    const handleCancelModalResponse = (positiveResponse) => {
        setModalOpen(false);
        if (positiveResponse) {
            window.pageYOffset = 0;
            handleReset();
        }
    }
    const handelBackResponse = () => {
        navigate(-1);
    }
    return (
        <>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3, p: 1, ml: 20, pr: 30 }}>
                <Grid
                    spacing={1}
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="stretch"
                >
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            id="bannerContent"
                            name="bannerContent"
                            autoComplete="bannerContent"
                            label="Banner Text"
                            helperText={touched.bannerContent ? errors.bannerContent : ""}
                            error={Boolean(errors.bannerContent)}
                            value={bannerContent}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        Enable Banner
                        <Switch
                            color="primary"
                            type="checkbox"
                            name="isBannerVisible"
                            checked={isBannerVisible}
                            value={isBannerVisible}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        Lock site
                        <Switch
                            color="primary"
                            type="checkbox"
                            name="isSiteLocked"
                            checked={isSiteLocked}
                            value={isSiteLocked}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Stack spacing={2} sx={{ marginLeft: 'auto', marginTop: 3 }} direction="row" >
                        <Button variant="outlined" sx={{ left: 'auto', textTransform: 'none' }}
                            onClick={handelBackResponse}>
                            Back
                        </Button>
                        <Button variant="outlined" sx={{ textTransform: 'none' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setModalOpen(true);
                            }}>
                            Cancel
                        </Button>
                        <Button type="submit" variant="contained" sx={{ textTransform: 'none' }}>
                            Save
                        </Button>
                    </Stack>
                </Grid>
                <CancelModal isOpen={modalOpen} handleResponse={handleCancelModalResponse} />
            </Box>
        </>
    )
}
export default SiteAdminForm