import { Grid, Paper, ThemeProvider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { setLoading, setCurrentAdminOrganisation, unsetLoading, updateOrganisation, createOrganisation } from "../../../store/appAction";
import _ from 'lodash';
import { mainTheme } from '../../../theme';
import { FormHeaderPaper, FormTitleTypography } from "../../../components/CustomStyles/StyledComponents";
import { Formik } from "formik";
import { EDIT } from "../../../constants/modes";
import toastr from 'toastr';
import OrganisationForm from "../../../components/Forms/OrganisationForm";
import { toastrCustomOptions } from '../../../constants/toastrOptions';

const Organisation = () => {
    const dispatch = useDispatch();
    let { mode } = useParams();

    const currentOrganisation = useSelector(state => state.adminOrganisation.selectedOrganisation);
    const organisationList = useSelector(state => state.adminOrganisation.organisations);
    
    const [intOrganisationDetails, setIntOrganisationDetails] = useState(undefined);
    const [formData, setFormData] = useState(undefined);
    const user = useSelector(state => state.auth.user);

    const validationSchema = Yup.object({
        name: Yup.string("Enter organisation name").trim().required("Organisation name is required")
    });

    useEffect(() => {
        if (mode === EDIT && !_.isEmpty(currentOrganisation)) {
            setIntOrganisationDetails(currentOrganisation);
        }
        else {
            dispatch(setCurrentAdminOrganisation(null));
            setFormData({
                id: '',
                name: '',
                file: null,
            });
        }
        return () => {
            // The following code line has been commented out due to the requirement of AGC-1682
            //dispatch(setCurrentAdminOrganisation(null));
            setIntOrganisationDetails(undefined);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (intOrganisationDetails && !_.isEmpty(intOrganisationDetails)) {
            setFormData({
                id: intOrganisationDetails?.id,
                name: intOrganisationDetails?.organizationName || '',
                file: intOrganisationDetails?.organizationLogoUrl || null
            })
            dispatch(unsetLoading());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intOrganisationDetails]);

    const handleSubmit = data => {
        if (( mode === EDIT && currentOrganisation.organizationName !== data?.name && organisationList.some(org => org.organizationName === data?.name))
         || ( mode !== EDIT && organisationList.some(org => org.organizationName === data?.name)) )
        {
            toastr.error("You have entered an existing organization name. Please try again with a different name.", undefined, toastrCustomOptions);
            return;
        }

        dispatch(setLoading());
        if (mode === EDIT && !_.isEmpty(currentOrganisation)) {
            dispatch(updateOrganisation(data));
        } else {
            dispatch(createOrganisation(data));
        }
    };

    return (
            <ThemeProvider theme={mainTheme}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={8} lg={9}>
                        <FormHeaderPaper
                            sx={{
                                p: 2,
                                display: "flex",
                                flexDirection: "column",
                            }}
                        >
                            <FormTitleTypography variant="h5" component="div">
                                {(mode === EDIT && currentOrganisation) ? 'Edit' : 'Create'} Organisation
                            </FormTitleTypography>
                            <Typography variant="body2" paragraph>
                                {' Create or modify your organisation details.'}
                            </Typography>
                        </FormHeaderPaper>
                    </Grid>
                    <Grid item md={12} >
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            {formData &&
                                <Formik
                                    enableReinitialize
                                    render={props => <OrganisationForm {...props} mode={mode}  role={user?.role}/>}
                                    initialValues={formData}
                                    validationSchema={validationSchema}
                                    onSubmit={handleSubmit}
                                    mode= {mode}
                                />}
                        </Paper>
                    </Grid>
                </Grid>

            </ThemeProvider>
    );
}

export default Organisation