export const SET_FARM_ID = 'SET_FARM_ID'
export const SET_REPORT_ID = 'SET_REPORT_ID'
export const SET_TEMP_REPORT_ID = 'SET_TEMP_REPORT_ID'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'
export const RESET_RESPONSE_STATE = 'RESET_RESPONSE_STATE'

export const SET_LOADING = 'SET_LOADING'
export const UNSET_LOADING = 'UNSET_LOADING'

export const SET_USER = 'SET_USER'

export const GET_ALL_COUNTRIES_SUCCESS = 'GET_ALL_COUNTRIES_SUCCESS'
export const GET_ALL_COUNTRIES_FAIL = 'GET_ALL_COUNTRIES_FAIL'

export const GET_FARM_OWNERS_DATA_SUCCESS = 'GET_FARM_OWNERS_DATA_SUCCESS'
export const GET_FARM_OWNERS_DATA_FAIL = 'GET_FARM_OWNERS_DATA_FAIL'

export const GET_OFFICE_DATA_SUCCESS = 'GET_OFFICE_DATA_SUCCESS'
export const GET_OFFICE_DATA_FAIL = 'GET_OFFICE_DATA_FAIL'

export const GET_ORGANISATION_DATA_SUCCESS = 'GET_ORGANISATION_DATA_SUCCESS'
export const GET_ORGANISATION_DATA_FAIL = 'GET_ORGANISATION_DATA_FAIL'

export const GET_USER_ROLE_DATA_SUCCESS = 'GET_USER_ROLE_DATA_SUCCESS'
export const GET_USER_ROLE_DATA_FAIL = 'GET_USER_ROLE_DATA_FAIL'

export const GET_BACKEND_VERSION_SUCCESS = 'GET_BACKEND_VERSION_SUCCESS'

export const GET_SITE_ADMIN_DATA_SUCCESS = 'GET_SITE_ADMIN_DATA_SUCCESS'
export const UPDATE_SITE_ADMIN_DATA_SUCCESS = 'UPDATE_SITE_ADMIN_DATA_SUCCESS'
export const UPDATE_SITE_ADMIN_DATA_FAIL = 'UPDATE_SITE_ADMIN_DATA_FAIL'