
export const GET_GRASSLAND_METADATA = 'GET_GRASSLAND_METADATA'
export const GET_GRASSLAND_METADATA_SUCCESS = 'GET_GRASSLAND_METADATA_SUCCESS'
export const GET_GRASSLAND_METADATA_FAIL = 'GET_GRASSLAND_METADATA_FAIL'
export const RESET_GRASSLAND_METADATA = 'RESET_GRASSLAND_METADATA'

export const GET_GRASSLAND_FORM_DATA_SUCCESS = 'GET_GRASSLAND_FORM_DATA_SUCCESS'
export const GET_GRASSLAND_FORM_DATA_FAIL = 'GET_GRASSLAND_FORM_DATA_FAIL'
export const SET_GRASSLAND_FORM_DATA = 'SET_GRASSLAND_FORM_DATA'

export const RESPONSE_STATE_SUCCESS = 'RESPONSE_STATE_SUCCESS'
export const RESPONSE_STATE_FAIL = 'RESPONSE_STATE_FAIL'

export const GET_GRASSLAND_OPTIONS_SUCCESS = 'GET_GRASSLAND_OPTIONS_SUCCESS'
export const GET_GRASSLAND_OPTIONS_FAIL = 'GET_GRASSLAND_OPTIONS_FAIL'